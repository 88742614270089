<template>
  <el-input v-model="value" @focus="numberFocus" @blur="numberBlur" :disabled="data.disabled"
    oninput="value=value.replace(/[^-?\d*\.?\d+$]/g, '')">
    <template slot="append" v-if="data.numberType === 'percentage'">%</template>
  </el-input>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    draftData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  name: 'MyInputNumber',
  data () {
    return {
      value: null
    }
  },

  components: {},
  mounted () {
    // debugger
    if (this.draftData.value) {
      this.value = this.draftData.value
    } else {
      this.value = this.data.__config__.defaultValue
    }
    if (this.data.valueType === 'formula') {
      this.$bus.$emit('formula', this.data)
    }
    if (this.data.valueType === 'dataActive') {
      this.$bus.$emit('dataLink', this.data)
    }
  },
  watch: {
    value (val) {
      //  console.log(val, '1212444')
      this.sendValue()
    },
    draftData: {
      handler (val) {
        this.value = this.draftData.value
      },
      deep: true
    }
  },
  beforeDestroy () {
    //结束监听循环
    this.$bus.$off('formula')
    this.$bus.$off('dataLink')
  },
  created () { },
  methods: {
    numberFocus () {
      if (this.value) {
        if (this.data.isThousandth) {
          this.value = this.rmoney(this.value)
        }
      }
    },
    numberBlur () {
      if (this.value) {
        if (this.data.isThousandth && !this.data.isFixed) {
          this.value = this.toThousands(this.value, 0, true)
        }
        if (this.data.isFixed) {
          if (this.data.isThousandth) {
            this.value = this.toThousands(this.value, this.data.FixedNumber, true)
          } else {
            this.value = this.rmoney(this.value)
            this.value = Number(this.value).toFixed(this.data.FixedNumber)
          }
        }
        if (this.data.isRange.range) {
          if (
            this.value < Number(this.data.isRange.min) ||
            this.value > Number(this.data.isRange.max)
          ) {
            this.value = null
            let obj = {
              name: this.data.__vModel__,
              value: this.value,
              index: this.index
            }
            this.$emit('tableValue', obj)
            return this.$message.error(
              '请输入' +
              this.data.isRange.min +
              '-' +
              this.data.isRange.max +
              '之间的数字'
            )
          }
        }
      }
      this.sendValue()
    },
    rmoney (s) {
      let str = ''
      if (s) {
        str = String(s).replace(/,/g, '')
      }
      if (s && (s + '').indexOf('.') > -1 && Number(str)) {
        return String(s).replace(/[^\d\.-]/g, '')
      } else if (s && Number(str)) {
        return str
      } else {
        return s
      }
    },

    toThousands (num, places, zeroFill = true) {
      if (typeof num === 'string') num = Number(num)
      if (typeof num !== 'number' || isNaN(num)) return
      let numStr = String(num) // 数字字符串
      let [integer, decimal] = numStr.split('.') // 整数部分，小数部分
      // 小数部分处理：四舍五入保留 places 位小数
      // 注意：不能直接使用 toFixed，toFixed 它是一个四舍六入五成双的诡异的方法(也叫银行家算法)，"四舍六入五成双"，最好别用，否则可能会出大问题！
      if (decimal && decimal.length > places) {
        const placesBase = Math.pow(10, places)
        num = Math.round(num * placesBase) / placesBase
        numStr = String(num)
        decimal = numStr.split('.')[1]
      }
      decimal = decimal ? decimal : ''
      if (places > decimal.length && zeroFill) {
        const fillZeroCount = places - decimal.length
        for (let i = 0; i < fillZeroCount; i++) {
          decimal += '0'
        }
      }
      // 整数部分处理
      let thousandsIntStr = ''
      while (integer.length > 3) {
        thousandsIntStr = ',' + integer.slice(-3) + thousandsIntStr
        integer = integer.slice(0, integer.length - 3)
      }
      thousandsIntStr = integer ? integer + thousandsIntStr : thousandsIntStr

      let result = decimal ? `${thousandsIntStr}.${decimal}` : thousandsIntStr
      if (result.startsWith('-,')) result = result.replace('-,', '-')

      return result
    },
    setValue () {
      if (this.data.valueType === 'custom') {
      }
    },
    sendValue () {
      let obj = {
        name: this.data.__vModel__,
        value: this.value,
        index: this.index
      }
      this.$emit('tableValue', obj)
    }
  }
}
</script>

<style  scoped></style>
