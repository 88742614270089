<template>
	<div class="grid-item-tools">
		<div class="tools-box">
			<i class="iconfont icon-yanse" v-if="datas.data.background" @click="colorBut"></i>
			<el-divider direction="vertical" v-if="datas.data.background"></el-divider>
			<i class="iconfont icon-bianji2" @click="editBut"></i>
			<el-divider direction="vertical"></el-divider>
			<i class="iconfont icon-fuzhi" @click="addBut"></i>
			<el-divider direction="vertical"></el-divider>
			<i class="iconfont icon-shanchu " @click="delBut"></i>
		</div>
	</div>
	
</template>

<script>
  export default {
		props: {
		  datas: Object,
		},
    data() {
      return {
      };
    },
    components: {
			
    },
		created() {
		},
		mounted() {
		},
    methods: {
			addBut(){
				this.$emit("addGrid", this.datas);
			},
			delBut(){
				this.$emit("delGrid", this.datas.i);
			},
			editBut(){
				this.$emit("editGrid", this.datas);
			},
			colorBut(){
				this.$emit("colorGrid", this.datas);
			}
    },
  };
</script>

<style lang="scss" scoped>
	.grid-item-tools{
		display: none;
		justify-content: end;
		align-items: center;
		height: 40px;
		position: absolute;
		top: 0;
		right: 14px;
		z-index: 20;
		.tools-box{
			background: #F5F8FC;
			border-radius: 4px;
			height: 26px;
			align-items: center;
			padding: 0 5px;
			display: flex;
			i{
				cursor: pointer;
			}
			i:hover{
				color: #409EFF;
			}
		}
	}
</style>