<template>
  <div>
    <div class="descBox"></div>
    <div class="btn" @click="openDialog">{{ data.buttonName }}</div>
  </div>
</template>
<script>
import { forEach } from "mathjs";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    formDataFields: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler (val) { },
    },
    formDataFields: {
      handler (val) { },
    },
  },
  data () {
    return {};
  },
  methods: {

    openDialog () {
      if (this.data.patternValue == 1) {
        this.data.btnFormData.formFilterList.forEach((itemA) => {
          if (itemA.filterData.fieldType == "1") {
            this.formDataFields.fields.forEach((itemB) => {
              if (itemA.filterData.zd_data === itemB.__vModel__) {
                if (itemB.__config__.defaultValue) {
                  itemA.filterData.value = itemB.__config__.defaultValue;
                } else {
                  this.$set(itemA.filterData, "value", "");
                }
              }
            });
          }
        });
      }
      setTimeout(() => {
        this.$bus.$emit("extendFun", this.data, this.formId);
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: #303133;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
}

.btn {
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  width: 100px;
  background-color: #0265ff;
  border-radius: 4px;
  overflow: hidden;
  /* 隐藏超出部分的文本 */
  white-space: nowrap;
  /* 防止文本换行 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}
</style>
