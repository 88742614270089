<template>
  <div class="tools-box">
    <el-dialog
      :visible.sync="openDialog"
      width="338px"
      :modal="false"
      :before-close="imgHandleClose"
      class="setDialog"
      :style="position"
    >
      <div class="dialog-cont">
        <div class="one-line">
          <div class="lable">背景颜色</div>
          <el-color-picker
            v-model="datas.data.background.color"
            show-alpha
          ></el-color-picker>
        </div>
        <div class="lable">背景图片</div>
        <div class="tips">
          支持jpg、jpeg、png、gif格式，单张5MB以内
          {{ datas.data.background.img.length }}/1
        </div>
        <div>
          <el-upload
            :action="action"
            list-type="picture-card"
            :limit="1"
            :file-list="datas.data.background.img"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="url + file.uid"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </div>
        <div class="lable">填充方式</div>
        <div>
          <el-radio-group v-model="datas.data.background.radio">
            <el-radio label="contain">原图居中</el-radio>
            <el-radio label="cover">居中填满</el-radio>
            <el-radio label="100% 100%">拉伸填满</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    openDialog: false,
    datas: Object,
    position: "",
  },
  data() {
    return {
      url: "http://47.101.160.60:8787/api/upload/handleRequest?attachmen_id=",
      action:
        process.env.NODE_ENV === "development"
          ? "/Api/api/upload/upload"
          : `${window.location.origin}/api/upload/upload`,
      color: "",
    };
  },
  watch: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    imgHandleClose() {
      this.$emit("handleClose", false);
    },
    handleRemove(file) {
      this.datas.data.background.img.splice(
        this.datas.data.background.img.findIndex(
          (item) => item.uid === file.uid
        ),
        1
      );
    },
    uploadSuccess(response, file, fileList) {
      let obj = {
        uid: file.response.data.attachment_id,
        text: "",
        link: "",
      };
      this.datas.data.background.img.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 100% !important;
  height: 40px !important;
  line-height: 48px !important;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100% !important;
  height: 125px !important;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100% !important;
  height: 125px !important;
}
</style>
