<!-- 数据管理 -->
<template>
  <div class="table-warp">
    <div class="table-search">
      <div class="search-btngroup">
        <div class="title" :style="{ color: settingOption.cssStyle.titleColor }">
          {{ settingOption.title }}
        </div>
      </div>
    </div>
    <!-- :height="tableHeight" -->
    <el-table ref="table" border :data="tableData" height="100%" style="width: 100%" :header-cell-style="{
      height: '100%',
      color: settingOption.cssStyle.headerTableColor,
      background:
        settingOption.cssStyle.bgType === '1'
          ? settingOption.cssStyle.bgColor
          : `url(${settingOption.cssStyle.bgUrl}) `,
      textAlign: settingOption.cssStyle.alignment,
    }" :cell-style="{
  height: '100%',
  color: settingOption.cssStyle.contentColor,
  background:
    settingOption.cssStyle.bgType === '1'
      ? settingOption.cssStyle.bgColor
      : `url(${settingOption.cssStyle.bgUrl}) `,
  textAlign: settingOption.cssStyle.alignment,
}" v-if="tableData.length > 0">
      <el-table-column type="index" width="50" align="center" v-if="settingOption.diaplayNumber == true" fixed="left" />


      <el-table-column :label="item.label" align="center" v-for="(item, index) in filed" :key="index" width="200"
        :prop="item.fileds" :sortable="true">
        <template slot-scope="scope">
          <div class="col-class" :title="getData(scope.row, item)">
            <img style="height: 32px" v-if="item.tagIcon === 'Sign'" :src="getData(scope.row, item)" />
            <div v-else>{{ getData(scope.row, item) }}</div>
          </div>
        </template>
        <template v-if="item.tagIcon === 'row'">
          <el-table-column :label="item2.label" width="200" align="center" v-for="(item2, index2) in item.child"
            :key="index2" :prop="item2.fileds">
            <template slot-scope="scope">
              <div class="row-warp">
                <div class="row-class" :title="getTabTableData(item3, item2)"
                  v-for="(item3, index3) in scope.row[item.fileds]" :key="index3">
                  <div v-if="item2.tagIcon === 'Sign'">
                    <img style="height: 32px" :src="getTabTableData(item3, item2, scope.row[item.fileds])
                      " />
                  </div>
                  <div v-else>
                    {{ getTabTableData(item3, item2, scope.row[item.fileds]) }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </template>

        <template v-if="item.tagIcon === 'table'">
          <el-table-column v-for="(tabItem, tabIndex) in item.child" :key="tabIndex" align="center" :label="tabItem.name">
            <el-table-column v-for="(tabChildItem, tabChildIndex) in tabItem.child" :key="tabChildIndex" width="200"
              align="center" :label="tabChildItem.label">
              <template slot-scope="scope">
                <div v-if="tabChildItem.tagIcon === 'Sign'">
                  <img style="height: 32px" :src="getData(
                    scope.row[item.fileds],
                    tabChildItem,
                    tabChildIndex
                  )
                    " />
                </div>
                <div v-else>
                  {{
                    getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                  }}
                </div>
              </template>
              <div v-if="tabChildItem.tagIcon === 'row'">
                <el-table-column :label="tableItem.label" width="200" align="center"
                  v-for="(tableItem, tableIndex) in tabChildItem.child" :key="tableIndex">
                  <template slot-scope="scope">
                    <div class="row-warp">
                      <div class="row-class" :title="getTabTableData(item3, tableItem)" v-for="(item3, index3) in scope.row[item.fileds][
                        tabChildItem.fileds
                      ]" :key="index3">
                        <div v-if="tableItem.tagIcon === 'Sign'">
                          <img style="height: 32px" :src="getTabTableData(item3, tableItem)" />
                        </div>
                        <div v-else>
                          {{ getTabTableData(item3, tableItem) }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </div>
            </el-table-column>
          </el-table-column>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" prop="add_time" align="center" width="200" fixed="right">
      </el-table-column>
      <el-table-column label="提交人" prop="user_name" align="center" fixed="right">
      </el-table-column>
    </el-table>

    <div class="pagination" v-if="tableData.length > 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageNum"
        :page-sizes="[10, 20, 30, 50]" :page-size="query.pageSize" :layout="layout" :total="total" background>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getdetailsTable } from "@/api/charts";
import { getDepartMentName, getMemberName } from "@/api/app";
export default {
  data () {
    return {
      sortNum: 0,
      preViewData: {},
      preFormInfo: {},
      dialogVisible: false,
      flow: false,
      name: "",
      filed: [],
      tableData: [],
      originalRowData: [],
      total: 0,
      ids: "",
      userName: "",
      tableHeight: null,
      show: false,
      formData: {
        filterList: [],
        draggableObj: {},
      },
      query: {
        order: "desc",
        pageSize: 10,
        pageNum: 1,
        user_name: "",
        type: 2,
      },
      layout: "total, sizes, prev, pager, next, jumper",
    };
  },

  computed: {
    ...mapGetters(["formInfo"]),
  },
  props: ["settingOption"],
  watch: {
    sortNum (val) {
      this.$refs.sort.doClose();
      this.formData = this.settingOption.draggableObj;
      let data = { ...this.formData, ...this.query };
      this.getList(data);
    },
    settingOption: {
      handler (val) {
        console.log(2222, val);
      },
    },
    "settingOption.draggableObj": {
      handler (val) {
        this.formData = this.settingOption.draggableObj;
        this.query.pageNum = 1;
        let data = { ...this.formData, ...this.query };
        this.getList(data);
      },
      deep: true,
    },
    // data_source_id: {
    //   handler (val) {
    //     this.formData.data_source_id = val
    //     this.getList()
    //   }
    // },
    "settingOption.endValue": {
      handler (val) {
        this.query.pageSize = val ? Number(val) : this.query.pageSize;
        this.query.pageNum = 1;
        let data = { ...this.formData, ...this.query };
        this.getList(data);
      },
      deep: true,
    },
    "settingOption.displayEnd": {
      handler (val) {
        this.$nextTick(() => {
          if (val == false) {
            this.layout = "total, sizes, prev, pager, next, jumper";
            this.query.pageSize = 10;
            this.formData = this.settingOption.draggableObj;
            let data = { ...this.formData, ...this.query };
            this.getList(data);
          } else {
            this.layout = "total,prev, pager, next, jumper";
          }
        });
      },
    },
  },
  mounted () {
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };

    if (_this.settingOption.draggableObj) {
      _this.formData = _this.settingOption.draggableObj;
      let data = { ..._this.formData, ..._this.query };
      _this.getList(data);
    }
  },
  methods: {
    getTableHeight () {
      let tableH = 350; //距离页面下方的高度
      let tableHeightDetil = window.innerHeight - tableH;
      if (tableHeightDetil <= 300) {
        this.tableHeight = 300;
      } else {
        this.tableHeight = window.innerHeight - tableH;
      }
    },

    getTabTableData (row, item) {
      if (item.tag === "datePicker") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i];
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in row) {
          if (i === item.fileds) {
            let arr = [];
            if (row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i].join("/");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          if (i === item.fileds) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "el-select" || item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.fileds) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i];
          }
        }
      }
    },

    getFiles (val, item) { },
    handleClose () {
      this.dialogVisible = false;
    },

    handleSelectionChange (val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item._id);
      });
      this.ids = arr.join(",");
    },
    getData2 (row, item, index) {
      if (item.tag === "datePicker") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j];
            }
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              let arr = [];
              if (row[i][j].length > 0) {
                row[i][j].forEach((item) => {
                  arr.push(item.name);
                });
              }
              return arr.join(",");
            }
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j].join("/");
            }
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join("/");
              }
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j].join(",");
            }
          }
        }
      } else if (item.tag === "el-select" || item.tag === "el-checkbox-group") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join(",");
              } else {
                return row[i][j];
              }
            }
          }
        }
      } else {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j];
            }
          }
        }
      }
    },

    getData (row, item) {
      if (item.tag === "el-upload") {
        for (let i in row) {
          if (item.fileds === i) {
            let arr = [];
            if (row[i] && row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join("/");
          }
        }
      } else if (item.tag === "el-select") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "datePicker") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i];
          }
        }
      }
    },
    handleSizeChange (val) {
      this.query.pageSize = val;
      let data = { ...this.formData, ...this.query };
      this.getList(data);
    },
    handleCurrentChange (val) {
      this.query.pageNum = val;
      let data = { ...this.formData, ...this.query };
      this.getList(data);
    },
    getList (data) {
      getdetailsTable(data).then((res) => {
        this.originalRowData = res.data.data;
        this.tableData = res.data.data;
        this.filed = res.data.head;
        this.tableData.forEach((item, index) => {
          for (let i in item.post_data) {
            if (
              item.post_data[i].tag === "departCheck" ||
              item.post_data[i].tag === "departSingle"
            ) {
              getDepartMentName(
                item.post_data[i].value.map((item) => item.id)
              ).then((res) => {
                this.$set(item.post_data[i], "value", res.data.join(","));
                this.$set(
                  this.tableData[index],
                  item.post_data[i].listindex,
                  res.data.join(",")
                );
              });
            }
            if (
              item.post_data[i].tag === "menberCheck" ||
              item.post_data[i].tag === "menberSingle"
            ) {
              getMemberName(
                item.post_data[i].value.map((item) => item.id)
              ).then((res) => {
                this.$set(item.post_data[i], "value", res.data.join(","));
                this.$set(
                  this.tableData[index],
                  item.post_data[i].listindex,
                  res.data.join(",")
                );
              });
            }
          }
        });
        if (this.settingOption.displayEnd) {
          if (this.settingOption.endValue) {
            if (this.settingOption.endValue > res.data.total) {
              this.total = res.data.total;
            } else {
              this.total = Number(this.settingOption.endValue);
            }
          } else {
            this.total = res.data.total;
          }
          this.dataTotal = res.data.total;
        } else {
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .add-form-box {
  .el-dialog__body {
    height: 90vh;
    padding: unset !important;
  }

  .el-dialog {
    position: absolute;
    bottom: 0;
    margin: 0 !important;
    height: 90vh;
  }
}

::v-deep .submit-form {
  .el-dialog__wrapper {
    overflow: auto !important;
  }
}

::v-deep .el-tabs__content {
  height: 100% !important;
}

.table-warp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 24px 0 24px;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #303133;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
}

.tab-row-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px 0;
  box-sizing: border-box;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-li {
  margin-bottom: 20px;

  &>div:nth-child(1) {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    color: #303133;
  }
}

.row-warp {
  height: 100%;

  .row-class:last-child {
    border: unset !important;
  }
}

::v-deep .cell {
  height: 100% !important;
}

::v-deep .el-dialog__wrapper {
  overflow: unset !important;
}

.row-class {
  border-bottom: 1px solid #ebeef5;

  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col-class {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .cell {
  padding: unset !important;
}

::v-deep .el-table__cell {
  padding: unset !important;
}

::v-deep .el-table th.el-table__cell>.cell {
  padding: 12px 0 !important;
}

.upload-warp {
  display: flex;
  align-items: center;
}

.form-btn {
  display: flex;
  align-items: center;

  &>div:nth-child(2) {
    height: 32px;
    cursor: pointer;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;

    span {
      font-size: 14px;
      margin-left: 5px;
      display: inline-block;
      font-weight: 400;
      color: #303133;
    }
  }
}

.sort-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 2px;
  cursor: pointer;

  i {
    color: #5aabff;
  }
}

.sort-li-active {
  background: #f0f4fa;
  border-radius: 4px;
}

.sort-li:hover {
  background: #f0f4fa;
  border-radius: 4px;
}

.table-warp {
  width: 100%;

  box-sizing: border-box;

  .table-title {
    border-bottom: 1px solid #ebeef5;

    .title-name {
      display: inline-block;
      font-size: 16px;
      border-bottom: 3px solid #409eff;
      font-weight: bold;
      color: #303133;
      border-radius: 1px;
      padding: 12px 0;
      box-sizing: border-box;
      height: 100%;
    }
  }

  .table-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    box-sizing: border-box;

    .el-button {
      margin-right: 5px;
    }

    .search-info {
      display: flex;
      align-items: center;

      .screen,
      .sort {
        display: flex;
        align-items: center;
        width: 100px;
        cursor: pointer;
        justify-content: center;

        div {
          margin-left: 3px;
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #303133;
        }
      }
    }
  }

  .pagination {
    text-align: right;
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
