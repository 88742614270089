<template>
	<div class="time-grid">
		<div :class="datas.data.font +' time-box'">{{time}}</div>
	</div>
</template>

<script>
	import moment from 'moment';
  export default {
		props: {
		  datas: Object,
		},
    data() {
      return {
				time: "",
      };
    },
    components: {
			
    },
		created() {
			this.getTime()
		},
		mounted() {
		},
    methods: {
			getTime(){
				setInterval(()=>{
					let week = moment().isoWeekday();
					let week_str = "星期"
					if(week === 1){
						week_str += '一'
					}else if(week === 2){
						week_str += '二'
					}else if(week === 3){
						week_str += '三'
					}else if(week === 4){
						week_str += '四'
					}else if(week === 5){
						week_str += '五'
					}else if(week === 6){
						week_str += '六'
					}else if(week === 7){
						week_str += '日'
					}
					
					let result = '';
					switch (this.datas.data.timetype) {
						case 1:
							result = moment().format('YYYY-MM-DD HH:mm:ss')
							break;
						case 2:
							result = moment().format('YYYY-MM-DD HH:mm:ss') + ' ' + week_str
							break;
						case 3:
							result = moment().format('YYYY/MM/DD HH:mm:ss')
							break;
						case 4:
							result = moment().format('YYYY/MM/DD HH:mm:ss') + ' ' + week_str
							break;
						case 5:
							result = moment().format('YYYY年MM月DD日 HH时mm分ss秒')
							break;
						case 6:
							result = moment().format('YYYY年MM月DD日 HH时mm分ss秒') + ' ' + week_str
							break;
						case 7:
							result = moment().format('HH:mm:ss')
							break;
						case 8:
							result = moment().format('HH:mm:ss') + ' ' + week_str
							break;
						case 9:
							result = moment().format('HH时mm分ss秒')
							break;
						case 10:
							result = moment().format('HH时mm分ss秒') + ' ' + week_str
							break;
						default:
							result = moment().format('YYYY-MM-DD HH:mm:ss');
					}
			
					this.time = result;
				},1000)
			},
    },
  };
</script>

<style lang="scss" scoped>
	@font-face {
	  font-family: 'pht';
	  src: url('./font/pht.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'hmt';
	  src: url('./font/hmt.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'jcyt';
	  src: url('./font/jcyt.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'mht';
	  src: url('./font/mht.otf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	.pht{
		font-family: 'pht';
	}
	.hmt{
		font-family: 'hmt';
	}
	.jcyt{
		font-family: 'jcyt';
	}
	.mht{
		font-family: 'mht';
	}
	
	.time-grid{
		height: 100%;
	}
	.time-box{
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		text-align: center;
	}
</style>