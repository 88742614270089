<template>
  <div class="link-grid">
    <div class="my-title" v-if="datas.data && datas.data.title">
      {{ datas.data.title }}
    </div>
    <div class="content-ul" ref="contentUL">
      <div
        :class="
          datas.data.list_type == 'card' ? 'content-li' : 'content-li islist'
        "
        v-for="(item, index) in datas.data.list"
        :key="index"
        @click="goToDoList(item, item.judge)"
      >
        <div class="app-icon" :style="{ background: item.color }">
          <i :class="'iconfont-1 ' + item.icon" v-if="item.icon"></i>
          <i :class="'iconfont-1 icon-shuju5'" v-else></i>
        </div>
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getTab } from "@/api/formList";
export default {
  props: {
    datas: Object,
    noglink: true,
  },
  data() {
    return {
      jurisdiction: {},
    };
  },
  components: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    async goToDoList(item, judge) {
      if (this.noglink) {
        return;
      }

      if (item.type == "form" || item.type == "bi") {
        let data = cloneDeep(item);
        delete data.parent.children;

        this.$store.dispatch("app/setIsClickForm", data);
        this.$store.dispatch("app/setAppName", JSON.stringify(data.parent));
        let ids = {
          app_id: this.$route.query.id,
          form_id: data._id,
        };
        const res = await getTab(ids);
        // console.log("请求", res);
        if (res.code == 200) {
          this.jurisdiction = res.data;
          this.$emit("getTab", this.jurisdiction);
          this.$store.dispatch("user/haveJurisdictionList", this.jurisdiction);
        }
        this.$store.dispatch(
          "user/haveHomebacklogTotal",
          JSON.stringify(data.parent.judge)
        );
        let obj = {
          type: 1,
          listIndex: data._id,
        };
        this.$store.dispatch("app/setLeftNav", obj);
        this.$store.dispatch("app/setFormInfo", data);

        setTimeout(() => {
          if (this.$route.query.id === data.parent._id) {
            window.location.reload();
          } else {
            if (this.$route.query)
              this.$router.replace({
                path: "/todolist",
                query: {
                  id: data.parent._id,
                  type: "bi",
                },
              });
            this.$store.dispatch("app/setLeftNav", obj);
            this.$store.dispatch("app/setFormInfo", data);
            window.location.reload();
          }
        }, 200);
      } else {
        this.$store.dispatch("app/setAppName", JSON.stringify(item));
        this.$store.dispatch(
          "user/haveHomebacklogTotal",
          JSON.stringify(judge)
        );
        if (this.$route.query.id === item._id) {
          window.location.reload();
        } else {
          this.$router.replace({
            path: "/todolist",
            query: {
              id: item._id,
            },
          });
          window.location.reload();
        }
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/components/createApp/icon/iconfont.css";
@import "@/components/createApp/icon2/iconfont.css";

.link-grid {
  // height: 100%;
  display: flex;
  flex-direction: column;
}

.my-title {
  color: #303133;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}
.app-icon {
  width: 48px;
  height: 48px;
  background: rgb(255, 164, 47);
  border-radius: 4px;
  line-height: 48px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  color: #fff;
}
.content-ul {
  display: grid;
  grid-gap: 0px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 216px);
  flex-wrap: wrap;
  .content-li:hover {
    // border: 1px solid #419bff;
    box-shadow: 0px 0px 12px 0px rgba(34, 131, 231, 0.3);
    border-radius: 4px;
  }
  .content-li {
    width: 216px;
    border: 1px solid rgba(255, 255, 255, 0);
    height: 130px;
    cursor: pointer;
    text-align: center;
    padding-top: 25px;
    position: relative;
    box-sizing: border-box;
    & > div:nth-child(2) {
      font-size: 14px;
      margin-top: 15px;
      font-weight: 400;
      color: #606266;
    }
    i {
      font-size: 24px;
    }
  }
  .content-li.islist {
    height: 60px;
    display: flex;
    align-items: center;
    padding-top: 0px;
    .app-icon {
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin: 0 10px;
      i {
        font-size: 18px;
      }
    }
    & > div:nth-child(2) {
      flex: 1;
      margin: 0;
      text-align: left;
      display: -webkit-box; /* 将元素设置为弹性容器 */
      -webkit-line-clamp: 2; /* 指定最多显示的行数 */
      -webkit-box-orient: vertical; /* 设置垂直布局 */
      overflow: hidden;
    }
  }
  .content-li.islist:hover {
    box-shadow: 0px 0px 12px 0px rgba(34, 131, 231, 0);
    border-radius: 4px;
    background-color: #f5f8fc;
  }
}
</style>
