<template>
  <div class="preview-dialog">
    <el-drawer title="预览" :visible.sync="previewDialog" direction="btt" size="90%" :before-close="handleClose">
      <div class="body-box">
        <gridLayoutLook :islook="true" :appId="$route.query.appId" :formId="$route.query.formId" :datas="datas">
        </gridLayoutLook>
      </div>
    </el-drawer>

  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import gridLayoutLook from "@/views/gridLayout/components/gridLayoutLook";
export default {
  props: {
    datas: Array,
    previewDialog: false,
  },
  data () {
    return {
    };
  },
  components: {
    gridLayoutLook
  },
  watch: {
  },
  created () {
  },
  mounted () {

  },
  methods: {
    handleClose () {
      this.$emit("previewClose", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.body-box {
  height: 100%;
}
</style>