<template>
  <el-upload :ref="value.__vModel__" :file-list="fileList" :action="value.action" :multiple="value.multiple"
    :accept="value.accept" :before-upload="beforeUploadFun" name="file" :limit="limit"
    :list-type="value.accept === 'image' ? 'picture' : ''" :disabled="value.disabled" :on-success="successFun"
    :on-preview="previewFun" :data="{
      app_id: appId,
      form_id: formId,
    }" :on-remove="removeFun" :on-exceed="exceedFun">
    <el-button size="small" type="primary" icon="el-icon-upload" :disabled="value.disabled">{{ "点击上传" }}</el-button>
    <div slot="tip" class="el-upload__tip">
      只能上传不超过 {{ value.__config__.fileSize || 2
      }}{{ value.__config__.sizeUnit || "M" }} 的{{
  value.accept === "image"
  ? "图片"
  : value.accept === "file"
    ? "文件"
    : "视频"
}}
    </div>
    <!-- <div slot="tip" class="el-upload__tip">
      只能上传不超过2M的{{ value.accept }}文件
    </div> -->
  </el-upload>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
    },
  },
  name: "MyUpload",
  data () {
    return {
      fileList: [],
      attachmen_id: "",
      value: "",
      limit: 99
    };
  },
  watch: {
    fileList: {
      handler (val) {
        this.sendValue();
      },
      deep: true,
    },
    value: {
      handler (newVal) {
        if (!newVal.multiple) {
          this.limit = 1
        } else {
          this.limit = 99
        }
      },
      deep: true
    },



  },
  components: {},
  mounted () { },
  created () {
    if (this.draftData.value) {
      this.fileList = this.draftData.value;
    }
    this.value = this.data;

    this.$set(
      this.value,
      "action",
      process.env.NODE_ENV === "development"
        ? "/Api/api/upload/upload"
        : `${window.location.origin}/api/upload/upload`
    );
  },
  methods: {
    previewFun (file) {
      window.open(
        `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
        file.response.data.attachment_id
      );
    },
    beforeUploadFun (file) {
      const allowHook = {
        video: ["mp4", "ogv", "ogg", "webm", "wav", "mp3", "acc", "amr"],
        file: [
          "doc",
          "docx",
          "xlsx",
          "xls",
          "pdf",
          "txt",
          "zip",
          "pptx",
          "json",
        ],
        img: ["jpg", "jpeg", "png", "gif"],
      };
      if (this.value.accept === "image") {
        console.log(allowHook.img.indexOf(file.name.split(".")[1]));
        if (
          allowHook.img.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传图片");
          return false;
        }
      }
      if (this.value.accept === "file") {
        if (
          allowHook.file.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传文件");
          return false;
        }
      }
      if (this.value.accept === "video") {
        if (
          allowHook.video.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传视频");
          return false;
        }
      }
      if (!this.value.__config__.fileSize) {
        return;
      }

      let fileSize;
      if (this.value.__config__.sizeUnit == "GB") {
        fileSize = file.size / 1024 / 1024 / 1024;
      } else if (this.value.__config__.sizeUnit == "MB") {
        fileSize = file.size / 1024 / 1024;
      } else if (this.value.__config__.sizeUnit == "KB") {
        fileSize = file.size / 1024;
      }
      let isRightSize = fileSize < this.value.__config__.fileSize * 1;
      if (!isRightSize) {
        this.$message.error(
          "文件大小超过 " +
          this.value.__config__.fileSize +
          this.value.__config__.sizeUnit
        );
        return false;
      }
    },
    exceedFun () {
      this.$message.warning("只能上传一个文件");
    },
    successFun (response, file, fileList) {
      if (response.code === -1) {
        // 上传失败，删除文件
        const index = fileList.findIndex((item) => item.uid === file.uid);
        if (index > -1) {
          fileList.splice(index, 1);
        }
        this.$message.error("文件上传失败！");
      } else {
        this.fileList = fileList;

        this.sendValue();
      }
    },
    removeFun (file, fileList) {
      this.fileList = fileList;
      this.sendValue();
      console.log(this.fileList);
    },
    sendValue () {
      let obj = {
        value: this.fileList,
        index: this.index,
        name: this.value.__vModel__,
      };

      this.$emit("tableValue", obj);
    },
  },
};
</script>

<style  scoped>
::v-deep .el-upload-list__item-name {
  font-size: 14px;
}
</style>
