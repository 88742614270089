<template>
  <div class="sign">
    <i class="iconfont icon-qianming"></i>
    <!-- <div>请在移动端打开表单获取定位</div> -->
    <div>{{ data.__config__.defaultValue || '请在移动端打开表单获取定位' }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
      }
    }
  },

  components: {},
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sign {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  cursor: pointer;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  text-align: left;
  padding-left: 10px;
  transition: all 0.3s;
  text-shadow: none;

  height: 34px;
  line-height: 32px;
  i {
    margin-right: 10px;
  }
  div {
    color: #999;
  }
}
</style>
