<template>
  <div class="serial-warp">
    <div>{{ data.__config__.defaultValue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    }
  },
  data () {
    return {};
  },
  mounted () {

  },
  watch: {
    "data.__config__.defaultValue": {
      deep: true,
      immediate: true,
      handler (val) {
        // debugger
      }
    }
  },
  components: {},
  created () { },
  methods: {},
};
</script>

<style lang="scss" scoped>
.serial-warp {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  cursor: pointer;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  text-align: left;
  padding-left: 10px;
  transition: all 0.3s;
  text-shadow: none;

  height: 34px;
  line-height: 32px;

  i {
    margin-right: 10px;
  }

  div {
    color: #999;
  }
}
</style>
