<template>
  <div style="width: 100%">
    <div class="richText" :style="{ color: textColor }" v-if="showLineLabel">
      {{ textLabel }}
    </div>
    <div class="descBox" v-if="lienDescribe">
      {{ lienDescribe }}
    </div>
    <div class="line" :style="{ backgroundColor: backgroundColor }"></div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {};
  },
  props: [
    "backgroundColor",
    "textColor",
    "showLineLabel",
    "lienDescribe",
    "textLabel",
    "__config__"
  ],
  components: {},
  created () { },
  methods: {},
  mounted () {
    console.log(
      this.backgroundColor,
      this.textColor,
      this.showLineLabel,
      this.lienDescribe
    )

  },
};
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.richText {
  font-size: 14px;
}

.descBox {
  font-size: 12px;
  color: #999;
}
</style>
