<template>
  <div>
    <div class="descBox"></div>

    <div class="btn">{{ buttonName }}</div>
  </div>
</template>
<script>
export default {
  props: ["buttonName", "placeholder"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.btn {
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  width: 100px;
  background-color: #0265ff;
  border-radius: 4px;
  overflow: hidden; /* 隐藏超出部分的文本 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}
</style>
