<template>
  <el-radio-group
    v-if="data.__config__.tagIcon == 'radio'"
    v-model="value"
    :size="data.size"
    :disabled="data.disabled"
    @input="sendValue"
  >
    <el-radio
      v-for="(item, index) in data.__slot__.options"
      :key="index"
      :label="item.value"
      :disabled="item.disabled"
      :border="data.__config__.border"
      >{{ item.value }}</el-radio
    >
  </el-radio-group>

  <el-checkbox-group
    v-else-if="data.__config__.tagIcon == 'checkbox'"
    v-model="value"
    :disabled="data.disabled"
    @input="sendValue"
  >
    <el-checkbox
      v-for="(item, index) in data.__slot__.options"
      :key="index"
      :label="item.value"
      :disabled="item.disabled"
      >{{ item.value }}</el-checkbox
    >
  </el-checkbox-group>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    rowIndex: "",
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "MyRadio",
  data() {
    return {
      value: "",
    };
  },
  watch: {
    value(val) {
      // this.sendValue();
    },
  },
  components: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      if (this.draftData.value) {
        this.value = this.draftData.value;
      } else {
        this.value = this.data.__config__.defaultValue;
      }
    });

    if (this.data.__config__.tagIcon == "radio") {
      this.sendValue();
    }
  },
  methods: {
    xygzFun() {
      if (
        this.data.regulation &&
        this.data.regulation.length > 0 &&
        this.data.regulation[0].name !== ""
      ) {
        let arr = [];
        let nowobj = {};
        this.data.regulation.forEach((item, index) => {
          if (item.name !== "") {
            if (item.name === this.value) {
              nowobj = {
                value: item.value,
                isshow: true,
              };
            } else {
              arr.push({
                value: item.value,
                isshow: false,
              });
            }
          }
        });
        if (nowobj.value) {
          arr.push(nowobj);
        }

        // this.$bus.$emit("tableXygz", arr, this.data.xygzParent, this.index);
      }
    },
    sendValue(val) {
      this.value = val;
      let dataArr = [];
      if (this.data.__config__.tagIcon == "checkbox") {
        this.data.__slot__.options.forEach((item) => {
          let str = this.data.__config__.defaultValue.indexOf(item.value);
          if (str !== -1) {
            dataArr.push(item.value);
          }
        });
        this.value = dataArr;
      }
      let obj = {
        name: this.data.__vModel__,
        value: this.value,
        index: this.index,
      };
      this.xygzFun();
      if (val !== undefined) {
        this.$emit("tableValue", obj);
      }
    },
  },
};
</script>

<style  scoped lang="scss">
::v-deep .el-radio {
  margin-right: unset !important;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}
::v-deep .el-checkbox {
  margin-right: unset !important;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}
</style>
