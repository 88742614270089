<!--  -->
<template>
  <div>
    <el-dialog title="添加成员" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="content">
        <div class="menber-ul">
          <div class="menber-li" v-for="(item, index) in selectMenberList" :key="index">
            <div class="li-info">
              <div class="li-info-atvar">{{ item.name[0] }}</div>
              <div>{{ item.name }}</div>
            </div>
            <div class="li-delete" @click="delSelectMent(index)">
              <i class="iconfont icon-shanchu"></i>
            </div>
          </div>
        </div>
        <div class="title">成员</div>
        <div class="select-menber-ul">
          <div class="depart-warp">
            <div class="depart-ul">
              <el-tree :data="departData" node-key="id" ref="departTree" default-expand-all :highlight-current="true"
                :expand-on-click-node="false" accordion @node-click="nodeDepartClick">
              </el-tree>
            </div>
          </div>
          <div class="menber-warp">
            <div class="menber-title" v-if="type != 'admin'">
              <div>已选择:{{ checkedList.length }}人</div>
              <div>
                全部
                <el-checkbox v-model="checked" style="margin-left: 3px" @change="allCheckChange"></el-checkbox>
              </div>
            </div>
            <div v-else class="menber-title"></div>
            <div class="menber-list">
              <el-checkbox-group v-model="checkedList" style="height: 100%" :max="type === 'admin' ? 1 : 999"
                v-if="menberList.length > 0">
                <div class="menber-list-li" v-for="(item, index) in menberList" :key="index">
                  <div class="li-info">
                    <div class="li-info-atvar">{{ item.name[0] }}</div>
                    <div>{{ item.name }}</div>
                  </div>
                  <div class="li-check">
                    <el-checkbox :label="item._id"></el-checkbox>
                  </div>
                </div>
              </el-checkbox-group>
              <div v-else>
                <el-empty :image-size="100"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit" v-loading="loadings">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDepartMent, getDepartMember } from "@/api/user";
export default {
  data () {
    return {
      checked: "",
      menberList: [],
      departData: [],

      checkedList: [],
      selectMenberList: [],
    };
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    type: {
      default: "",
      type: String,
    },
    oldSelectMenberList: [],
    isoldMenberList: false,
    isall: false,
    loadings: {
      default: false,
      type: Boolean,
    },
  },
  components: {
  },
  watch: {

    checkedList (val) {
      if (
        val.length === this.menberList.length &&
        this.menberList.length != 0
      ) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.selectMenberList = [];
      val.forEach((item) => {
        this.menberList.forEach((itemA) => {
          if (item === itemA._id) {
            console.log(item, itemA, 3333);
            this.selectMenberList.push(itemA);
          }
        });
      });
    },
    dialogVisible (val) {
      if (val && this.isoldMenberList) {
        this.checkedList = this.oldSelectMenberList;
      }
      this.checkedList = [];
      this.selectMenberList = [];
    },
  },
  methods: {
    delSelectMent (index) {
      this.checkedList.splice(index, 1);
      this.selectMenberList.splice(index, 1);
    },
    allCheckChange (val) {
      if (val) {
        this.checkedList = [];
        this.menberList.forEach((item) => {
          this.checkedList.push(item._id);
        });
      } else {
        this.checkedList = [];
      }
    },
    handleClose () {
      this.$emit("handleClose");
    },
    submit () {
      let name = [];

      this.checkedList.forEach((item) => {
        this.menberList.forEach((itemA) => {
          if (item === itemA._id) {
            name.push(itemA.name);
          }
        });
      });

      this.$emit("submit", this.checkedList, name);
    },
    nodeDepartClick (data) {
      let query = {
        status: "",
        limit: 999,
        page: 1,
        name: "",
        id: data.id,
      };
      if (this.departActive != data.id) {
        this.departActive = data.id;

        this.selectMenberList = [];
        this.checkedList = [];
      }
      this.checked = false;
      getDepartMember(query).then((res) => {
        this.menberList = res.data;
      });
    },

    treeDataChange (data) {
      data.forEach((item) => {
        this.$set(item, "id", item._id);
        this.$set(item, "label", item.name);
        delete item._id;
        delete item.name;
        if (item.children) {
          this.treeDataChange(item.children);
        }
      });
      return data;
    },
    getDepartMentList () {
      let type = {
        type: "1",
      };
      if (this.isall) {
        type.type = "all";
      }
      getDepartMent(type).then((res) => {
        this.departData = this.treeDataChange(res.data);
      });
    },
  },
  mounted () {
    this.getDepartMentList();
    console.log(this.loadings, 'this.loadings');
  },
  created () { },
};
</script>
<style lang="scss" scoped>
.menber-ul {
  padding: 12px 15px;
  box-sizing: border-box;
  height: 246px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  overflow-y: scroll;

  .menber-li {
    height: 36px;
    line-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 9px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .li-delete {
      display: none;

      i {
        font-size: 16px;
        cursor: pointer;
        color: #ff4646;
      }
    }

    .li-info {
      display: flex;

      align-items: center;

      .li-info-atvar {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
    }
  }

  .menber-li:hover {
    background: #f5f8fc;

    .li-delete {
      display: block;
    }
  }
}

::v-deep .el-tree>.el-tree-node {
  padding-left: 0px !important;
}

::v-deep .el-checkbox__label {
  display: none;
}

.title {
  font-size: 14px;
  margin: 20px 0 8px 0;
  font-weight: bold;
  color: #303133;
}

.select-menber-ul {
  height: 300px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  display: flex;
  align-items: center;

  .depart-warp {
    flex: 1;
    height: 100%;
    border-right: 1px solid #e4e7ed;
    padding: 16px 24px;
    box-sizing: border-box;
  }

  .menber-warp {
    flex: 1;
    height: 100%;

    .menber-title {
      padding: 16px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      line-height: 32px;
      justify-content: space-between;

      &>div:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #303133;
      }

      &>div:nth-child(2) {
        color: #409eff;
      }
    }

    .menber-list {
      overflow-y: scroll;
      height: 230px;

      .menber-list-li:hover {
        background: #f5f8fc;
      }

      .menber-list-li {
        font-size: 14px;
        padding: 0 24px;
        box-sizing: border-box;
        font-weight: 400;
        color: #303133;
        display: flex;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-between;

        .li-info {
          display: flex;
          align-items: center;

          .li-info-atvar {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            background: #409eff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.depart-ul {
  margin-top: 10px;
  overflow-y: scroll;
  height: 230px;

  .depart-li {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    padding: 8px 0;
    box-sizing: border-box;
    cursor: pointer;

    i {
      margin-right: 8px;
      color: #409eff;
      font-size: 16px;
    }
  }

  .depart-li-active {
    background: #f5f8fc;
  }

  .depart-li:hover {
    background: #f5f8fc;
  }
}
</style>
