<template>
  <div>
    <el-dialog
      title="成员列表"
      :visible="dialogVisible"
      width="50%"
      @close="close"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="menber-ul">
        <div
          class="menber-li"
          v-for="(item, index) in selectMenberList"
          :key="item._id"
        >
          <div class="li-info">
            <div class="name">{{ item.name[0] }}</div>
            <div>
              {{ item.name }}
            </div>
          </div>
          <div class="li-delete" @click="menberDel(item)">
            <i class="iconfont icon-shanchu"></i>
          </div>
        </div>
      </div>

      <div class="changetable">
        <ul class="changetable_tab">
          <li class="isactive">成员</li>
        </ul>
        <div class="content">
          <div class="depart" v-show="isType === '3'">
            <div class="select-menber-ul">
              <div class="depart-warp">
                <div class="depart-ul">
                  <el-tree
                    :data="departList"
                    node-key="id"
                    ref="departTree"
                    default-expand-all
                    :highlight-current="true"
                    :expand-on-click-node="false"
                    accordion
                    @node-click="nodeDepartClick"
                  >
                    <span slot-scope="{ node, data }">
                      <i
                        class="iconfont icon-zuzhibumen"
                        style="color: #409dff"
                      ></i>
                      <span style="margin-left: 10px">{{ node.label }}</span>
                    </span>
                  </el-tree>
                </div>
              </div>
              <div class="menber-warp">
                <div class="menber-title">
                  <div>已选择:{{ selectMenberList.length }}人</div>
                  <div>
                    全部
                    <el-checkbox
                      v-model="checked"
                      style="margin-left: 3px"
                      @change="allCheckChange"
                    ></el-checkbox>
                  </div>
                </div>

                <div class="menber-list">
                  <el-checkbox-group
                    v-model="checkedList"
                    style="height: 100%"
                    v-if="menberList.length > 0"
                  >
                    <div
                      class="menber-list-li"
                      v-for="(item, index) in menberList"
                      :key="index"
                    >
                      <div class="li-info">
                        <div class="li-info-atvar">{{ item.name[0] }}</div>
                        <div>{{ item.name }}</div>
                      </div>
                      <div class="li-check">
                        <el-checkbox :label="item._id"></el-checkbox>
                      </div>
                    </div>
                  </el-checkbox-group>
                  <div v-else>
                    <el-empty :image-size="100"></el-empty>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button style="margin-bottom: 10px" @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDepartMent,
  getRoleList,
  getDepartMember,
  getRoleMemberList,
} from "@/api/user";

export default {
  data() {
    return {
      isType: "3",
      departActive: null,
      departList: [],
      selectMenberList: [],
      checkedList: [],
      menberList: [],
      checked: false,
      roleList: [],
      allCheck: {},
      nowLabel: "",
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: "",
    },
    oldSelectMenberList: [],
    oldAllCheck: {},
  },
  watch: {
    checkedList(val) {
      this.allCheck[this.nowLabel].value = val;
      if (
        val.length === this.menberList.length &&
        this.menberList.length != 0
      ) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },

    allCheck: {
      handler(val) {
        for (let i in val) {
          console.log(i, this.nowLabel);
          if (i === this.nowLabel) {
            if (val[i].value) {
              this.checkedList = val[i].value;
            }
          }
        }
        let arr = [];
        let ids = [];
        for (let i in val) {
          if (val[i].data) {
            val[i].data.forEach((item) => {
              arr.push(item);
            });
          }
          if (val[i].value) {
            val[i].value.forEach((item) => {
              ids.push(item);
            });
          }
        }

        this.selectMenberList = [];
        console.log(this.allCheck);
        arr.forEach((item) => {
          ids.forEach((itemA) => {
            if (itemA === item._id) {
              this.selectMenberList.push(item);
            }
          });
        });
      },
      deep: true,
    },

    dialogVisible(val) {
      if (val) {
        this.getDepartMentList();
        this.selectMenberList = this.oldSelectMenberList;
        this.allCheck = this.oldAllCheck;
        console.log(this.oldSelectMenberList,this.selectMenberList)
        setTimeout(() => {
          if (this.data.defaultAllCheck) {
            this.allCheck = JSON.parse(
              JSON.stringify(this.data.defaultAllCheck)
            );
          } else {
            this.allCheck = {};
          }

          this.departActive = null;
          this.menberList = [];
        }, 200);
      } else {
        this.checked = false;
        this.selectMenberList = [];
        this.departActive = null;
        this.menberList = [];
        this.allCheck = {};
      }
    },
  },
  mounted() {},

  methods: {
    menberDel(item) {
      console.log(this.allCheck);
      for (let i in this.allCheck) {
        this.allCheck[i].value.forEach((itemA, index) => {
          if (item._id === itemA) {
            this.allCheck[i].value.splice(index, 1);
          }
        });
      }

      this.checkedList.forEach((itemA, index) => {
        if (item._id === itemA) {
          this.checkedList.splice(index, 1);
        }
      });
    },
    nodeDepartClick(data) {
      let query = {
        status: "",
        limit: 999,
        page: 1,
        name: "",
        id: data.id,
      };
      if (this.departActive != data.id) {
        this.departActive = data.id;
        this.nowLabel = data.id;

        this.checked = false;
        getDepartMember(query).then((res) => {
          this.menberList = res.data;

          if (this.allCheck[data.id]) {
            this.checkedList = this.allCheck[data.id].value;
          } else {
            this.$set(this.allCheck, [data.id], {});
            this.$set(this.allCheck[data.id], "data", res.data);
            this.$set(this.allCheck[data.id], "value", []);
          }
        });
      }
    },

    allCheckChange(val) {
      if (val) {
        this.checkedList = [];
        this.menberList.forEach((item) => {
          this.checkedList.push(item._id);
        });
      } else {
        this.checkedList = [];
      }
    },

    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function (node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach((child) => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },

    treeDataChange(data) {
      data.forEach((item) => {
        this.$set(item, "id", item._id);
        this.$set(item, "label", item.name);
        delete item._id;
        delete item.name;
        if (item.children) {
          this.treeDataChange(item.children);
        }
      });
      return data;
    },
    getDepartMentList() {
      let type = {
        type: "1",
      };
      getDepartMent(type).then((res) => {
        this.departList = this.treeDataChange(res.data);
      });
    },
    getRoleList() {
      let type = {
        type: "1",
      };
      getRoleList(type).then((res) => {
        this.roleList = this.treeDataChange(res.data);
      });
    },
    submit() {
      this.$emit("submit", this.selectMenberList, this.allCheck);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tree > .el-tree-node {
  border-bottom: unset !important;
  padding-left: unset !important;
  padding-right: 14px;
  box-sizing: border-box;
  position: relative;
}
::v-deep .el-tree-node__content {
  position: relative;
}
::v-deep .el-checkbox {
  position: absolute;
  right: 0;
  top: 9px;
}
.menber-title {
  ::v-deep .el-checkbox {
    position: absolute;
    right: unset !important;
    top: unset !important;
  }
}
::v-deep .el-checkbox__label {
  display: none;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.menber-ul {
  padding: 12px 15px;
  box-sizing: border-box;
  height: 246px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  overflow-y: scroll;

  .menber-li {
    height: 36px;
    line-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 9px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;
    .li-delete {
      display: none;
      i {
        font-size: 16px;
        cursor: pointer;
        color: #ff4646;
      }
    }
    .li-info {
      display: flex;

      align-items: center;
      i {
        color: #409dff;
        margin-right: 10px;
      }
      .name {
        width: 30px;
        height: 30px;
        background: #409eff;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        color: #fff;
        margin-right: 10px;
      }
      .li-info-atvar {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
    }
  }
  .menber-li:hover {
    background: #f5f8fc;
    .li-delete {
      display: block;
    }
  }
}
.showtable {
  height: 170px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  overflow-y: scroll;
  ul {
    margin: 0;
    padding-left: 10px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      width: 100%;
      height: 35px;
      background: #f5f8fc;
      border-radius: 4px;

      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #303133;
      line-height: 28px;

      .icon-shanchu {
        display: none;
        color: #ff4646;
      }
      i {
        color: #409eff;
        margin-right: 10px;
      }
      .head_portrait {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: #409eff;
        border-radius: 50%;
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        font-style: normal;
        text-align: center;
      }
    }
    li:hover {
      .icon-shanchu {
        display: block;
        color: #ff4646;
      }
    }
  }
}
.changetable {
  .changetable_tab {
    display: flex;
    font-size: 14px;
    margin-top: 15px;
    li {
      margin-right: 15px;
      cursor: pointer;
    }
    .isactive {
      color: #409eff;
      border-bottom: 2px solid #409eff;
    }
  }
  .content {
    height: 300px;
    background: #ffffff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    padding: 12px;

    ::v-deep .el-tree > .el-tree-node {
      padding: 0;
      border: 0;
    }
    .found {
      display: flex;
      .found_1 {
        width: 50%;
        height: 302px;
        padding: 0 10px;
        border-right: 1px solid #e4e7ed;
        overflow-y: scroll;
      }
      .found_2 {
        width: 50%;
        height: 302px;
        overflow-y: scroll;
        .found_2_heade {
          display: flex;
          align-items: center;
          line-height: 32px;
          justify-content: space-between;
          padding: 16px 24px;
          box-sizing: border-box;
        }
        ul {
          display: block;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            margin: 0;
            margin-bottom: 15px;
            ::v-deep .el-checkbox__label {
              display: none;
            }
          }
        }
        .head_portrait {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: #409eff;
          border-radius: 50%;
          display: inline-block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          font-style: normal;
          text-align: center;
        }
        .el-checkbox {
          all: initial;
        }
      }
    }
  }
}
.select-menber-ul {
  height: 300px;
  background: #ffffff;

  border-radius: 4px;
  display: flex;
  align-items: center;
  .depart-warp {
    flex: 1;
    height: 100%;
    border-right: 1px solid #e4e7ed;
    padding: 16px 24px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  .menber-warp {
    flex: 1;
    height: 100%;

    .menber-title {
      padding: 16px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      line-height: 32px;
      justify-content: space-between;
      & > div:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #303133;
      }
      & > div:nth-child(2) {
        color: #409eff;
      }
    }
    .menber-list {
      overflow-y: scroll;
      height: 230px;
      position: relative;

      .menber-list-li:hover {
        background: #f5f8fc;
      }
      .menber-list-li {
        font-size: 14px;
        padding: 0 24px;
        box-sizing: border-box;
        font-weight: 400;
        color: #303133;
        display: flex;
        cursor: pointer;
        margin-bottom: 15px;
        align-items: center;
        position: relative;
        justify-content: space-between;
        ::v-deep .el-checkbox {
          position: absolute;
          right: 5px;
          top: 5px;
        }
        .li-info {
          display: flex;
          align-items: center;
          .li-info-atvar {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            background: #409eff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.el-dialog__wrapper {
  z-index: 3001 !important;
}
</style>
