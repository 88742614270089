<template>
	<div class="tools-box">
		<el-dialog
			:visible.sync="openDialog"
			width="338px"
			:modal="false"
			:before-close="imgHandleClose"
			class="setDialog"
			:style="position">
			<div class="dialog-cont">
				<div class="lable">链接地址</div>
				<el-input style="width: 100%;" v-model="datas.data.src" placeholder="请输入链接地址"></el-input>
			</div>
		</el-dialog>
		
	</div>
	
</template>

<script>
	
  export default {
		props: {
			openDialog: false,
		  datas: Object,
			position: "",
		},
    data() {
      return {
      };
    },
		watch: {
		},
    components: {
			
    },
		created() {
		},
		mounted() {
		},
    methods: {
			imgHandleClose(){
				this.$emit("handleClose", false);
			},
		}
  };
</script>

<style lang="scss" scoped>
</style>