import request from "@/utils/request";


// 前端事件设置后获取数据

export function getFrontNdEventList(data) {
    return request({
      url: "/api/formRequest/getData",
      method: "post",
      data,
    //  baseURL:"/Api2"
    });
  }


// 保存数据
  export function getFormRequest(data) {
    return request({
      url: "/api/formRequest/insert",
      method: "post",
      data,
      //  baseURL:"/Api2"
    });
  }

  //前端事件回显
  export function getConfig(data) {
    return request({
      url: "/api/formRequest/getConfig",
      method: "post",
      data,
      //  baseURL:"/Api2"
    });
  }

  // 表格修改
  export function setupdate(data) {
    return request({
      url: "/api/formData/update",
      method: "post",
      data,
      //  baseURL:"/Api2"
    });
  }

//保存编辑设置
export function Setediting(data) {
  return request({
    url: "/api/formSet/update",
    method: "post",
    data,
  });
}


// 获取保存编辑的状态
export function Getediting(data) {
  return request({
    url: "/api/formSet/get",
    method: "post",
    data,
  });
}



// 下载导入的文件

export function getDownLoad(data) {
  return request({
    url: "/api/import/import",
    method: "post",
    data,
  });
}


// 打印模版列表

export function getPrint(appid,form_id,is_excel) {
  return request({
    url: `/api/luckysheet/list?app_id=${appid}&form_id=${form_id}&is_excel=${is_excel}`,
    method: "GET",
  });
}


