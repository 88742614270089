<template>
  <div>
    <div class="dataLoad-warp">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: "dataLoad",
  data() {
    return {};
  },
  props: ["text"],
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.dataLoad-warp {
  width: 350px;
  max-width: 100%;

  background: #fff;
  color: #999;
  height: 34px;
  line-height: 32px;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  border: 1px dashed #e0e0e0;
}
</style>
