<template>
  <div :class="islook ? 'gridLayout islook' : 'gridLayout'">
    <!-- <div class="grid-top-box" v-if="!islook">
      <div class="go-out"><i class="el-icon-arrow-left" @click="goTop"></i>{{ name }}</div>
	  <div class="title">仪表盘</div>
	  <div>
		  <el-button @click="previewDialog = true">预览</el-button>
		  <el-button type="primary" @click="save()">保存</el-button>
	  </div>

    </div> -->

    <div class="grid-menu-box" v-if="!islook">
      <div class="menu-list" v-for="(item, index) in menu" :key="index">
        <div class="title">{{ item.title }}</div>
        <div
          class="menu-item droppable-element"
          v-for="(item2, index2) in item.list"
          :key="index2"
          @drag="modelDrag($event, item2.data)"
          @dragend="modelDragEnd($event, item2.data)"
          draggable="true"
          unselectable="on"
        >
          <i :class="item2.icon"></i> {{ item2.name }}
        </div>
      </div>
    </div>
    <div id="grid-cont-box">
      <grid-layout
        ref="gridlayout"
        :layout.sync="layout"
        :col-num="24"
        :row-height="30"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="(item, index) in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :isDraggable="item.isdrag && !islook"
        >
          <div
            :ref="'editorRef' + item.i"
            :class="
              item.data.type === 'img' || item.data.istb
                ? 'grid-item-box no-padding'
                : 'grid-item-box'
            "
            :style="
              'background-color:' +
              (item.data.background ? item.data.background.color : '') +
              ';background-image:url(' +
              (item.data.background && item.data.background.img.length > 0
                ? url + item.data.background.img[0].uid
                : '') +
              ');background-size: ' +
              (item.data.background ? item.data.background.radio : '') +
              ';'
            "
          >
            <gridItemTools
              v-if="!islook"
              :datas="item"
              @colorGrid="colorGrid"
              @editGrid="editGrid"
              @addGrid="addGrid"
              @delGrid="delGrid"
            ></gridItemTools>
            <div class="grid-item-type">
              <imgGrid v-if="item.data.type === 'img'" :datas="item"></imgGrid>
              <editorGrid
                v-else-if="item.data.type === 'text'"
                :datas="item"
              ></editorGrid>
              <timeGrid
                v-else-if="item.data.type === 'time'"
                :datas="item"
              ></timeGrid>
              <linkGrid
                v-else-if="item.data.type === 'link'"
                :datas="item"
                :noglink="!islook"
              ></linkGrid>
              <iframeGrid
                v-else-if="item.data.type === 'iframe'"
                :datas="item"
              ></iframeGrid>

              <echarts
                v-else-if="item.data.type === 3"
                :settingOption="item.set_config ? item.set_config : ''"
                :chartId="item.i + '22'"
              ></echarts>

              <!-- “筛选条件”组件  -->
              <filterChart
                ref="filterChart"
                v-else-if="item.data.type === 'filter'"
                :key="'filter' + item.i"
                @filterUpdate="filterUpdate"
                :index="index"
                :islook="islook"
                :layoutData="layout"
                :id="item.i"
              />

              <detailsForm
                v-else-if="item.data.type === 1"
                :settingOption="item.set_config ? item.set_config : ''"
              >
              </detailsForm>
              <dataForm
                v-else-if="item.data.type === 2"
                :settingOption="item.set_config ? item.set_config : ''"
              >
              </dataForm>
            </div>
          </div>
        </grid-item>
      </grid-layout>
    </div>

    <toolsBox
      :opennum="openEditData"
      :opencolornum="openColorEditData"
      :datas="editData"
      :noglink="!islook"
    ></toolsBox>

    <el-dialog
      title="添加图表"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div style="color: #409eff">表单</div>
      <div style="margin: 5px 0 15px 0">
        <el-input placeholder="请输入名称" v-model="form_search_txt">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="formListSearchFun"
          ></el-button>
        </el-input>
      </div>
      <div class="form-list-box">
        <template
          v-if="form_list_search === '' || item.name.includes(form_list_search)"
        >
          <div
            class="checked-box content-li islist"
            v-for="(item, index) in form_list"
            :key="index"
            @click="setFormListCheck(item._id)"
          >
            <div class="app-icon" :style="{ background: item.color }">
              <i
                :class="'iconfont iconfont-1 ' + item.icon"
                v-if="item.icon"
              ></i>

              <i v-else :class="'iconfont-1 icon-shuju5'"></i>
            </div>
            <div>
              <div class="txt">{{ item.name }}</div>
              <el-checkbox v-model="item._id"></el-checkbox>
              <!-- <el-checkbox
                v-model="form_checked == item._id ? true : false"
              ></el-checkbox> -->
            </div>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="gotoChatWithin">确 定</el-button>
      </span>
    </el-dialog>

    <preview
      v-if="previewDialog"
      :datas="layout"
      :previewDialog="previewDialog"
      @previewClose="previewClose"
    ></preview>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  postWmtestYBP,
  getWmYBP,
  getAppGetAppForm,
  postCreateSubYBP,
  getDraftList,
  getSingleFieldData,
} from "@/api/app";

import { getTxPermissions } from "@/api/user";
import VueGridLayout from "vue-grid-layout";
import gridItemTools from "./components/gridItemTools";
import imgGrid from "./components/imgGrid/imgGrid";
import editorGrid from "./components/editorGrid/editorGrid";
import timeGrid from "./components/timeGrid/timeGrid";
import linkGrid from "./components/linkGrid/linkGrid";
import iframeGrid from "./components/iframeGrid/iframeGrid";
import toolsBox from "./components/toolsBox";
import preview from "./components/preview/preview";

import echarts from "@/views/chatWithin/src/components/echats/index";
import dataForm from "@/views/chatWithin/src/dataSheet/components/dataForm/index";
import detailsForm from "@/views/chatWithin/src/dataSheet/components/detailsForm/index";
import filterChart from "./components/filterChart/index";

let DragPos = { x: null, y: null, w: 8, h: 7, i: null, isdrag: true };
export default {
  props: {
    islook: false,
    appId: "",
    formId: "",
    datas: null,
    editName: "",
    currentItem: {},
  },
  watch: {
    formId(val) {
      this.form_id = val;
      console.log("撒大大的", val);
      this.getLayoutData();
    },
    editName(val) {
      this.name = val;
    },
  },
  data() {
    return {
      name: "未命名仪表盘",
      url: "http://47.101.160.60:8787/api/upload/handleRequest?attachmen_id=",
      app_id: "",
      form_id: "",
      dialogVisible: false,

      conditionOption: [],
      formNameChecked: true,
      condition: {
        conditionfileds: "",
        conditionname: "",
        condition: "",
        conditionway: "chosevalue",
        value: null,
      },
      tagType: "",
      tranName: "",

      form_list: [],
      form_list_search: "",
      form_search_txt: "",
      form_checked: "",
      mouseXY: { x: null, y: null },
      openEditData: 0,
      openColorEditData: 0,
      editData: {},
      layout: [],
      previewDialog: false,
      // currentItem: {},
      menu: [
        {
          title: "图表",
          list: [
            {
              name: "统计表",
              icon: "iconfont icon-yundan",
              data: {
                istb: true,
                type: 3,
              },
            },
            {
              name: "明细表",
              icon: "iconfont icon-yibiaoban",
              data: {
                istb: true,
                type: 1,
              },
            },
            {
              name: "数据管理表",
              icon: "iconfont icon-checkbill",
              data: {
                istb: true,
                type: 2,
              },
            },
          ],
        },
        {
          title: "组件",
          list: [
            {
              name: "图片组件",
              icon: "iconfont icon-charutupian",
              data: {
                type: "img",
                file_list: [],
                radio: "cover",
                auto: true,
                interval: 3,
              },
            },
            {
              name: "文本组件",
              icon: "iconfont icon-fuwenbenbianjiqi_wenben",
              data: {
                type: "text",
                html: "",
                background: {
                  color: "",
                  img: [],
                  radio: "cover",
                },
              },
            },
            {
              name: "实时时间",
              icon: "iconfont icon-31shijian",
              data: {
                type: "time",
                timetype: 1,
                font: "hmt",
                background: {
                  color: "",
                  img: [],
                  radio: "cover",
                },
              },
            },
            {
              name: "快捷入口",
              icon: "iconfont icon-kuaijieyingyon",
              data: {
                type: "link",
                title: "",
                list_type: "card",
                list: [],
                checkedlist: [],
                openlist: [],
                background: {
                  color: "",
                  img: [],
                  radio: "cover",
                },
              },
            },
            {
              name: "嵌入式页面",
              icon: "iconfont icon-weixinxiaochengxu",
              data: {
                type: "iframe",
                src: "",
                background: {
                  color: "",
                  img: [],
                  radio: "cover",
                },
              },
            },
          ],
        },
        {
          title: "筛选条件",
          list: [
            {
              name: "筛选条件",
              icon: "iconfont icon-checkbill",
              data: {
                isfilter: true,
                type: "filter",
              },
            },
          ],
        },
      ],
      checkChartList: [], // 筛选器选中图表数组
      fieldValue: "", // 筛选字段
      isChartChecked: false, // 是否选中图表数据
      fieldOptions: [], //筛选下拉列表
      type: "", // 筛选下拉选中组件类型
    };
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    gridItemTools,
    imgGrid,
    editorGrid,
    timeGrid,
    linkGrid,
    iframeGrid,
    toolsBox,
    echarts,
    dataForm,
    detailsForm,
    preview,
    filterChart,
  },
  created() {
    this.app_id = this.$route.query.appId;
    this.form_id = this.$route.query.formId;
    if (this.islook) {
      this.app_id = this.appId;
      this.form_id = this.formId;
    }
    if (this.datas && this.datas.length !== null) {
      this.layout = this.datas;
    } else {
      this.getLayoutData();
    }
  },
  mounted() {
    document.addEventListener(
      "dragover",
      (e) => {
        this.mouseXY.x = e.clientX;
        this.mouseXY.y = e.clientY;
      },
      false
    );
    // this.setData()
  },
  methods: {
    handleOutsideClick(id) {
      // 判断点击的区域是否在 div 内部
      return (event) => {
        this.$nextTick(() => {
          if (
            this.$refs["editorRef" + id][0] &&
            !this.$refs["editorRef" + id][0].contains(event.target)
          ) {
            this.layout.forEach((item, index) => {
              if (item.i == id) {
                item.isdrag = true;
              }
            });
            document.removeEventListener("click", this.handleOutsideClick);
          }
        });
      };
    },
    getFormList() {
      getAppGetAppForm(this.app_id).then((res) => {
        this.form_list = res.data;
      });
    },
    formListSearchFun() {
      this.form_list_search = this.form_search_txt;
    },
    setFormListCheck(id) {
      this.form_checked = id;
    },
    openFormList() {
      this.form_checked = "";
      this.getFormList();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.form_checked = "";
      this.layout.pop();
    },
    previewClose() {
      // this.getLayoutData();
      this.layout.forEach(item => {
        if (item.set_config && item.set_config.draggableObj) {
          item.set_config.draggableObj.filterList = []
        }
      })
      this.$nextTick(() => {
        this.previewDialog = false;
      })
    },
    gotoChatWithin() {
      let obj = this.layout.slice(-1)[0];
      postCreateSubYBP(this.app_id, this.form_id, this.form_checked, obj).then(
        (res) => {
          if (obj.data.type === 3) {
            this.$router.push({
              path: "/ChatWithin",
              query: {
                formId: this.form_id,
                bi_id: res.data,
                data_source_id: this.form_checked,
              },
            });
          } else {
            this.$router.push({
              path: "/DataSheet",
              query: {
                formId: this.form_id,
                bi_id: res.data,
                type: obj.data.type,
                data_source_id: this.form_checked,
              },
            });
          }
        }
      );
    },
    colorGrid(data) {
      this.openColorEditData++;
      this.editData = data;
    },
    editGrid(data) {
      if (data.data.type === 'filter') {
        this.openFilterFormList(data.i);
      }
      if (data.data.type == "text") {
        data.isdrag = !data.isdrag;
        let targetIndex = this.layout.findIndex((item) => item.i === data.i);
        if (targetIndex !== -1) {
          this.$set(this.layout, targetIndex, data);
        }
        if (!data.isdrag) {
          // 添加点击事件监听器
          document.addEventListener("click", this.handleOutsideClick(data.i));
        } else {
          // 移除点击事件监听器
          document.removeEventListener(
            "click",
            this.handleOutsideClick(data.i)
          );
        }
      } else if (data.data.istb) {
        if (data.data.type === 3) {
          this.$router.push({
            path: "/ChatWithin",
            query: {
              formId: this.form_id,
              bi_id: data._id,
              data_source_id: data._id,
            },
          });
        } else {
          this.$router.push({
            path: "/DataSheet",
            query: {
              formId: this.form_id,
              bi_id: data._id,
              type: data.data.type,
              data_source_id: data._id,
            },
          });
        }
      } 
      this.editData = data;
    },
    addGrid(data) {
      let edit_data = cloneDeep(data);
      if (edit_data._id) {
        edit_data._id = "";
      }
      edit_data.i = this.generateTimestampId();
      this.layout.unshift(edit_data);
    },
    delGrid(i) {
      this.layout.splice(
        this.layout.findIndex((item) => item.i === i),
        1
      );
    },
    getLayoutData() {
      getWmYBP(this.app_id, this.form_id).then((res) => {
        this.layout = res.data.layout;
        console.log("组件图层", this.layout);
        this.name = res.data.name;
        this.$emit("getChartsInfo", res.data);
      });
    },
    look() {
      this.previewDialog = true;
    },
    save() {
      // 手动清除筛选条件
      this.layout.forEach(item => {
        if (item.set_config && item.set_config.draggableObj) {
          item.set_config.draggableObj.filterList = []
        }
      })
      postWmtestYBP({
        app_id: this.app_id,
        form_id: this.form_id,
        data: this.layout,
        name: this.name,
      }).then((res) => {
        this.$message.success(res.msg);
      });
    },
    modelDrag(params, data) {
      this.drag(this.mouseXY, cloneDeep(data));
    },
    modelDragEnd(params, data) {
      this.dragend(this.mouseXY, cloneDeep(data));
    },
    drag: function (params, data) {
      let parentRect = document
        .getElementById("grid-cont-box")
        .getBoundingClientRect();
      let mouseInGrid = false;
      let mouseXY = params;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (
        mouseInGrid === true &&
        this.layout.findIndex((item) => item.i === "drop") === -1
      ) {
        this.layout.push({
          x: (this.layout.length * 2) % (this.colNum || 24),
          y: this.layout.length + (this.colNum || 24), // puts it at the bottom
          data: data,
          isdrag: true,
          w: 8,
          h: 7,
          i: "drop",
        });
      }
      let index = this.layout.findIndex((item) => item.i === "drop");
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[
            this.layout.length
          ].$refs.item.style.display = "none";
        } catch {}
        let el = this.$refs.gridlayout.$children[index];
        el.dragging = {
          top: mouseXY.y - parentRect.top,
          left: mouseXY.x - parentRect.left,
        };
        let new_pos = el.calcXY(
          mouseXY.y - parentRect.top,
          mouseXY.x - parentRect.left
        );
        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent(
            "dragstart",
            "drop",
            new_pos.x,
            new_pos.y,
            data.type === 'filter' ? 4 : 7,
            data.type === 'filter' ? 6 : 8,
          );
          // DragPos.i = String(index);
          DragPos.i = this.generateTimestampId();
          DragPos.x = this.layout[index].x;
          DragPos.y = this.layout[index].y;
          DragPos.data = this.layout[index].data;
          DragPos.isdrag = this.layout[index].isdrag;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent(
            "dragend",
            "drop",
            new_pos.x,
            new_pos.y,
            data.type === 'filter' ? 4 : 7,
            data.type === 'filter' ? 6 : 8,
          );
          this.layout = this.layout.filter((obj) => obj.i !== "drop");
        }
      }
    },
    dragend: function (params, data) {
      let parentRect = document
        .getElementById("grid-cont-box")
        .getBoundingClientRect();
      let mouseInGrid = false;
      let mouseXY = params;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.$refs.gridlayout.dragEvent(
          "dragend",
          "drop",
          DragPos.x,
          DragPos.y,
          data.type === 'filter' ? 4 : 7,
          data.type === 'filter' ? 6 : 8,
        );
        this.layout = this.layout.filter((obj) => obj.i !== "drop");
        // UNCOMMENT below if you want to add a grid-item
        if (data.istb) {
          this.openFormList();
        }
        if (data.isfilter) {
          this.openFilterFormList();
        }

        // this.$refs[0].dialogVisible = true;
        this.layout.push({
          x: DragPos.x,
          y: DragPos.y,
          data: data,
          isdrag: true,
          w: data.type === 'filter' ? 6 : 8,
          h:  data.type === 'filter' ? 4 : 7,
          i: DragPos.i,
        });
        this.$refs.gridlayout.dragEvent(
          "dragend",
          DragPos.i,
          DragPos.x,
          DragPos.y,
          6,
          8
        );
        try {
          this.$refs.gridLayout.$children[
            this.layout.length
          ].$refs.item.style.display = "block";
        } catch {}
      }
    },

    openFilterFormList(id) {
      // this.form_checked = "";
      // this.openFilterFormList();
      if (id) {
        this.$nextTick(() => {
          let index
          this.$refs.filterChart.forEach((item,inx) => {
            if (item.id === id) {
              index = inx
            }
          });
          this.$refs.filterChart[index].openFilterDialog()
        });
      } else {
        this.$nextTick(() => {
          let newFilter = this.$refs.filterChart[this.$refs.filterChart.length - 1];
          newFilter.openFilterDialog();
        });
      }
      // this.$refs.filterChart[this.$refs.filterChart.length-1].getLayoutData()
    },

    filedsChange() {
      this.tranName = this.condition.conditionfileds;
      let item = this.fieldsList.filter((item) => {
        return item.__config__.label == this.condition.conditionfileds;
      });
      this.currentItem = item[0];
      this.condition.conditionway = "chosevalue";
      // this.setData();
    },

    // 生成结合时间戳的随机ID的函数
    generateTimestampId() {
      const timestamp = Date.now().toString();
      const random = Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
      const id = `${timestamp}${random}`;
      return id;
    },
    goBack() {
      this.$router.go(-1);
    },
    // 更新帅选条件组件的配置--用于预览、保存提交
    filterUpdate(val) {
      // this.$set(this.layout[val.index], "_id", val.id);
      this.$set(this.layout[val.index], "content", val.data);
      
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 12px;
  /* 设置滚动条宽度 */
  height: 18px;
  /* 设置滚动条高度 */
}

::v-deep ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #e0e3e7;
  height: 20px;
}

::v-deep ::-webkit-scrollbar-track {
  background-color: transparent;
}

::v-deep .vue-grid-item > .vue-resizable-handle {
  z-index: 30;
}

::v-deep .vue-grid-item.vue-grid-placeholder {
  background: #409eff;
}

::v-deep .echarts-content {
  div {
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

.islook.gridLayout {
  height: 100%;

  #grid-cont-box {
    height: 100%;
  }
}

.gridLayout {
  display: flex;
  background-color: #f5f8fc;
  flex-wrap: wrap;
  height: calc(100vh - 49px);

  .grid-top-box {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px rgba(15, 108, 207, 0.1);
    // border-bottom: 1px solid #e5ecec;
    position: relative;
    z-index: 10;

    .go-out {
      i {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .title {
      color: #409eff;
      font-weight: bold;
    }
  }

  .grid-menu-box {
    width: 168px;
    background-color: #fff;
    height: calc(100vh - 49px);
    overflow-y: auto;
    box-shadow: 2px 0px 8px 0px rgba(15, 108, 207, 0.1);
    padding: 24px;
    box-sizing: border-box;
    font-size: 14px;

    .menu-list {
      margin: 20px 0;

      .title {
        font-weight: bold;
        color: #409eff;
      }

      .menu-item {
        text-align: center;
        color: #303133;
        margin-top: 12px;
        line-height: 32px;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        cursor: pointer;
      }

      .menu-item:hover {
        color: #fff;
        background: #409eff;
        border: 1px solid #409eff;
      }
    }
  }

  #grid-cont-box {
    flex: 1;
    height: calc(100vh - 49px);
    overflow-y: auto;

    .vue-grid-item {
      background-color: #fff;
      box-shadow: 0px 0px 8px 0px rgba(15, 108, 207, 0.06);
      display: flex;
      flex-direction: column;

      /* 设置主轴为纵向 */
      .grid-item-box {
        flex: 1;
        padding: 24px;
        border: 1px dashed rgba(255, 255, 255, 0);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        background-repeat: no-repeat;
        background-position: center;

        .grid-item-type {
          flex: 1;
        }
      }

      .grid-item-box.no-padding {
        padding: 0;
      }

      .grid-item-box:hover {
        border: 1px dashed #409eff;
      }

      .grid-item-box:hover .grid-item-tools {
        display: flex;
      }
    }
  }
}

.islook .grid-item-box:hover {
  border: 1px dashed #fff !important;
}

.islook ::v-deep .vue-resizable-handle {
  display: none !important;
}

.app-icon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  background: rgb(255, 164, 47);
  border-radius: 4px;
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  color: #fff;
}

.form-list-box {
  height: 400px;
  overflow-y: auto;
}

.content-li.islist {
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  cursor: pointer;
  padding: 0 10px;

  .app-icon {
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin-right: 10px;

    i {
      font-size: 18px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  & > div:nth-child(2) .txt {
    flex: 1;
    margin: 0;
    text-align: left;
    display: -webkit-box;
    /* 将元素设置为弹性容器 */
    -webkit-line-clamp: 2;
    /* 指定最多显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置垂直布局 */
    overflow: hidden;
  }
}

.content-li.islist:hover {
  background-color: #f5f5f5;
}
.filterDialogClass {
  .draggableItem-li {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 25px;
    background: #409eff;
    border-radius: 13px;
    cursor: pointer;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 20px;

    & > div:nth-child(1) {
      margin: 0 5px;
    }

    i {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .targat-dict {
    font-size: 12px;
    margin: 0 5px 0 0 !important;
  }

  .item {
    padding: 5px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .item:hover {
    background: rgb(240, 241, 244);
  }

  .content-label {
    display: flex;
    align-items: center;
  }

  .content-value {
    padding: 5px 0;
    box-sizing: border-box;
  }

  .is-prohibit {
    background: rgb(240, 241, 244);
    pointer-events: auto !important;
    cursor: not-allowed !important;

    .el-icon-arrow-down {
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
  }
  .content {
    .left {
      width: 30%;
    }
    .right,
    .center {
      width: 30%;
      border-left: 1px solid #eee;
      padding: 0 1%;
    }
  }
  .addTag {
    overflow: auto;
    white-space: nowrap;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    margin-top: 5px;

    .el-tag {
      margin-right: 10px;
      min-width: 0;
      .el-tag .el-icon-close {
        left: 0px;
      }
    }

    .button-new-tag {
      margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 100px;
      vertical-align: bottom;
    }
  }
}
</style>