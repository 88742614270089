<!--  -->
<template>
  <div ref="datePicker">
    <div v-if="type === 'time'">
      <el-time-picker v-model="data.__config__.defaultValue" placeholder="请选择时间" :value-format="format" :format="format"
        @change="sendValue" :disabled="data.disabled" :picker-options="pickerOptions">
      </el-time-picker>
    </div>
    <div v-else-if="type === 'timerang'">
      <el-time-picker v-model="data.__config__.defaultValue" placeholder="选择时间范围" is-range @change="sendValue"
        :value-format="format" :format="format" range-separator="至" :disabled="data.disabled">
      </el-time-picker>
    </div>
    <div v-else-if="type === 'date'">
      <div v-if="data.valueType == 'currentDate' && $store.state.app.type_time == 0">
        <span v-if="format == 'yyyy-MM'">
          <el-date-picker v-model="defaultValue_1" placeholder="选择日期" @change="sendValue" :type="format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'date'
            " :value-format="format" :format="format" :disabled="data.disabled" :picker-options="pickerOptions">
          </el-date-picker>
        </span>
        <span v-if="format === 'yyyy-MM-dd'">
          <el-date-picker v-model="defaultValue_1" placeholder="选择日期" @change="sendValue" :type="format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'date'
            " :value-format="format" :format="format" :disabled="data.disabled" :picker-options="pickerOptions">
          </el-date-picker>
        </span>
      </div>

      <span v-else>
        <el-date-picker v-model="data.__config__.defaultValue" placeholder="选择日期" @change="sendValue" :type="format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'date'
          " :value-format="format" :format="format" :disabled="data.disabled" :picker-options="pickerOptions">
        </el-date-picker>
      </span>

    </div>
    <div v-else-if="type === 'daterang'">
      <el-date-picker v-model="data.__config__.defaultValue" placeholder="选择日期" @change="sendValue" type="daterange"
        :value-format="format" :format="format" :disabled="data.disabled">
      </el-date-picker>
    </div>
    <div v-else-if="type === 'datetimerang'">
      <el-date-picker v-model="data.__config__.defaultValue" type="datetimerange" @change="sendValue"
        :value-format="format" :format="format" :disabled="data.disabled">
      </el-date-picker>
    </div>
    <div v-else-if="type === 'datetime'">
      <div v-if="data.valueType == 'currentDate' && $store.state.app.type_time == 0">
        <span v-if="data.format == 'yyyy-MM-dd HH:mm:ss'">
          <el-date-picker @change="sendValue" v-model="defaultValue" type="datetime" :value-format="format"
            :format="format" :disabled="data.disabled" :picker-options="pickerOptions">
          </el-date-picker>
        </span>
        <span v-if="data.format == 'yyyy-MM-dd HH:mm'">
          <el-date-picker @change="sendValue" v-model="defaultValue_3" type="datetime" :value-format="format"
            :format="format" :disabled="data.disabled" :picker-options="pickerOptions">
          </el-date-picker>
        </span>
      </div>
      <span v-else>
        <el-date-picker @change="sendValue" v-model="data.__config__.defaultValue" type="datetime"
          :value-format="format" :format="format" :disabled="data.disabled">
        </el-date-picker>
      </span>


    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      format: "",
      defaultValue: '',
      defaultValue_1: '',
      defaultValue_2: '',
      defaultValue_3: '',
      pickerOptions: {
        shortcuts: [
          {
            text: "选择当前时间",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        console.log(newVal, "typeleixin");
      },
      deep: true,
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sendValue(value) {
      console.log(value, "value");

      let obj = {
        name: this.data.__vModel__,
        value: value,
      };
      console.log(obj, "obj");

      this.$emit("value", obj);
    },


    setDefaultValue() {
      // 获取当前时间
      const now = new Date();
      const start = new Date(now.getTime() - 3600 * 1000 * 24); // 假设默认开始时间是昨天
      const end = new Date(); // 默认结束时间是当前时间

      // 格式化日期时间
      const formatTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
      const formatTime_1 = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      const formatTime_2 = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}`;
      };
      const formatTime_3 = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      };

      // 设置默认值
      this.defaultValue = formatTime(end)
      this.defaultValue_1 = formatTime_1(end)
      this.defaultValue_2 = formatTime_2(end)
      this.defaultValue_3 = formatTime_3(end)

      if (this.data.format === "yyyy-MM" && this.data.valueType === "currentDate") {

        let obj_2 = {
          name: this.data.__vModel__,
          value: this.defaultValue_2,
        };

        this.$emit("value", obj_2);

      } else if (this.data.format === "yyyy-MM-dd" && this.data.valueType === "currentDate") {
        let obj_1 = {
          name: this.data.__vModel__,
          value: this.defaultValue_1,
        };

        this.$emit("value", obj_1);

      }
      else if (this.data.format === "yyyy-MM-dd HH:mm" && this.data.valueType === "currentDate") {
        let obj_3 = {
          name: this.data.__vModel__,
          value: this.defaultValue_3,
        };

        this.$emit("value", obj_3);

      } else if (this.data.format === "yyyy-MM-dd HH:mm:ss" && this.data.valueType === "currentDate") {

        let obj = {
          name: this.data.__vModel__,
          value: this.defaultValue,
        };
        this.$emit("value", obj);

      }
    }
  },

  mounted() {
      setTimeout(() => {
        if (this.$store.state.app.type_time == 0) {
        this.setDefaultValue()
        }
      }, 1500);
    
    if (this.format === "yyyy-MM" || this.format === "yyyy-MM-dd") {
      this.type = "date";
    } else {
      this.type = "datetime";
    }
  },
  created() {
    if (this.data.valueType === "dataActive") {

      this.$bus.$emit("dataLink", this.data);
    }
    if (this.data.valueType === "formula") {
      this.$bus.$emit("formula", this.data);
    }
    this.format = this.data.format;
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-date-editor {
  width: 100%;
}
</style>
