<template>
  <div>
    <div v-if="!data.takePictures">
      <el-upload :ref="data.__vModel__" :file-list="fileList" :action="data.action" :multiple="data.multiple"
        :accept="data.accept" :before-upload="beforeUploadFun" :limit="limit" :on-success="successFun"
        :on-remove="removeFun" name="file" list-type="picture-card" :disabled="data.disabled" :on-preview="previewFun"
        :on-exceed="exceedFun" :data="{
          app_id: appId,
          form_id: formId,
        }">
        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <!-- <el-button size="small" type="primary" icon="el-icon-upload" :disabled="data.disabled">{{ "点击上传" }}</el-button> -->
        <div slot="tip" class="el-upload__tip">
          只能上传不超过 {{ data.__config__.fileSize || 2
          }}{{ data.__config__.sizeUnit || "M" }} 的图片
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
    <div v-else>
      <el-image v-for="(item,index) in fileList"
        style="width: 100px; height: 100px"
        :src="item.url"
        :key="index" 
      />
      <span style="color: red;">拍照上传</span>（该功能仅支持移动端使用）
    </div>
  </div>
</template>

<script>
import { compressImage } from "@/utils/compressImg.js";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
    },
  },
  name: "MyUpload",
  data () {
    return {
      fileList: [],
      attachmen_id: "",
      limit: 99,
      imageUrl: "",
      dialogVisible: false,
      dialogImageUrl: ''
    };
  },
  watch: {
    data: {
      handler (newVal) {
        if (!newVal.multiple) {
          this.limit = 1
        } else {
          this.limit = 99
        }

      },
      deep: true
    }
  },

  components: {},
  mounted () { },

  created () {
    if (this.draftData.value) {
      this.fileList = this.draftData.value;
    }
    this.value = this.data;
    console.log(this.fileList, this.data.__config__, "巨巨巨")
    this.$set(
      this.value,
      "action",
      process.env.NODE_ENV === "development"
        ? "/Api/api/upload/upload" :
        "https://xfxc.jx.119.gov.cn/api/upload/upload"
    );
  },
  methods: {
    beforeUploadFun (file) {
      const allowHook = {
        // video: ["mp4", "ogv", "ogg", "webm", "wav", "mp3", "acc", "amr"],
        // file: [
        //   "doc",
        //   "docx",
        //   "xlsx",
        //   "xls",
        //   "pdf",
        //   "txt",
        //   "zip",
        //   "pptx",
        //   "json",
        // ],
        img: ["jpg", "jpeg", "png", "gif"],
      };

      if (this.data.accept === "image") {
        if (
          allowHook.img.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传图片");
          return false;
        }
      }
      if (!this.data.__config__.fileSize) {
        return;
      }

      let fileSize;
      if (this.data.__config__.sizeUnit == "GB") {
        fileSize = file.size / 1024 / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "MB") {
        fileSize = file.size / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "KB") {
        fileSize = file.size / 1024;
      }
      let isRightSize = fileSize < this.data.__config__.fileSize * 1;
      if (!isRightSize) {
        this.$message.error(
          "文件大小超过 " +
          this.data.__config__.fileSize +
          this.data.__config__.sizeUnit
        );
        return false;
      }
    },
    exceedFun () {
      this.$message.warning("只能上传一张图片");
    },
    successFun (response, file, fileList) {
      if (response.code === -1) {
        // 上传失败，删除文件
        const index = fileList.findIndex((item) => item.uid === file.uid);
        if (index > -1) {
          fileList.splice(index, 1);
        }
        this.$message.error("文件上传失败！");
      } else {
        console.log(fileList, "fileList")
        fileList.forEach(item => {
          this.$set(item, 'url', `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
            item.response.data.attachment_id)
        })
        this.fileList = fileList;

        this.sendValue();

      }
    },
    removeFun (file, fileList) {
      this.fileList = fileList;
      this.sendValue();
    },
    previewFun (file) {
      // window.open(
      //   `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
      //   file.response.data.attachment_id
      // );
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    sendValue () {
      let obj = {
        value: this.fileList,
        index: this.index,
        name: this.data.__vModel__,
      };
      console.log(obj)
      this.$emit("tableValue", obj);
    },
  },
};
</script>

<style lang="scss"  scoped>
::v-deep .el-upload-list__item-name {
  font-size: 14px;
}

::v-deep .el-dialog {
  // all: unset;
  // margin: 0 auto !important;
  // height: 100% !important;
  // background: none !important;
  // position: static !important;
  // position: static !important;
  // bottom: unset !important;
  // margin: unset !important;
  // height: auto !important;
}

::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: normal;

  .el-upload-list__item {
    width: 80px;
    height: 80px;
    line-height: normal;
  }
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: normal;
}

::v-deep .add-form-box .el-dialog {
  margin: 10vh 40vh !important;
}

::v-deep .el-dialog__body {
  height: 100% !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 22px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
