<template>
	<div class="iframe-grid">
		<iframe :src="datas.data.src" width="100%" height="100%" frameborder="0"></iframe>
	</div>
</template>

<script>
  export default {
		props: {
		  datas: Object,
		},
    data() {
      return {
      };
    },
    components: {
    },
		watch: {
		},
		created() {
		},
		mounted() {
			
		},
    methods: {
    },
		beforeDestroy() {
		}
  };
</script>

<style lang="scss" scoped>
	.iframe-grid{
		height: 100%;
	}
</style>