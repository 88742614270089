import request from "@/utils/request";

export function getRelatedData(data) {
  return request({
    url: `/api/FormGlSearch/find?app_id=${data.app_id}&form_id=${data.form_id}&ageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data,
  });
}

// 扩展按钮回显
export function getBtnValue(form_id, fields, form_data_id, source_form_id) {
  return request({
    url: `api/form/sourceDataBack?form_id=${form_id}&fields=${fields}&form_data_id=${form_data_id}&source_form_id=${source_form_id}`,
    method: "get",
  });
}

//获取应用列表
export function getAppList(name) {
  return request({
    url: "/api/app/list",
    method: "post",
    data: {
      name,
    },
  });
}
//BI获取应用列表
export function getAppFrom(id) {
  return request({
    url: `/api/bi/getAppFrom?app_id=${id}`,
    method: "get",
  });
}
// 获取bi图表
export function getBiList(id) {
  return request({
    url: `api/bi/getAppBi?app_id=${id}`,
    method: "get",
  });
}
// 获取bi图表下的所有单个bi图表
export function getBidateilsList(id) {
  return request({
    url: `api/bi/getAppBisub?form_id=${id}`,
    method: "get",
  });
}
// 首页bi图表展示新增
export function addHomeBiform(bi_id) {
  return request({
    url: `/api/bi/setIndexConfig`,
    method: "post",
    data: {
      bi_id,
    },
  });
}
// 首页展示的bi图表
export function homeBiFormList() {
  return request({
    url: `/api/bi/getSetIndexConfig`,
    method: "get",
  });
}
// 删除首页bi图表
export function delBiForm(id) {
  return request({
    url: "/api/bi/delSetIndexConfig",
    method: "post",
    data: {
      id,
    },
  });
}

//添加应用
export function getAppAdd(data) {
  return request({
    url: "/api/app/add",
    method: "post",
    data,
  });
}
//修改应用
export function getAppEdit(data) {
  return request({
    url: "/api/app/edit",
    method: "post",
    data,
  });
}
//删除应用
export function getAppDel(id) {
  return request({
    url: "/api/app/delete",
    method: "post",
    data: {
      id,
    },
  });
}

//计数接口调用查询
export function getFormCount(id) {
  return request({
    url: `/api/form/FormCount?form_id=${id}`,
    method: "get",
  });
}

//获取应用表单列表
export function getAppFormList(id) {
  return request({
    url: `/api/form/list?app_id=${id}`,
    method: "get",
  });
}

//获取应用表单列表
export function getAllAppForm(keyword) {
  return request({
    url: `/api/instrument/getAllAppForm?keyword=${keyword}`,
    method: "get",
  });
}

//新建应用表单
export function getAppFormAdd(id, groupId, work_flows) {
  return request({
    url: `/api/form/create?app_id=${id}&group_id=${groupId}&work_flows=${work_flows}`,
    method: "get",
  });
}

//修改表单名称和图标
export function getAppFormNameIcon(id, formId, data) {
  return request({
    url: `/api/form/update?app_id=${id}&form_id=${formId}`,
    data,
    method: "post",
  });
}

//删除表单
export function getAppFormDel(id, formId) {
  return request({
    url: `/api/form/del?app_id=${id}&form_id=${formId}`,
    method: "post",
  });
}

//编辑表单内容
export function getAppFormEdit(id, formid, content) {
  return request({
    url: `/api/form/update?app_id=${id}&form_id=${formid}`,
    data: {
      content,
    },
    method: "post",
  });
}

//查看表单内容
export function getAppFormView(id, formid) {
  return request({
    url: `/api/form/edit?app_id=${id}&form_id=${formid}`,
    method: "get",
  });
}
//查看表单内容(表单发布不需要登录)
export function getAppFormViewNoLogin(id, formid) {
  return request({
    url: `/api/publicrelease/getFile?app_id=${id}&form_id=${formid}`,
    method: "get",
  });
}

//获取审核流表单
export function geteWorkFlowsForm(formid) {
  return request({
    url: `/api/workflows/geteWorkFlows?form_id=${formid}`,
    method: "get",
  });
}

//获取表单权限
export function getFieldPermission(formid) {
  return request({
    url: `/api/formpermissions/getFieldPermission?form_id=${formid}`,
    method: "get",
  });
}

//获取审核流字段
export function geteWorkFlowsFiled(formid) {
  return request({
    url: `/api/workflows/geteWorkFlowsFiled?form_id=${formid}`,
    method: "post",
  });
}

//新建表单分组
export function getAppFormGroupAdd(appId, name, groupId) {
  return request({
    url: `api/formGroup/create?app_id=${appId}&group_id=${groupId}`,
    method: "post",
    data: {
      name,
    },
  });
}
//修改分组名称
export function getAppFormGroupEdit(appId, groupId, name) {
  return request({
    url: `/api/formGroup/update?app_id=${appId}&group_id=${groupId}`,
    method: "post",
    data: {
      name,
    },
  });
}

//删除表单组
export function getAppFormGroupDel(appId, groupId) {
  return request({
    url: `/api/formGroup/del?app_id=${appId}&group_id=${groupId}`,
    method: "get",
  });
}

//表单组列表
export function getAppFromGropuTree(appId) {
  return request({
    url: `/api/formGroup/list?app_id=${appId}`,
    method: "post",
  });
}

//移动表单分组顺序
export function getAppFormGroupMove(appId, group_info) {
  return request({
    url: `/api/app/update?app_id=${appId}`,
    method: "post",
    data: {
      group_info,
    },
  });
}

//提交表达数据
export function getAppFormSubmit(data) {
  return request({
    url: `/api/formData/create`,
    method: "post",
    data,
  });
}

//提交表达数据不需要登录
export function getAppFormSubmitNoLogin(data) {
  return request({
    url: `/api/publicrelease/addFormInfo`,
    method: "post",
    data,
  });
}

//修改表达数据
export function getAppFormUpdatet(data) {
  return request({
    url: `/api/formData/update`,
    method: "post",
    data,
  });
}

//流程表达数据
export function getAppFlowsUpdate(data) {
  return request({
    url: `/api/formData/workFlowsUpdate`,
    method: "post",
    data,
  });
}

//提交流程数据
export function getAppFlowsSubmit(data) {
  return request({
    url: `/api/WorkFlowsFormData/create`,
    method: "post",
    data,
  });
}

//查询表单数据
export function getAppFormData(data) {
  return request({
    url: `/api/formData/find?app_id=${data.app_id}&form_id=${data.form_id}&order=${data.order}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&user_name=${data.user_name}&is_look=${data.is_look}`,
    method: "post",
    data,
  });
}
export function getAppFormDataDel(data) {
  return request({
    url: "/api/formData/del",
    method: "post",
    data: data,
  });
}

//获取详情
export function getAppFormDataDetail(data) {
  return request({
    url: "/api/formData/detail",
    method: "post",
    data: data,
  });
}
//首页待办处理发起数量
export function getHomeWorkFlowsDataCount() {
  return request({
    url: "/api/userFormData/getWorkFlowsDataCount",
    method: "get",
  });
}
//首页待办数据列表/api/userFormData/handleList

export function getHomePendingList(data) {
  return request({
    url: "/api/userFormData/pendingList",
    method: "post",
    data: data,
  });
}

//首页办理数据列表

export function getHomeHandleList(data) {
  return request({
    url: "/api/userFormData/handleList",
    method: "post",
    data: data,
  });
}

//首页发起数据列表

export function getHomeInitiateList(data) {
  return request({
    url: "/api/userFormData/initiateList",
    method: "post",
    data: data,
  });
}
//抄送数据列表
export function getMakeCopyList(data) {
  return request({
    url: "/api/copy/list",
    method: "post",
    data: data,
  });
}

//待办数据列表
export function geteWorkFlowsBacklog(data) {
  return request({
    url: "/api/workFlowsFormData/find",
    method: "post",
    data: data,
  });
}

//我发起数据列表
export function geteWorkFlowsBacklogMyfind(data) {
  return request({
    url: "/api/workFlowsFormData/myfind",
    method: "post",
    data: data,
  });
}
//查看流程数据记录
export function geteWorkFlowsRecord(data) {
  return request({
    url: `/api/workFlowsFormData/record?step_id=${data.step_id}&form_id=${data.form_id}&form_data_id=${data.form_data_id}&revocatio_type=${data.revocatio_type}`,
    method: "get",
  });
}

//查看节点详情
export function geteWorkFlowsDetail(data) {
  return request({
    url: `/api/workFlowsFormData/detail?step_id=${data.step_id}&form_id=${data.form_id}&_id=${data._id}`,
    method: "get",
  });
}

//流程审批
export function geteWorkFlowsCheck(data) {
  return request({
    url: "/api/workFlowsFormData/check",
    method: "post",
    data: data,
  });
}

//流程驳回
export function postOverruled(data) {
  return request({
    url: "/api/workflowsoperation/overruled",
    method: "post",
    data: data,
  });
}

//流程回退
export function postRollback(data) {
  return request({
    url: "/api/workflowsoperation/rollback",
    method: "post",
    data: data,
  });
}

//流程转交
export function postBackoff(data) {
  return request({
    url: "/api/workflowsoperation/backoff",
    method: "post",
    data: data,
  });
}

//流程加签
export function postAddsign(data) {
  return request({
    url: "/api/workflowsoperation/addsign",
    method: "post",
    data: data,
  });
}

//流程驳回后二次提交
export function postRestartadd(data) {
  return request({
    url: "/api/workflowsoperation/restartadd",
    method: "post",
    data: data,
  });
}

//获取联动表单数据
export function getAppLinkFormData(id) {
  return request({
    url: `/api/form/getFormList?app_id=${id}`,
    method: "get",
  });
}

//获取联动表单字段
export function getAppLinkFormFieldsData(appId, formId, current) {
  return request({
    url: `/api/form/getFormFields?app_id=${appId}&form_id=${formId}&current=${current}`,
    method: "get",
  });
}

//保存表单草稿
export function getAppFormDraft(data) {
  return request({
    url: "/api/draft/create",
    method: "post",
    data,
  });
}
//获取草稿内容
export function getAppFormDraftContent(appId, formId) {
  return request({
    url: `/api/draft/list?app_id=${appId}&form_id=${formId}`,
    method: "get",
  });
}

//数据导出
export function getAppFormExport(appId, formId) {
  return request({
    url: `/api/export/export?form_id=${formId}&app_id=${appId}`,
    method: "get",
    timeout: 100000000,
    responseType: "blob",
  });
}

// 查询选择筛选条件之后拿到字段
export function getSelectFields(data) {
  return request({
    url: `/api/FormGlSearch/distinct`,
    method: "post",
    data,
  });
}

//查询所有成员
export function getAppAllMemberList(type) {
  return request({
    url: `/api/user/UserAll?type=${type}`,
    method: "get",
  });
}

//查询所有部门
export function getAppAllDepartList() {
  return request({
    url: "/api/user/departmentAll",
    method: "get",
  });
}

//数据联动
export function getAppFormLinkData(data) {
  return request({
    url: "api/formData/link",
    method: "post",
    data,
  });
}
// 表单发布开关
export function getAppSetFormUrl(data) {
  return request({
    url: "api/publicrelease/setFormUrl",
    method: "post",
    data,
  });
}
// 表单发布数据获取
export function getAppGetUrlStatus(data) {
  return request({
    url: `api/publicrelease/getUrlStatus?form_id=${data.form_id}&app_id=${data.app_id}`,
    method: "get",
  });
}
// 表单发布二维码
export function getQrcode(data) {
  return request({
    url: `/api/qrcode/create`,
    method: "post",
    data,
    responseType: "blob",
  });
}
//获取流水号数据
export function getAppGetSerialNumber(data) {
  return request({
    url: `/api/form/get_sn_vw?form_id=${data.formId}&app_id=${data.appId}`,
    method: "get",
  });
}

//重置流水号
export function getAppResetSerialNumber(data) {
  return request({
    url: `/api/form/reset_sn_vw?form_id=${data.formId}&app_id=${data.appId}`,
    method: "get",
  });
}

//仪表盘获取应用表单
export function getAppGetAppForm(appId) {
  return request({
    url: `/api/form/getFormList?app_id=${appId}`,
    method: "get",
  });
}

// 获取应用回收站列表
export function getDelList() {
  return request({
    url: `/api/app/delList`,
    method: "get",
  });
}
// 删除应用回收站
export function getDelYY(data) {
  return request({
    url: `/api/app/delRecycle`,
    method: "post",
    data,
  });
}
// 恢复应用回收站
export function getRecoveryDel(data) {
  return request({
    url: `/api/app/recoveryDel`,
    method: "post",
    data,
  });
}

// 获取表单回收站列表
export function getDelListFrom(ids) {
  return request({
    url: `/api/form/delList?app_id=${ids}`,
    method: "get",
  });
}
// 删除表单回收站
export function getDelFrom(data) {
  return request({
    url: `/api/form/delRecycle`,
    method: "post",
    data,
  });
}
// 恢复表单回收站
export function getRecoveryDelFrom(data) {
  return request({
    url: `/api/form/recoveryDel`,
    method: "post",
    data,
  });
}
// 消息列表
export function getMessageList(type) {
  return request({
    url: `/api/msg/list?is_read=${type}`,
    method: "get",
  });
}
//消息已读
export function getSetRead(id) {
  return request({
    url: `/api/msg/setRead?id=${id}`,
    method: "get",
  });
}
//获取单个字段的提交数据
export function getSingleFieldData(appId, formId, field, data_source_id) {
  return request({
    url: `/api/instrument/getFiledList?app_id=${appId}&form_id=${formId}&field=${field}&data_source_id=${data_source_id}`,
    method: "get",
  });
}

// 获取单个字段的提交数据（带分页，模糊查询
export function getFiledListPaging(
  appId,
  formId,
  field,
  data_source_id,
  limit,
  page,
  name
) {
  return request({
    url: name
      ? `/api/instrument/getFiledListPaging?app_id=${appId}&form_id=${formId}&field=${field}&data_source_id=${data_source_id}&page=${
          page || 1
        }&limit=${limit || 10}&search=${name || ""}`
      : `/api/instrument/getFiledListPaging?app_id=${appId}&form_id=${formId}&field=${field}&data_source_id=${data_source_id}&page=${
          page || 1
        }&limit=${limit || 10}`,
    method: "get",
  });
}
// 仪表盘数据保存
export function postWmtestYBP(data) {
  return request({
    url: `/api/bi/update?app_id=${data.app_id}&form_id=${data.form_id}`,
    method: "post",
    data,
  });
}

//仪表盘数据获取
export function getWmYBP(appId, formId) {
  return request({
    url: `/api/bi/getBi?app_id=${appId}&form_id=${formId}`,
    method: "get",
  });
}

// 仪表盘图表保存
export function postCreateSubYBP(appId, formId, checkId, data) {
  return request({
    url: `/api/bi/createSub?app_id=${appId}&form_id=${formId}`,
    method: "post",
    data: {
      data_source_id: checkId,
      content: data,
    },
  });
}

//复制表单
export function postCopyForm(data) {
  return request({
    url: `api/form/copyForm`,
    data,
    method: "post",
    // 获取草稿列表
  });
}
//复制BI图表
export function postCopyBI(data) {
  return request({
    url: `api/form/copyBi`,
    data,
    method: "post",
  });
}
export function getDraftList(appId, formId) {
  return request({
    url: `/api/draft/list?app_id=${appId}&form_id=${formId}`,
    method: "get",
  });
}
// 删除草稿
export function delDraf(id) {
  return request({
    // url: `/api/draft/del?id=${id}`,
    url: `/api/draft/del`,
    method: "post",
    data: {
      id,
    },
  });
}
// 草稿箱修改
export function editDraf(data) {
  return request({
    // url: `/api/draft/del?id=${id}`,
    url: `/api/draft/update`,
    method: "post",
    data,
  });
}

// 表单撤回
// export function getWithdraw(form_id, form_data_id, opinion) {
//   return request({
//     url: `/api/workflowsoperation/revocation?form_id=${form_id}&form_data_id=${form_data_id}&opinion=${opinion}`,
//     method: "post",
//   });
// }
export function getWithdraw(data) {
  return request({
    url: `/api/workflowsoperation/revocation`,
    method: "post",
    data,
  });
}
// 数据管理排序接口
export function getsortTable(data) {
  return request({
    url: `/api/instrument/getDataCalculationList`,
    method: "post",
    data,
  });
}
// 分组修改
export function updateAppList(data) {
  return request({
    url: `/api/app/updateAppGroup`,
    method: "post",
    data,
  });
}

//获取子表单权限
export function getTableRule(form_id, setup_id) {
  return request({
    url: `/api/form/getTableRows?form_id=${form_id}&step_id=${setup_id}`,
    method: "get",
  });
}
// 获取最近打开的表单
export function getRecent() {
  return request({
    url: "/api/form/getRecent",
    method: "get",
  });
}

// 获取发起流程表单
export function getAllForm() {
  return request({
    url: "/api/form/getAllForm",
    method: "get",
  });
}
// 不用登录展示的字段
export function getFileApi(data) {
  return request({
    url: `/api/publicrelease/getFile?form_id=${data.formId}`,
    method: "get",
  });
}

// 不用登录的接口
export function addFormInfoApi(data) {
  return request({
    url: "/api/publicrelease/addFormInfo",
    method: "post",
    data,
  });
}
export function getFieldData(formId, field) {
  return request({
    url: `/api/instrument/getFiledList?form_id=${formId}&field=${field}`,
    method: "get",
  });
}

//获取跨应用表单
export function getCrossAppForm(appId) {
  return request({
    url: `/api/companyConfig/getForm?app_id=${appId}`,
    method: "get",
  });
}

//获取数据加载表单
export function getDataLoadForm(data) {
  return request({
    url: `api/formData/dataLoadingList?field=${data.field}&form_id=${data.form_id}&page=${data.page}&limit=${data.limit}`,
    method: "get",
  });
}

//根据部门ID查找部门名称
export function getDepartMentName(department_ids) {
  return request({
    url: `/api/user/departmentName`,
    method: "post",
    data: {
      department_ids,
    },
  });
}

//根据成员ID查找成员名称
export function getMemberName(user_ids) {
  return request({
    url: `/api/user/userName`,
    method: "post",
    data: {
      user_ids,
    },
  });
}

//根据部门ID查找部门对象
export function getDepartMentObj(department_ids) {
  return request({
    url: `/api/user/departmentIdName`,
    method: "post",
    data: {
      department_ids,
    },
  });
}

//根据成员ID查找成员对象
export function getMemberObj(user_ids) {
  return request({
    url: `/api/user/userIdName`,
    method: "post",
    data: {
      user_ids,
    },
  });
}

//复制后台应用管理权限列表
export function getCopyAppAuthList(id) {
  return request({
    url: `/api/formpermissions/FormPermissionCopy`,
    method: "post",
    data: {
      id,
    },
  });
}

export function getexport(query, data) {
  return request({
    url: `/api/export/export?form_id=${query.formId}&app_id=${query.app_id}&id=${query.id}&fileds=${query.fileds}&is_look=${query.is_look}`,
    method: "post",
    timeout: 100000000,
    data,
  });
}

// 批量修改预览
export function updateManyPrev(query, data) {
  return request({
    url: `api/formData/updateManyPrev?app_id=${query.app_id}&form_id=${query.form_id}&is_look=${query.is_look}`,
    method: "post",
    data,
  });
}

// 批量修改提交
export function updateMany(query, data) {
  return request({
    url: `api/formData/updateMany?app_id=${query.app_id}&form_id=${query.form_id}&is_look=${query.is_look}`,
    method: "post",
    data,
  });
}

// 数据回收站查询
export function dataBackup(data) {
  return request({
    url: `/api/formData/dataBackup?app_id=${data.app_id}&form_id=${data.form_id}&order=${data.order}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&user_name=${data.user_name}&is_look=${data.is_look}`,
    method: "post",
    data,
  });
}

// 数据回收站恢复
export function returnTo(data) {
  return request({
    url: `/api/formData/returnTo?app_id=${data.app_id}&form_id=${data.form_id}`,
    method: "post",
    data,
  });
}

// 数据回收站彻底删除
export function realDel(data) {
  return request({
    url: `/api/formData/realDel?app_id=${data.app_id}&form_id=${data.form_id}`,
    method: "post",
    data,
  });
}
// 导出列表
export function getExportList(data) {
  return request({
    url: `/api/export/exporttaskList?app_id=${data.app_id}&form_id=${data.form_id}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
    method: "post",
    data,
  });
}
// 删除
export function delExport(data) {
  return request({
    url: `/api/export/exporttaskDel?app_id=${data.app_id}&form_id=${data.form_id}&id=${data.id}`,
    method: "post",
    data,
  });
}
