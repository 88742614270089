<template>
  <div class="app-warp">
    <Header></Header>
    <app-main></app-main>
  </div>
</template>

<script>
import { AppMain, Header } from "./components";

export default {
  data() {
    return {};
  },
  components: {
    AppMain,
    Header,
  },
  methods: {},
  created() {},
};
</script>
<style>
.app-warp {
  width: 100%;
  height: 100%;
  background: #f5f8fc;
}
</style>
