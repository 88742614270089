<!--  -->
<template>
  <div v-if="dialogVisible">
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="60%" append-to-body
      :modal-append-to-body="false" class="menber-select-dialog" :before-close="handleSelectClose">
      <div class="content">
        <div class="menber-ul">
          <div class="menber-li" v-for="(item, index) in selectList" :key="index">
            <div class="li-info">
              <i class="el-icon-user-solid flow-node-drag"></i>

              <div style="margin: 0 5px">
                {{ item.label }}
              </div>
              <i class="iconfont icon-shanchu" style="color: red" @click="delSelect(item, index)"></i>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" style="margin-bottom: -20px">
          <el-tab-pane label="组织架构" name="1"></el-tab-pane>
          <el-tab-pane label="动态参数" name="2" v-if="activeData.modelType === 'all' ||
            (activeData.modelType === 'custom' && activeData.trendsChecked)
            "></el-tab-pane>
        </el-tabs>
        <el-input placeholder="请输入内容" style="position: relative; top: -30px; width: 60%; left: 40%"
          prefix-icon="el-icon-search" @change="inputDM(1)" @input="inputDM(2)" v-model="inputDepartment">
        </el-input>
        <div class="select-menber-ul" v-show="activeName === '1'">
          <div class="menber-warp">
            <div class="menber-list menber-tree">
              <div class="menber-flex">
                <el-tree :data="data" style="flex: 1" node-key="id" @node-click="nodeClick" highlight-current
                  :default-expand-all="false" ref="tree" :expand-on-click-node="false" accordion>
                  <span slot-scope="{ node, data }">
                    <i class="iconfont icon-zuzhibumen" style="color: #409dff"></i>
                    <span style="margin-left: 10px; font-size: 12px">{{
                      node.label
                    }}</span>
                  </span>
                </el-tree>
                <div class="flex-menber-warp">
                  <div class="menber-title" v-if="activeData.multiple">
                    <div>已选择:{{ selectList.length }}人</div>
                    <div>
                      全部
                      <el-checkbox v-model="checked" style="margin-left: 3px"></el-checkbox>
                    </div>
                  </div>

                  <div class="menber-list">
                    <el-checkbox-group v-model="checkedList" style="height: 100%" v-if="menberList.length > 0">
                      <div class="menber-list-li" v-for="(item, index) in menberList" :key="index"
                        @click="toggleCheckbox(item)">
                        <div class="li-info">
                          <div class="li-info-atvar">
                            {{ item.label ? item.label[0] : '' }}
                          </div>
                          <div>{{ item.label }}</div>
                        </div>
                        <div class="li-check" @click.stop>
                          <el-checkbox :label="item.id" :value="item.id" @change="checkMenberChange(item)"></el-checkbox>
                        </div>
                      </div>
                    </el-checkbox-group>
                    <div v-else>
                      <el-empty :image-size="100"></el-empty>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="select-menber-ul" v-show="activeName === '2'">
          <div class="menber-warp">
            <div class="menber-trends">
              <div class="menber-trends-left">
                <i class="iconfont icon-zuzhibumen" style="color: #409dff"></i>
                <div>{{ $store.state.user.PermissionsList.name }}</div>
              </div>
              <el-checkbox v-model="showTrends"></el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSelectClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDepartMent, getDepartMember, getUserNameSearch } from '@/api/user'
import { getMemberObj } from '@/api/app'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      data: [],
      inputDepartment: '',
      selectList: [],
      checked: false,
      checkedList: [],
      activeName: '1',
      trendsChecked: false,
      showTrends: false,
      menberList: [],
      historyList: [],
      nodeId: null,

      nowMenberList: [],
      isListClick: false
    }
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: '',
      type: String
    },
    echoData: '',
    selectRange: {
      default: () => {
        return []
      },
      type: Array
    },
    activeData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },

  watch: {
    checked (val) {
      this.setAllCheck(val)
    },
    dialogVisible (val) {
      this.activeName = '1'
      if (val) {
        this.getList()
      }
    },
    checkedList (val) {
      if (val.length === this.menberList.length && val.length !== 0) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    historyList (val) {
      if (val.length > 0) {
        getMemberObj(val).then((res) => {
          this.selectList.splice(0, this.selectList.length)
          this.selectList.push(...res.data)
        })
      }

      let i = val.indexOf(this.$store.state.user.PermissionsList._id)
      if (i !== -1) {
        this.showTrends = true
      }
    },
    selectList (val) {
      if (val.length === 0) {
        this.showTrends = false
      }
    },
    showTrends (val) {
      if (val) {
        this.historyList.push(this.$store.state.user.PermissionsList._id)
        let i = this.checkedList.indexOf(this.$store.state.user.PermissionsList._id)
        if (i === -1) {
          this.checkedList.push(this.$store.state.user.PermissionsList._id)
        }
      } else {
        let i = this.historyList.indexOf(this.$store.state.user.PermissionsList._id)
        if (i !== -1) {
          this.historyList.splice(i, 1)
        }
        let i2 = this.checkedList.indexOf(
          this.$store.state.user.PermissionsList._id
        )
        if (i !== -1) {
          this.checkedList.splice(i, 1)
        }
      }
      if (!this.activeData.multiple) {
        if (val) {
          this.historyList.splice(0, this.historyList.length)
          this.checkedList.splice(0, this.checkedList.length)
          this.checkedList.push(this.$store.state.user.PermissionsList._id)
          this.historyList.push(this.$store.state.user.PermissionsList._id)
        }
      }
      this.historyList = Array.from(new Set(this.historyList))
    }
  },
  methods: {
    toggleCheckbox (item) {
      const index = this.checkedList.indexOf(item.id);
      if (this.activeData.__config__.tagIcon === "menberCheck") {
        if (index === -1) {
          this.checkedList.push(item.id); // 如果未选中，则选中
        } else {
          this.checkedList.splice(index, 1); // 如果已选中，则取消选中
        }
        this.checkMenberChange(item);
      } else {
        if (index === -1) {
          this.checkedList = []
          this.checkedList.push(item.id); // 如果未选中，则选中
        } else {
          this.checkedList.splice(index, 1); // 如果已选中，则取消选中
        }
        this.checkMenberChange(item);
      }

    },

    inputDM (index) {
      if (index === 2) {
        this.menberList = []
      }
      this.checkedList.splice(0, this.checkedList.length)
      getUserNameSearch({ name: this.inputDepartment }).then((res) => {
        console.log(res.data)
        let arr = this.treeDataChange(res.data)

        arr.forEach((item) => {
          if (item.status !== 2) {
            this.menberList.push(item)
          }
        })
        this.historyList.forEach((item) => {
          this.menberList.forEach((itemA) => {
            if (itemA.id === item) {
              this.checkedList.push(item)
            }
          })
        })
        this.menberList = res.data
        if (this.checkedList.length === this.menberList.length) {
          this.checked = true
        } else {
          this.checked = false
        }
      })
    },
    setAllCheck (val) {
      if (val) {
        this.menberList.forEach((item) => {
          let i = this.checkedList.indexOf(item.id)
          if (i === -1) {
            this.checkedList.push(item.id)
          }
          let i2 = this.historyList.indexOf(item.id)
          if (i2 === -1) {
            this.historyList.push(item.id)
          }
        })
      } else {
        if (this.checkedList.length === this.menberList.length) {
          this.menberList.forEach((item) => {
            let i = this.checkedList.indexOf(item.id)
            if (i !== -1) {
              this.checkedList.splice(i, 1)
            }
            let i2 = this.historyList.indexOf(item.id)
            if (i2 !== -1) {
              this.historyList.splice(i2, 1)
            }
          })
        }
      }
    },

    nodeClick (data, node, el) {
      if (this.nodeId === data.id) {
        return
      }

      this.nodeId = data.id

      this.checkedList.splice(0, this.checkedList.length)
      this.checked = false
      let query = {
        status: '',
        limit: 999,
        page: 1,
        name: '',
        id: data.id
      }

      getDepartMember(query).then((res) => {
        let arr = this.treeDataChange(res.data)
        this.menberList = []
        arr.forEach((item) => {
          if (item.status !== 2) {
            this.menberList.push(item)
          }
        })

        this.historyList.forEach((item) => {
          this.menberList.forEach((itemA) => {
            if (item === itemA.id) {
              this.checkedList.push(item)
            }
          })
        })

        if (this.checkedList.length === this.menberList.length) {
          this.checked = true
        } else {
          this.checked = false
        }
      })
    },
    checkMenberChange (item) {
      let checked = this.checkedList.indexOf(item.id) !== -1 ? true : false
      if (this.activeData.multiple) {
        if (checked) {
          this.historyList.push(item.id)
          if (item.id === this.$store.state.user.PermissionsList._id) {
            this.showTrends = true
          }
        } else {

          this.selectList.forEach((obj, index) => {
            if (item.id === obj.id) {
              this.selectList.splice(index, 1)
            }
          })
          let i = this.historyList.indexOf(item.id)
          if (i !== -1) {
            this.historyList.splice(i, 1)
          }
          if (item.id === this.$store.state.user.PermissionsList._id) {
            this.showTrends = false
          }
        }
        console.log(this.historyList)
      } else {
        if (checked) {
          this.historyList.splice(0, this.historyList.length)
          this.checkedList.splice(0, this.checkedList.length)
          this.historyList.push(item.id)
          this.checkedList.push(item.id)
          if (this.showTrends) {
            this.showTrends = false
          }
        } else {
          this.selectList.splice(0, 1)
          this.historyList.splice(0, 1)
          this.checkedList.splice(0, 1)
        }
      }
    },
    getList () {
      this.checkedList = []
      this.historyList = []
      this.checked = false
      this.nodeId = null
      this.menberList = []
      this.nowMenberList = []
      getDepartMent({ type: 'all' }).then((res) => {
        if (this.activeData.modelType === 'custom') {
          this.data = this.selectRange
          this.data.forEach((item) => {
            if (item.children) {
              delete item.children
            }
          })
        } else if (this.activeData.modelType === 'inDepart') {
          this.data = JSON.parse(JSON.stringify(this.activeData.departList))
        } else {
          this.data = this.treeDataChange(res.data)
        }

        this.showTrends = this.activeData.showTrends
        if (
          (this.activeData.modelType === 'custom' &&
            this.activeData.trendsChecked) ||
          this.activeData.modelType === 'all'
        ) {
          this.nowMenberList.push({
            id: this.$store.state.user.PermissionsList._id,
            label: this.$store.state.user.PermissionsList.name
          })

          this.showTrends = this.activeData.showTrends
        }
        if (
          this.activeData.__config__ &&
          this.activeData.__config__.defaultValue &&
          this.activeData.__config__.defaultValue.length > 0
        ) {
          this.activeData.__config__.defaultValue.forEach((item) => {
            this.checkedList.push(item.id)
            this.historyList.push(item.id)
          })
        }
      })
    },

    handleSelectClose () {
      this.$emit('handleSelectClose')
    },

    delSelect (item, index) {
      if (item !== 'now') {
        let i = this.checkedList.indexOf(item.id)
        if (i !== -1) {
          this.checkedList.splice(i, 1)
        }
        let historyIndex = this.historyList.indexOf(item.id)
        if (historyIndex !== -1) {
          this.historyList.splice(historyIndex, 1)
        }
        this.selectList.splice(index, 1)
      } else {
        this.showTrends = false
      }
    },

    submit () {
      this.$emit(
        'submit',
        JSON.parse(JSON.stringify(this.selectList)),
        this.showTrends
      )
    },
    treeDataChange (data) {
      data.forEach((item) => {
        this.$set(item, 'id', item._id)
        this.$set(item, 'label', item.name)
        delete item._id
        delete item.name
        if (item.children) {
          this.treeDataChange(item.children)
        }
      })
      return data
    }
  },
  mounted () {
    this.getList()
  },
  created () { }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: block !important;
}

::v-deep .el-checkbox__label {
  display: none;
}

::v-deep .el-tabs__item {
  font-size: 12px;
  padding: 0 10px;
}

::v-deep .el-tabs__nav-scroll {
  justify-content: unset !important;
  align-items: unset !important;
}

::v-deep .is-disabled {
  display: none !important;
}

::v-deep .el-tree>.el-tree-node {
  border-bottom: unset !important;
  padding-left: unset !important;
  padding-right: 14px;
  box-sizing: border-box;
  position: relative;
}

::v-deep .el-tree-node__content {
  position: relative;
}

.menber-tree {
  ::v-deep .el-checkbox {
    position: absolute;
    right: 0;
    top: 9px;
  }
}

.menber-flex {
  display: flex;
  height: 100%;

  ::v-deep .el-tree {
    overflow-y: auto;


    &::-webkit-scrollbar {
      display: none;
    }
  }


}

.flex-menber-warp {
  flex: 1;
  border-left: 1px solid #cecece;

  .menber-title {
    padding: 0 25px 0 24px;
    box-sizing: border-box;
    display: flex !important;
    align-items: center;
    line-height: 32px;
    justify-content: space-between;

    ::v-deep .el-checkbox {
      position: unset !important;
      right: unset !important;
      top: unset !important;
    }

    &>div:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #303133;
    }

    &>div:nth-child(2) {
      color: #409eff;
    }
  }
}

.menber-trends {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  padding: 0 14px 0 10px;
  font-size: 12px;

  .menber-trends-left {
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
      color: #409dff;
    }
  }
}

.all-checkd {
  ::v-deep .el-checkbox {
    position: unset !important;
    right: unset !important;
    top: unset !important;
  }
}

.iconfont {
  font-size: 12px !important;
}

.icon-warp {
  width: 32px;
  height: 32px;
  background: #409dff;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
  line-height: 32px;

  i {
    color: #fff !important;
    margin: unset !important;
  }
}

.title {
  font-size: 14px;
  margin: 20px 0 8px 0;
  font-weight: bold;
  color: #303133;
}

.select-menber-ul {
  height: 280px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  display: flex;
  align-items: center;

  .menber-warp {
    flex: 1;
    height: 100%;

    .menber-list {
      overflow-y: scroll;
      height: 100%;

      .menber-list-li:hover {
        background: #f5f8fc;
      }

      .menber-list-li {
        font-size: 12px;
        padding: 10px 24px;
        box-sizing: border-box;
        font-weight: 400;
        color: #303133;
        display: flex;
        cursor: pointer;

        align-items: center;
        justify-content: space-between;

        .li-info {
          display: flex;
          align-items: center;

          i {
            color: #409dff;
            margin-right: 10px;
          }

          .li-info-atvar {
            margin-right: 10px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            background: #409eff;
            border-radius: 50%;
          }
        }

        .li-check {
          ::v-deep .el-checkbox {
            position: unset !important;
          }
        }
      }
    }
  }
}

.menber-ul {
  margin-top: 10px;
  display: flex;
  border: 1px solid #e4e7ed;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;

  min-height: 120px;

  .li-info {
    display: flex;
    align-items: center;
  }

  .menber-li {
    font-size: 12px;
    font-weight: 400;
    color: #303133;
    background: #f0f1f4;
    border-radius: 1px;
    box-sizing: border-box;
    padding: 5px 10px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    display: flex;
    margin: 5px 10px 0 0;

    i {
      color: #409eff;
      font-size: 16px;
    }
  }
}
</style>
