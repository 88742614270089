<template>
  <div class="content">
    <!-- <div class="screenBox1">
      筛选出符合以下
      <el-select v-model="screenValue" placeholder="请选择">
        <el-option label="所有" value="and"> </el-option>
        <el-option label="任一" value="or"> </el-option> </el-select
      >条件的数据
    </div> -->
    <el-popover
      placement="bottom"
      width="150"
      trigger="click"
      ref="childPopover"
      v-model="fileShow"
    >
      <div>
        <el-checkbox-group v-model="filedCheckList" @change="filedChange">
          <el-checkbox
            :label="item.__vModel__"
            v-for="(item, index) in screenFileds"
            :key="index"
            :disabled="modelList.indexOf(item.__vModel__) > -1"
            >{{ item.__config__.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="add_sort" slot="reference">
        <i class="el-icon-plus" style="color: #409eff"></i> 添加筛选条件
      </div>
    </el-popover>

    <div class="screenBox2" v-for="(item, index) in screenList" :key="index">
      <el-select
        style="width: 160px"
        v-model="item.__vModel__"
        @change="changeFileds(item.__vModel__, index)"
      >
        <el-option
          v-for="(item1, index1) in item.condition.filedsList"
          :key="index1"
          :label="item1.__config__.label"
          :value="item1.__vModel__"
          :disabled="modelList.indexOf(item1.__vModel__) > -1"
        >
        </el-option>
      </el-select>
      <div class="selectNoneBorder">
        <el-select
          style="width: 140px; margin-left: 10px"
          v-model="item.condition.condition"
          @change="handleConditionChange(item)"
        >
          <el-option
            v-for="item2 in item.condition.conditionOption"
            :key="item2.value"
            :label="item2.label"
            :value="item2.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        v-show="
          item.condition.condition !== 'emp' &&
          item.condition.condition !== 'nemp'
        "
      >
        <div v-if="item.condition.tagType === '1'">
          <el-select
            v-if="
              item.condition.condition === 'or' ||
              item.condition.condition === 'nor'
            "
            multiple
            ref="multipleSelect"
            v-model="item.condition.value"
            style="width: 200px"
          >
            <el-option
              v-for="itemA in item.condition.dataList"
              :key="itemA.value"
              :label="itemA.value"
              :value="itemA.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="item.condition.value"
            v-else-if="
              item.condition.condition === 'con' ||
              item.condition.condition === 'ncon'
            "
          ></el-input>
          <el-select v-else v-model="item.condition.value" style="width: 200px">
            <el-option
              v-for="itemA in item.condition.dataList"
              :key="itemA.value"
              :label="itemA.label"
              :value="itemA.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          v-model="item.condition.value"
          v-else-if="item.condition.tagType === '2'"
          :type="item.__config__.tag === 'inputNumber' ? 'number' : 'text'"
        ></el-input>
        <div v-else-if="item.condition.tagType === '4'" style="width: 200px">
          <div v-if="item.condition.condition !== 'range'">
            <el-date-picker
              v-if="item.format == 'yyyy-MM-dd HH:mm:ss'"
              style="width: 200px"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="item.condition.value"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.format == 'yyyy-MM-dd HH:mm'"
              style="width: 200px"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              v-model="item.condition.value"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.format == 'yyyy-MM-dd'"
              style="width: 200px"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="item.condition.value"
              type="date"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <el-date-picker
              v-if="item.format == 'yyyy-MM'"
              style="width: 200px"
              format="yyyy-MM"
              value-format="yyyy-MM"
              v-model="item.condition.value"
              type="month"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <el-date-picker
            v-else
            style="width: 200px"
            v-model="item.condition.value"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div style="width: 200px" v-else-if="item.condition.tagType === '5'">
          <div v-if="item.__config__.tag === 'menberCheck'">
            <el-select
              v-model="item.condition.value"
              style="width: 200px"
              ref="multipleSelect"
              :multiple="true"
            >
              <el-option
                v-for="itemA in allMenberList"
                :key="itemA._id"
                :label="itemA.name"
                :value="itemA._id"
              >
              </el-option>
            </el-select>
          </div>
          <treeselect
            v-model="item.condition.value"
            :options="item.condition.dataList"
            :multiple="true"
            v-else-if="item.__config__.tag === 'departCheck'"
            no-options-text="暂无数据"
            no-children-text="暂无数据"
            placeholder="请选择部门"
            ref="treeselect"
            :normalizer="normalizer"
          >
          </treeselect>
          <el-select
            v-model="item.condition.value"
            style="width: 200px"
            ref="multipleSelect"
            multiple
            v-else
          >
            <el-option
              v-for="itemA in item.condition.dataList"
              :key="itemA.value"
              :label="itemA.label ? itemA.label : itemA.value"
              :value="itemA.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="width: 200px" v-else-if="item.condition.tagType === '6'">
          <el-select
            v-model="item.condition.value"
            style="width: 200px"
            :multiple="
              item.condition.condition !== 'eq' &&
              item.condition.condition !== 'neq'
            "
            ref="multipleSelect"
            v-if="item.__config__.tag === 'menberSingle'"
          >
            <el-option
              v-for="itemA in allMenberList"
              :key="itemA._id"
              :label="itemA.name"
              :value="itemA._id"
            >
            </el-option>
          </el-select>
          <treeselect
            v-model="item.condition.value"
            :options="item.condition.dataList"
            :multiple="
              item.condition.condition !== 'eq' &&
              item.condition.condition !== 'neq'
            "
            ref="treeselect"
            v-else-if="item.__config__.tag === 'departSingle'"
            no-options-text="暂无数据"
            no-children-text="暂无数据"
            placeholder="请选择部门"
            :normalizer="normalizer"
          ></treeselect>
          <el-select
            v-model="item.condition.value"
            style="width: 200px"
            ref="multipleSelect"
            :multiple="
              item.condition.condition !== 'eq' &&
              item.condition.condition !== 'neq'
            "
            v-else-if="item.__config__.tag === 'user_id'"
          >
            <el-option
              v-for="itemA in item.condition.dataList"
              :key="itemA._id"
              :label="itemA.name"
              :value="itemA._id"
            >
            </el-option>
          </el-select>
        </div>

        <!-- <el-select v-model="item.value" style="width: 200px; margin-left: 10px ">
          <el-option v-for="item3 in item.conditionOption" :key="item3.value" :label="item3.label" :value="item3.value">
          </el-option>
        </el-select> -->
      </div>

      <i
        class="iconfont icon-shanchu"
        style="margin-left: 10px"
        @click="delScreen(index)"
      ></i>
    </div>
    <div class="line"></div>
    <div>
      <el-button type="primary" @click="screenSubmit">筛选</el-button>
      <el-button @click="clearFun">清空</el-button>
    </div>
  </div>
</template>
<script>
import { getSingleFieldData, getsortTable, getSelectFields } from "@/api/app";
import { getDepartMent } from "@/api/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";
export default {
  name: "screenIndex",
  props: ["screenFileds", "formId", "screenShow", "appId"],
  components: { Treeselect },
  data() {
    return {
      modelList: [],
      filedCheckList: [],
      option: "or",
      tagIcon: "",
      filedsList: [],
      conditionOption: [],
      valueList: [],
      screenList: [],
      pageNum: 1,
      pageSize: 10,
      // 保存筛选之后的值
      copyScreenList: [],
      fileShow: false,
    };
  },
  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
  watch: {
    filedCheckList: {
      handler(val) {
        // console.log(val)
      },
    },
    screenShow: {
      handler(val) {
        if (val == true && this.copyScreenList.length > 0) {
          this.screenList = JSON.parse(JSON.stringify(this.copyScreenList));
          console.log(this.screenList, "this.screenList");

          this.filedCheckList = this.filedCheckList.filter((item2) => {
            let found = this.screenList.some(
              (item) => item.__vModel__ === item2
            );
            return found;
          });
          this.modelList = this.filedCheckList;
        }
      },
    },
    screenList: {
      handler(val) {},
    },
    appId: {
      handler(val) {
        consoel.log(val, "screenindex里面的appId");
      },
    },
  },
  methods: {
    filedChange(val) {
      val.forEach((item) => {
        this.screenFileds.forEach((item2) => {
          if (item === item2.__vModel__) {
            this.setData(item2, "");
          } else {
          }
        });
      });
    },
    // 切换筛选字段
    changeFileds(val, index) {
      this.screenFileds.forEach((item) => {
        if (item.__vModel__ === val) {
          this.setData(item, index);
        }
      });
    },

    handleConditionChange(data) {
      if (
        data.condition.condition === "or" ||
        data.condition.condition === "nor"
      ) {
        this.$set(data.condition, "value", []);
        setTimeout(() => {
          if (this.$refs.multipleSelect) {
            setTimeout(() => {}, 10);
          }
        }, 200);
      } else {
        if (
          data.condition.tagType === "6" &&
          data.__config__.tag === "departSingle"
        ) {
          if (data.condition.condition === "eq") {
            this.$set(data.condition, "value", "");
          } else {
            this.$set(data.condition, "value", []);
          }
          setTimeout(() => {
            this.$set(data.condition, "value", null);
            if (this.$refs.treeselect) {
              console.log(this.$refs.treeselect);
              this.$refs.treeselect[0].clear();
            }
          }, 250);
        } else if (
          data.condition.tagType === "6" &&
          data.__config__.tag === "menberSingle"
        ) {
          this.$set(data.condition.condition, "value", []);

          // setTimeout(() => {
          //   if (this.$refs.multipleSelect) {
          //     this.$refs.multipleSelect.toggleMenu();
          //     setTimeout(() => {
          //       this.$refs.multipleSelect.handleClose();
          //     }, 10);
          //   }
          // }, 200);
        } else if (
          data.condition.tagType === "6" &&
          data.__config__.tag === "user_id"
        ) {
          this.$set(data.condition, "value", []);
        } else if (
          data.condition.tagType === "5" &&
          (data.__config__.tag === "el-checkbox-group" ||
            data.__config__.tag === "el-select")
        ) {
          if (data.condition.condition === "eq") {
            this.$set(data.condition, "value", "");
          } else {
            this.$set(data.condition, "value", []);
            // setTimeout(() => {
            //   if (this.$refs.multipleSelect) {
            //     this.$refs.multipleSelect.toggleMenu();
            //     setTimeout(() => {
            //       this.$refs.multipleSelect.handleClose();
            //     }, 10);
            //   }
            // }, 200);
          }
        } else {
          this.$set(data.condition, "value", "");
        }
      }
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node._id,
        label: node.name,
        children: node.children,
      };
    },
    setData(val, index) {
      let appId = this.$route.query.id;
      let dataSourceId = "";
      let currentData = JSON.parse(JSON.stringify(val));
      this.$set(currentData, "condition", {
        condition: "",
        value: null,
        tagType: "",
        dataList: [],
        conditionOption: [],
        filedsList: JSON.parse(JSON.stringify(this.screenFileds)),
      });
      let typeList1 = [
        "el-input",
        "el-select",
        "el-radio-group",
        "work_flows",
        "el-serial",
      ];
      let typeList2 = ["add_time", "update_time", "datePicker"];
      let typeList3 = [
        "el-checkbox-group",
        "menberCheck",
        "departCheck",
        "el-cascader",
      ];
      let typeList4 = ["menberSingle", "departSingle", "user_id"];
      let type = currentData.__config__.tag;
      // 包括部门单选
      if (typeList1.indexOf(type) > -1 && !currentData.multiple) {
        this.$set(currentData.condition, "tagType", "1");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "or",
            label: "等于任意一个",
          },
          {
            value: "nor",
            label: "不等于任意一个",
          },
          {
            value: "con",
            label: "包含",
          },
          {
            value: "ncon",
            label: "不包含",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);

        if (
          (type === "el-input" && currentData.__config__.tagIcon === "input") ||
          currentData.__config__.tag === "el-serial" ||
          currentData.__config__.tag === "el-cascader"
        ) {
          getSingleFieldData(
            appId,
            this.formId,
            currentData.__vModel__,
            dataSourceId
          ).then((res) => {
            console.log(res.data, "呵呵红红火火恍恍惚惚");
            res.data.forEach((item, index) => {
              if (item) {
                currentData.condition.dataList.push({
                  value: item,
                  label: item,
                });
              }
            });
          });
        } else {
          if (currentData.__config__.tagIcon !== "textarea") {
            this.$set(
              currentData.condition,
              "dataList",
              currentData.__slot__.options
            );
          }
        }
      }

      if (typeList1.indexOf(type) > -1 && currentData.multiple) {
        this.$set(currentData.condition, "tagType", "5");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "or",
            label: "包含任意一个",
          },
          {
            value: "cna",
            label: "同时包含",
          },
          {
            value: "eq",
            label: "等于",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);
        this.$set(
          currentData.condition,
          "dataList",
          currentData.__slot__.options
        );
      }
      if (currentData.__config__.tagIcon === "textarea") {
        this.$set(currentData.condition, "tagType", "2");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "con",
            label: "包含",
          },
          {
            value: "ncon",
            label: "不包含",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);
      }
      if (type === "inputNumber") {
        this.$set(currentData.condition, "tagType", "2");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },

          {
            value: "gt",
            label: "大于",
          },
          {
            value: "gteq",
            label: "大于等于",
          },
          {
            value: "lt",
            label: "小于",
          },
          {
            value: "lteq",
            label: "小于等于",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);
      }
      if (typeList2.indexOf(type) > -1) {
        this.$set(currentData.condition, "tagType", "4");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "gteq",
            label: "大于等于",
          },
          {
            value: "lteq",
            label: "小于等于",
          },

          {
            value: "range",
            label: "范围",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);
      }

      if (typeList3.indexOf(type) > -1) {
        this.$set(currentData.condition, "tagType", "5");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "or",
            label: "包含任意一个",
          },
          {
            value: "cna",
            label: "同时包含",
          },
          {
            value: "eq",
            label: "等于",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);

        if (type === "menberCheck" && currentData.multiple) {
          this.$set(currentData.condition, "value", []);
        } else if (type === "departCheck" && currentData.multiple) {
          getDepartMent({ type: "all" }).then((res) => {
            this.$set(currentData.condition, "value", []);
            this.$set(currentData.condition, "dataList", res.data);
          });
        } else {
          this.$set(currentData.condition, "value", []);
          this.$set(
            currentData.condition,
            "dataList",
            currentData.__slot__.options
          );
        }
      }

      if (typeList4.indexOf(type) > -1 && !currentData.multiple) {
        this.$set(currentData.condition, "tagType", "6");
        this.$set(currentData.condition, "conditionOption", [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "or",
            label: "等于任意一个",
          },
          {
            value: "nor",
            label: "不等于任意一个",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ]);

        if (type === "menberSingle" && !currentData.multiple) {
        } else if (type === "departSingle" && !currentData.multiple) {
          getDepartMent({ type: "all" }).then((res) => {
            this.$set(currentData.condition, "dataList", res.data);
          });
        } else if (type === "user_id") {
          this.addCurrentUser().then((updatedList) => {
            this.$set(currentData.condition, "dataList", updatedList);
          });
        } else {
        }
      }
      setTimeout(() => {
        if (index !== "") {
          this.screenList = Array.from(
            new Set(this.screenList.map((item) => item.__vModel__))
          ).map((vModel) =>
            this.screenList.find((item) => item.__vModel__ === vModel)
          );
          this.$set(this.screenList, index, currentData);
        } else {
          this.screenList.push(currentData);
          this.screenList = Array.from(
            new Set(this.screenList.map((item) => item.__vModel__))
          ).map((vModel) =>
            this.screenList.find((item) => item.__vModel__ === vModel)
          );
          this.fileShow = false;
        }

        this.modelList = [];
        this.screenList.forEach((item) => {
          this.modelList.push(item.__vModel__);
        });
        this.filedCheckList = this.modelList;
      }, 0);
    },
    addCurrentUser() {
      let query = {
        app_id: this.appId,
        form_id: this.formId,
        field: "user_id",
      };
      return getSelectFields(query).then((res) => {
        // 获取当前用户的 _id
        const currentUserId = this.$store.state.user.PermissionsList._id;
        let list = res.items;
        // 删除任何 _id 为当前用户的条目，防止重复
        list = list.filter((item) => item._id !== currentUserId);
        // 添加当前用户信息到列表最前面
        list.unshift({
          _id: currentUserId.toString(),
          name: "当前用户",
        });
        // 返回修改后的 list
        return list;
      });
    },
    // 筛选确定按钮
    screenSubmit() {
      this.screenList.forEach((item) => {
        if (item.__config__.tag === "inputNumber") {
          item.condition.value = Number(item.condition.value);
        }
      });
      let data = {
        filterList: this.screenList,
        filterType: this.screenValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        form_id: this.formId,
        app_id: this.appId,
      };
      this.copyScreenList = JSON.parse(JSON.stringify(this.screenList));

      this.$emit("screenSubmit", data);
    },
    clearFun() {
      this.screenList = [];
      this.filedCheckList = this.filedCheckList.filter((item2) => {
        let found = this.screenList.some((item) => item.__vModel__ === item2);
        return found;
      });
      this.modelList = this.filedCheckList;
    },
    // 删除
    delScreen(index) {
      this.screenList.splice(index, 1);
      this.filedCheckList = this.filedCheckList.filter((item2) => {
        let found = this.screenList.some((item) => item.__vModel__ === item2);
        return found;
      });
      this.modelList = this.filedCheckList;
    },
  },
};
</script>
<style lang="scss" scoped>
.screenBox1 {
  ::v-deep .el-input__inner {
    width: 80px;
    border: none;
    box-shadow: none;
  }
}

.selectNoneBorder {
  ::v-deep .el-input__inner {
    border: none;
    box-shadow: none;
  }
}

.screenBox2 {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  // .delicon {
  //   display: ;
  // }
}

.line {
  height: 1px;
  width: 100%;
  background-color: #ebecee;
  margin: 10px 0;
}

.add_sort {
  cursor: pointer;
  font-size: 16px;
  color: #409eff;
  margin-bottom: 15px;
  width: 200px;
}
</style>
