<!--  -->
<template>
  <div>
    <el-cascader
      style="width: 100%"
      :options="options"
      clearable
      v-model="address"
      @change="sendValue"
      ref="cascader"
      :disabled="data.disabled"
    >
    </el-cascader>
    <el-input
      style="margin-top: 10px"
      type="textarea"
      :rows="3"
      placeholder="请输入详细地址"
      v-model="detailAddr"
      @blur="inputBlur"
    >
    </el-input>
  </div>
</template>

<script>
import { cityCode } from "@/utils/address";
export default {
  data() {
    return {
      options: cityCode,
      address: [],
      detailAddr: "",
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value(val) {
      this.sendValue();
    },
  },
  methods: {
    inputBlur() {
      let value = [];
      if (this.address.length === 0 && !this.detailAddr) {
        let obj = {
          name: this.data.__vModel__,
          value: "",
        };
        this.$emit("tableValue", obj);
        return false;
      }
      if (this.address.length > 0) {
        value = [];
        this.address.forEach((item, index) => {
          this.$set(value, index, item);
        });
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      } else {
        this.value = [];
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      }

      let obj = {
        name: this.data.__vModel__,
        value: value,
        index: this.index,
      };
      this.$emit("tableValue", obj);
    },
    sendValue(val) {
      let value = [];
      if (this.address.length === 0 && !this.detailAddr) {
        let obj = {
          name: this.data.__vModel__,
          value: "",
        };
        this.$emit("tableValue", obj);
        return false;
      }
      if (this.address.length > 0) {
        value = [];
        this.address.forEach((item, index) => {
          this.$set(value, index, item);
        });
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      } else {
        this.value = [];
        if (this.detailAddr) {
          value.push(this.detailAddr);
        }
      }
      let obj = {
        name: this.data.__vModel__,
        value: value,
        index: this.index,
      };
      this.$emit("tableValue", obj);
    },
    resetCasadeSelector() {
      if (this.$refs.cascader) {
        this.address = [];
        this.$refs.cascader.$refs.panel.activePath = [];
        this.$refs.cascader.$refs.panel.calculateCheckedNodePaths();
      }
    },
    cityChange(data) {
      data.forEach((item) => {
        item.value = item.label;
        if (item.children) {
          this.cityChange(item.children);
        }
      });
    },
  },
  mounted() {
    if (this.draftData.value) {
      this.value = this.draftData.value;
    } else {
      this.value = this.data.__config__.defaultValue;
    }
    this.resetCasadeSelector();
    setTimeout(() => {
      if (this.value) {
        if (this.value.length === 1) {
          this.detailAddr = this.value[0];
        } else if (this.value.length === 3) {
          this.value.forEach((item, index) => {
            this.$set(this.address, index, item);
          });
        } else if (this.value.length > 3) {
          this.value.forEach((item, index) => {
            if (index < 3) {
              this.$set(this.address, index, item);
            } else {
              this.detailAddr = item;
            }
          });
        }
        let obj = {
          name: this.data.__vModel__,
          value: this.value,
          index: this.index,
        };
        this.$emit("tableValue", obj);
      }
    }, 500);
  },
  created() {
    this.cityChange(cityCode);
  },
};
</script>
<style lang='scss' scoped>
.address {
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  font-size: 14px;
  line-height: 32px;
  text-indent: 10px;
  font-weight: 400;
  color: #c0c4cc;
}
</style>
