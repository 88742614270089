<!--
 * @Author: zhou_q601 15879230727@163.com
 * @Date: 2025-02-10 17:27:53
 * @LastEditors: zhou_q601 15879230727@163.com
 * @LastEditTime: 2025-02-17 10:11:28
 * @FilePath: \ldmpt-frontend\ldmpc\src\views\gridLayout\components\filterChart\index.vue
 * @Description: 
-->
<template>
  <!--  编辑界面阻止所有事件传播 -->
	<div class="echarts-filter" >
    <div :style="{ pointerEvents: !islook ? 'none' : 'auto' }">

    <div style="margin-top: 5px;">
      <div class="filter-title">{{ tranName || '默认值' }}</div>
      <el-select
        v-model="condition.condition"
        @change="handleConditionChange"
        style="width: calc(100% - 60px); display: inline-block; float: right;"
        class="filter-condition"
      >
        <el-option
          v-for="item in conditionOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-select
      v-model="condition.value"
      style="width: 100%"
      ref="multipleSelect"
      :multiple="
        condition.condition !== 'eq' &&
        condition.condition !== 'neq'
      "
      @change="changeCondition"
    >
      <el-option
        v-for="item in dataList"
        :key="item.value"
        :label="item.label ? item.label : item.value"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>

    <el-dialog
      v-if="filterDialogVisible"
      :visible="true"
      title="添加筛选器"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleFilterClose"
      append-to-body
      :modal-append-to-body="false"
      class='filterDialogClass'
    >
      <div class="content" style="display: flex">
        <div class="left">
          <h4>1、选择需要筛选的图表</h4>

          <!-- <el-checkbox v-model="formNameChecked" checked disabled>{{
            formName
          }}</el-checkbox> -->
          <el-checkbox-group v-model="checkChartList" @change="changeChartList">
            <el-checkbox v-for="(item, index) in layout.filter(i => i.data.type === 3 && i.set_config)" :key="index" :label="item.i">{{ item.set_config.title }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="center">
          <h4>2、选择筛选字段</h4>
          <el-select v-model="fieldValue" filterable placeholder="请选择" v-if="isChartChecked || fieldValue" @change="changeField">
            <el-option
              v-for="item in fieldOptions"
              :key="item.fileds"
              :label="item.label"
              :value="item.fileds">
            </el-option>
          </el-select>
          <!-- <div style="font-size: 12px; margin: 5px 0">{{ formName }}</div> -->
          <!-- <el-select
            v-model="condition.conditionfileds"
            placeholder="请选择"
            @change="filedsChange"
            style="width: 100%"
          >
            <el-option
              v-for="item in fieldsList"
              :key="item.__vModel__"
              :label="item.__config__.label"
              :value="item.__config__.label"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="right">
          <h4>3、设置选择器</h4>
          <template v-if="fieldValue">
            <div style="margin-bottom: 20px">
              <div style="font-size: 12px; margin: 5px 0">筛选器名称</div>
              <el-input v-model="tranName"></el-input>
            </div>
            <div style="margin-bottom: 20px">
              <div style="font-size: 12px; margin: 5px 0">筛选方式</div>
              <el-radio-group
                v-model="condition.conditionway"
                @input="radioChange"
              >
                <el-radio-button label="chosevalue">选择选项</el-radio-button>
                <el-radio-button label="inputtext">输入文本</el-radio-button>
                <!-- <el-radio-button label="exactsearch">精准查询</el-radio-button> -->
              </el-radio-group>
            </div>
            <div style="margin-bottom: 20px">
              <div style="font-size: 12px; margin: 5px 0">默认值</div>
              <el-select
                v-model="condition.condition"
                @change="handleConditionChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in conditionOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <div v-if="condition.conditionway == 'chosevalue'">
                <div
                  class="content-value"
                  v-show="
                    condition.condition !== 'emp' &&
                    condition.condition !== 'nemp'
                  "
                >
                  <div v-if="tagType === '1'">
                    <el-select
                      v-if="
                        condition.condition === 'or' ||
                        condition.condition === 'nor'
                      "
                      multiple
                      ref="multipleSelect"
                      v-model="condition.value"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      v-model="condition.value"
                      v-else-if="
                        condition.condition === 'con' ||
                        condition.condition === 'ncon'
                      "
                    ></el-input>
                    <el-select
                      v-else
                      v-model="condition.value"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <el-input
                    v-model="condition.value"
                    v-else-if="tagType === '2'"
                    :type="
                      type === 'inputNumber'
                        ? 'number'
                        : 'text'
                    "
                  ></el-input>
                  <div v-else-if="tagType === '4'" style="width: 100%">
                    <el-date-picker
                      v-if="condition.condition !== 'range'"
                      style="width: 100%"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="condition.value"
                      type="datetime"
                      placeholder="选择日期时间"
                    >
                    </el-date-picker>
                    <el-date-picker
                      v-else
                      style="width: 100%"
                      v-model="condition.value"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </div>
                  <div style="width: 100%" v-else-if="tagType === '5'">
                    <div v-if="type === 'menberCheck'">
                      <el-select
                        v-model="condition.value"
                        style="width: 100%"
                        ref="multipleSelect"
                        :multiple="true"
                      >
                        <el-option
                          v-for="item in allMenberList"
                          :key="item._id"
                          :label="item.name"
                          :value="item._id"
                        >
                        </el-option>
                      </el-select>
                    </div>

                    <treeselect
                      v-model="condition.value"
                      :options="dataList"
                      :multiple="true"
                      v-else-if="type === 'departCheck'"
                      no-options-text="暂无数据"
                      no-children-text="暂无数据"
                      placeholder="请选择部门"
                      ref="treeselect"
                      :normalizer="normalizer"
                    ></treeselect>

                    <el-select
                      v-model="condition.value"
                      style="width: 100%"
                      ref="multipleSelect"
                      multiple
                      v-else
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item.value"
                        :label="item.label ? item.label : item.value"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div style="width: 100%" v-else-if="tagType === '6'">
                    <el-select
                      v-model="condition.value"
                      style="width: 100%"
                      :multiple="
                        condition.condition !== 'eq' &&
                        condition.condition !== 'neq'
                      "
                      ref="multipleSelect"
                      v-if="type === 'menberSingle'"
                    >
                      <el-option
                        v-for="item in allMenberList"
                        :key="item._id"
                        :label="item.name"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                    <treeselect
                      v-model="condition.value"
                      :options="dataList"
                      :multiple="
                        condition.condition !== 'eq' &&
                        condition.condition !== 'neq'
                      "
                      ref="treeselect"
                      v-else-if="type === 'departSingle'"
                      no-options-text="暂无数据"
                      no-children-text="暂无数据"
                      placeholder="请选择部门"
                      :normalizer="normalizer"
                    ></treeselect>
                    <el-select
                      v-model="condition.value"
                      style="width: 100%"
                      ref="multipleSelect"
                      :multiple="
                        condition.condition !== 'eq' &&
                        condition.condition !== 'neq'
                      "
                      v-else-if="type === 'user_id'"
                    >
                      <el-option
                        v-for="item in dataList"
                        :key="item._id"
                        :label="item.name"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div
                v-else-if="condition.conditionway == 'inputtext'"
                class="addTag"
              >
                <el-tag
                  :key="tag"
                  v-for="tag in condition.value"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseTag(tag)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="mini"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                  placeholder="添加筛选值"
                >
                </el-input>
              </div>
              <div v-else-if="condition.conditionway == 'exactsearch'"></div>
            </div>
          </template>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="filterDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>
<script>
import {
  getWmYBP,
  getDraftList,
  getSingleFieldData,
  postCreateSubYBP
} from "@/api/app";
import { getBiChartsSetting } from "@/api/charts";
export default {
  props: ["index", "islook", "layoutData", "id"],
  data() {
    return {
      conditionOption: [],
      filterDialogVisible: false,
      formNameChecked: true,
      condition: {
        conditionfileds: "",
        conditionname: "",
        condition: "",
        conditionway: "chosevalue",
        value: null,
        fieldOptions: [],
        dataList: [],
        conditionOption: [],
      },
      tagType: '',
      app_id: '',
      form_id: '',
      layout: '',
      checkChartList: [], // 筛选器选中图表数组  
      fieldValue: '', // 筛选字段
      isChartChecked: false, // 是否选中图表数据
      fieldOptions: [], //筛选下拉列表
      type: '', // 筛选下拉选中组件类型
      dataList: [], // 条件下拉列表
      tranName: '', // 条件
    }
  },
  created() {
    this.app_id = this.$route.query.appId;
    this.form_id = this.$route.query.formId;
    // 预览时直接读配置
    
  },
  mounted() {
    // if (this.islook) {
      this.layout = this.layoutData
      if (this.layout[this.index] && this.layout[this.index]?.content) {
        this.condition = { ...this.layout[this.index].content }
        // let a  = this.condition
        // debugger
        this.fieldOptions = this.condition.fieldOptions,
        this.dataList = this.condition.dataList,
        this.conditionOption = this.condition.conditionOption
        this.tranName = this.condition.tranName 
        this.checkChartList = this.condition.checkChartList
        this.fieldValue = this.condition.fieldValue
        this.setData(this.fieldValue)
    } else {
      // this.getLayoutData();
    }
    
    // this.openFilterDialog()
    
  },
  methods: {
    getLayoutData() {
      getWmYBP(this.app_id, this.form_id).then((res) => {
        this.layout = res.data.layout;
        // this.condition = { ...this.layout[this.index].set_config }
        // this.fieldOptions = this.condition.fieldOptions,
        // this.dataList = this.condition.dataList,
        // this.conditionOption = this.condition.conditionOption
        // this.tranName = this.condition.tranName 
      });
    },
    async handleSubmit() {
      if (!this.condition.condition) {
        return this.$message.error("请选择条件");
      }
      // if (
      //   this.condition.condition !== "emp" &&
      //   this.condition.condition !== "nemp"
      // ) {
      //   if (!this.condition.value) {
      //     return this.$message.error("请输入条件值");
      //   }
      // }
      this.condition.fieldOptions = this.fieldOptions
      this.condition.fieldValue = this.fieldValue
      this.condition.dataList = this.dataList
      this.condition.conditionOption = this.conditionOption
      this.condition.tranName = this.tranName;
      this.condition.checkChartList = this.checkChartList
      let query = JSON.parse(JSON.stringify(this.condition));
      if (this.type === "inputNumber") {
        query.value = Number(query.value);
      }
      // const obj = this.layout.slice(-1)[0];
      // obj.data.type = 10
      // const res = await postCreateSubYBP(this.app_id, this.form_id, '', obj)

      this.$emit("filterUpdate", {
        data: query,
        index: this.index,
        // id: res.data
      });
      this.filterDialogVisible = false;  
    },
    setData(val) {
      // if (this.currentItem.isRow && this.filterType === "or") {
      //   this.isProhibit = true;
      // }
      this.type = this.fieldOptions.filter(item => item.fileds === val)[0].tag
      this.tagType = "";
      let typeList1 = [
        "el-input",
        "el-select",
        "el-radio-group",
        "work_flows",
        "el-serial",
      ];
      let typeList2 = ["add_time", "update_time", "datePicker"];
      let typeList3 = [
        "el-checkbox-group",
        "menberCheck",
        "departCheck",
        "el-cascader",
      ];
      let typeList4 = ["menberSingle", "departSingle", "user_id"];
      // 包括部门单选
      // if (typeList1.indexOf(type) > -1 && !this.currentItem.multiple) {
      if (typeList1.indexOf(this.type) > -1) {
        this.tagType = "1";
        this.conditionOption = [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "or",
            label: "等于任意一个",
          },
          {
            value: "nor",
            label: "不等于任意一个",
          },
          {
            value: "con",
            label: "包含",
          },
          {
            value: "ncon",
            label: "不包含",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.condition.condition = this.conditionOption[0].value;
        if(this.type === "el-input" &&
          this.type === "el-serial" ||
          this.type === "el-cascader"
        ) {
          
        } else {
          if (typeIcon !== "textarea") {
            this.dataList = this.currentItem.__slot__.options;
          }
        }
      }
      // 部门多选
      if (typeList1.indexOf(this.type) > -1 && this.currentItem.multiple) {
        this.tagType = "5";
        this.conditionOption = [
          {
            value: "or",
            label: "包含任意一个",
          },
          {
            value: "cna",
            label: "同时包含",
          },
          {
            value: "eq",
            label: "等于",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.dataList = this.currentItem.__slot__.options;
        this.condition.condition = this.conditionOption[0].value;
      }
      if (typeIcon === "textarea") {
        this.tagType = "2";
        this.conditionOption = [
          {
            value: "con",
            label: "包含",
          },
          {
            value: "ncon",
            label: "不包含",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.condition.condition = this.conditionOption[0].value;
      }
      if (this.type === "inputNumber") {
        this.tagType = "2";
        this.conditionOption = [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },

          {
            value: "gt",
            label: "大于",
          },
          {
            value: "gteq",
            label: "大于等于",
          },
          {
            value: "lt",
            label: "小于",
          },
          {
            value: "lteq",
            label: "小于等于",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.condition.condition = this.conditionOption[0].value;
      }
      if (typeList2.indexOf(this.type) > -1) {
        this.tagType = "4";
        this.conditionOption = [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "gteq",
            label: "大于等于",
          },
          {
            value: "lteq",
            label: "小于等于",
          },

          {
            value: "range",
            label: "范围",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.condition.condition = this.conditionOption[0].value;
      }
      if (typeList3.indexOf(this.type) > -1) {
        this.tagType = "5";
        this.conditionOption = [
          {
            value: "or",
            label: "包含任意一个",
          },
          {
            value: "cna",
            label: "同时包含",
          },
          {
            value: "eq",
            label: "等于",
          },

          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
          (this.condition.condition = this.conditionOption[0].value),
        ];
        if (this.type === "menberCheck" && this.currentItem.multiple) {
        } else if (this.type === "departCheck" && this.currentItem.multiple) {
          getDepartMent({ type: "all" }).then((res) => {
            this.dataList = res.data;
          });
        } else {
          this.dataList = this.currentItem.__slot__.options;
        }
      }
      if (typeList4.indexOf(this.$emittype) > -1 && !this.currentItem.multiple) {
        this.tagType = "6";
        this.conditionOption = [
          {
            value: "eq",
            label: "等于",
          },
          {
            value: "neq",
            label: "不等于",
          },
          {
            value: "or",
            label: "等于任意一个",
          },
          {
            value: "nor",
            label: "不等于任意一个",
          },
          {
            value: "emp",
            label: "为空",
          },
          {
            value: "nemp",
            label: "不为空",
          },
        ];
        this.condition.condition = this.conditionOption[0].value;
        if (this.type === "menberSingle" && !this.currentItem.multiple) {
        } else if (this.type === "departSingle" && !this.currentItem.multiple) {
          getDepartMent({ type: "all" }).then((res) => {
            this.dataList = res.data;
          });
        } else {
        }
      }
    },

    // 打开筛选条件弹框
    openFilterDialog() {
      this.filterDialogVisible = true
    },

    // 筛选器选中图表数组发生变化
    async changeChartList(val) {
      if(!val.length) {
        this.isChartChecked = false;
        this.fieldOptions = []
        this.fieldValue = ''
        return 
      } else {
        let id = this.layout.filter(item => item.i === val[0])[0].set_config.draggableObj.data_source_id
        this.isChartChecked = true;
        const res = await getDraftList(this.$route.query.appId, id)
        if (res.code === 200) {
          this.fieldOptions = res.data.head
        }
      } 
    },
    // 筛选器选中字段
    async changeField(val) {
      // return
      this.tranName = this.fieldOptions.filter(item => item.fileds === val)[0].label
      let id = this.layout.filter(item => item.i === this.checkChartList[0])[0].set_config.draggableObj.data_source_id
      const res = await getSingleFieldData(
        this.app_id,
        id,
        val,
      )
      this.dataList = []
      res.data.forEach((item, index) => {
        this.dataList.push({
          value: item,
          label: item,
        });
      });
      this.setData(val)
    },
    handleFilterClose() {
      this.filterDialogVisible = false;
    },
    handleConditionChange(val) {
      if (
        this.condition.condition === "or" ||
        this.condition.condition === "nor"
      ) {
        this.$set(this.condition, "value", []);

        setTimeout(() => {
          if (this.$refs.multipleSelect) {
            this.$refs.multipleSelect.toggleMenu();
            setTimeout(() => {
              this.$refs.multipleSelect.handleClose();
            }, 10);
          }
        }, 200);
      } else {
        if (
          this.tagType === "6" &&
          this.type === "departSingle"
        ) {
          if (this.condition.condition === "eq") {
            this.$set(this.condition, "value", "");
          } else {
            this.$set(this.condition, "value", []);
          }
          setTimeout(() => {
            this.$refs.treeselect.clear();
          }, 50);
        } else if (
          this.tagType === "6" &&
          this.type === "menberSingle"
        ) {
          this.$set(this.condition, "value", []);

          setTimeout(() => {
            if (this.$refs.multipleSelect) {
              this.$refs.multipleSelect.toggleMenu();
              setTimeout(() => {
                this.$refs.multipleSelect.handleClose();
              }, 10);
            }
          }, 200);
        } else if (
          this.tagType === "6" &&
          this.type === "user_id"
        ) {
          this.$set(this.condition, "value", []);
          setTimeout(() => {
            if (this.$refs.multipleSelect) {
              this.$refs.multipleSelect.toggleMenu();
              setTimeout(() => {
                this.$refs.multipleSelect.handleClose();
              }, 10);
            }
          }, 200);
        } else if (
          this.tagType === "5" &&
          (this.type === "el-checkbox-group" ||
            this.type === "el-select")
        ) {
          if (this.condition.condition === "eq") {
            this.$set(this.condition, "value", "");
          } else {
            this.$set(this.condition, "value", []);
            setTimeout(() => {
              if (this.$refs.multipleSelect) {
                this.$refs.multipleSelect.toggleMenu();
                setTimeout(() => {
                  this.$refs.multipleSelect.handleClose();
                }, 10);
              }
            }, 200);
          }
        } else {
          this.$set(this.condition, "value", "");
        }
      }
    },
    radioChange(label) {
      if (label == "chosevalue") {
        this.condition.value = "";
      } else if (label == "inputtext") {
        this.condition.value = [];
      }
    },

    // 条件筛选
    changeCondition(val) {
      // return
      if(this.islook) {
        let condition = {
          value: val,
          condition: this.condition.condition
        }
        let index = []
        this.layout.forEach((item, inx) => {
          if (this.condition.checkChartList.some(j => j == item.i) && item.set_config) {
            index.push(inx)
          }
        })
        let field = this.condition.fieldOptions.filter( item => item.fileds === this.condition.fieldValue ).map(item => item.conf)
        field[0].condition = condition
        if (index.length) {
          index.forEach(item => {
            if (!this.layout[item].set_config.draggableObj.filterList.length) {
              this.layout[item].set_config.draggableObj.filterList = field
            } else {
              // 筛选条件默认新的替换旧的
              let arr = JSON.parse(JSON.stringify(this.layout[item].set_config.draggableObj.filterList))
              arr = arr.filter(item => item.__vModel__ !== this.condition.fieldValue)
              arr.push(field[0])
              this.$set(this.layout[item].set_config.draggableObj, 'filterList', arr)
            }
          })
        }
      }
    },

    // 编辑界面阻止事件捕获/冒泡
    stopDefault(event) {
      debugger
      event.preventDefault()
    }
  },
}
</script>
<style lang="scss" scoped>
.echarts-filter {
  font-size: 14px;
  .filter-title{
    display: inline-block;
    width: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // padding-right: 5px;
    line-height: 30px;
    color: rgb(31, 45, 61);
  }
  .filter-condition{
    ::v-deep .el-input__inner{
      border: 1px solid transparent;
      padding: 0px;
      color: #0265FF !important;
    }
    ::v-deep .el-select__caret{
      color: #0265FF !important;
    }
  }
  // padding: 24px 24px 0 24px;
  // height: 100%;
  // box-sizing: border-box;
  // position: relative;
}
.draggableItem-li {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 25px;
  background: #409eff;
  border-radius: 13px;
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 20px;

  & > div:nth-child(1) {
    margin: 0 5px;
  }

  i {
    font-size: 14px;
    cursor: pointer;
  }
}
.content {
  .left {
    width: 30%;
  }
  .right,
  .center {
    width: 30%;
    border-left: 1px solid #eee;
    padding: 0 1%;
  }
}
.content-label {
  display: flex;
  align-items: center;
}

.content-value {
  padding: 5px 0;
  box-sizing: border-box;
}
</style>