import Vue from "vue";

import "element-ui/lib/theme-chalk/index.css";
import ElementUI, {
  Button
} from "element-ui";
import "./styles/index.scss";
// import { vueJsonp } from "vue-jsonp";
import {
  VueJsonp
} from "vue-jsonp";

import "@/formStyles/index.scss";

import datePicker from "@/components/datePicker/index.vue";
import inputNumber from "@/components/inputNumber/index.vue";
import Tinymce from "@/components/tinymce/index.vue";
import departSingle from "@/components/departSingle/index.vue";
import departCheck from "@/components/departCheck/index.vue";
import menberSingle from "@/components/menberSingle/index.vue";
import menberCheck from "@/components/menberCheck/index.vue";
import addr from "@/components/addr/index.vue";
import Bus from "@/utils/bus.js"; //这是我的路径，正确引用你们的路径
import Line from "@/components/Line/index.vue";
import extendButton from "@/components/extendButton";
import ImgUpload from "@/components/ImgUpload/index.vue";
import Sign from "@/components/Sign/index.vue";
import Editor from "@/components/Editor/index.vue";
import Position from "@/components/Position/index.vue";
import serialNumber from "@/components/serialNumber/index.vue";
import relationSearch from "@/components/relationSearch/index.vue";
import relationData from "@/components/relationData/index.vue";
import dataLoad from "@/components/dataLoad/index.vue";
import "@/views/chatWithin/src/chatSvgIcon"; // svg 图标
import "@/icons";
import "./iconfont/iconfont.css";
import Print from "vue-print-nb";
import CodeEditor from 'bin-code-editor';
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
//引入echarts
import * as echarts from "echarts";
import "echarts-liquidfill";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//vue全局注入echarts
Vue.prototype.$echarts = echarts;
Vue.prototype.$bus = Bus;
// Vue.prototype.$compressImage = compressImage;
Vue.use(Print);
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.component("inputNumber", inputNumber);
Vue.component("datePicker", datePicker);
Vue.component("tinymce", Tinymce);
Vue.component("el-sign", Sign);
Vue.component("el-position", Position);
Vue.component("el-serial", serialNumber);
Vue.component("el-editor", Editor);
Vue.component("departSingle", departSingle);
Vue.component("departCheck", departCheck);
Vue.component("menberSingle", menberSingle);
Vue.component("menberCheck", menberCheck);
Vue.component("addr", addr);
Vue.component("el-line", Line);
Vue.component("extend-button", extendButton);
Vue.component("img-upload", ImgUpload);
Vue.component("relationSearch", relationSearch);
Vue.component("relationData", relationData);
Vue.component("dataLoad", dataLoad);
Vue.use(ElementUI);
Vue.use(VueJsonp);
Vue.use(CodeEditor);
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

// Vue.use(vueJsonp);
Vue.config.productionTip = false;
Vue.directive("draggable", {
  bind(el, binding, vnode) {
    let dragging = false;

    function dragStart(e) {
      e.preventDefault();
      dragging = true;
      const offset = {
        x: e.clientX - el.offsetLeft,
        y: e.clientY - el.offsetTop,
      };

      document.onmousemove = function (e) {
        if (dragging) {
          vnode.context.$store.dispatch("app/setDragStatus", "1");
          const x = e.clientX - offset.x;
          const y = e.clientY - offset.y;
          el.style.left = x + "px";
          el.style.top = y + "px";

          el.style.cursor = "pointer";
          e.target.style.cursor = "pointer";

          if (!document.getElementById("newId")) {
            var newDiv = document.createElement("div");
            newDiv.textContent = el.textContent;
            newDiv.style.position = "fixed";
            newDiv.style.left = x + "px";
            newDiv.style.top = y + "px";
            newDiv.style.border = "1px dashed #0265FF";
            newDiv.style.background = "rgba(2, 101, 255, .08)";
            newDiv.style.color = "#0265FF";
            newDiv.style.width = "100px";
            newDiv.style.height = "26px";
            newDiv.style.lineHeight = "26px";
            newDiv.style.textAlign = "center";
            newDiv.style.boxSizing = "border-box";

            newDiv.style.zIndex = 99999;
            document.body.appendChild(newDiv);
            newDiv.setAttribute("id", "newId");
          } else {
            var newDiv = document.getElementById("newId");
            newDiv.style.left = x + "px";
            newDiv.style.top = y + "px";
          }
        }
      };
      document.onmouseup = function (e) {
        vnode.context.$store.dispatch("app/updateDragData", binding.value);
        vnode.context.$store.dispatch("app/setDragStatus", "2");
        dragging = false;
        const elementToRemove = document.getElementById("newId");
        elementToRemove.remove();
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }

    // 添加事件监听
    el.addEventListener("mousedown", dragStart);
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
