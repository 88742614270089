<!--  -->
<template>
  <div :style="{ pointerEvents: data.disabled ? 'none' : 'unset' }">
    <div
      :style="{ backgroundColor: data.disabled ? '#F5F7FA' : '#fff' }"
      class="depart-check"
      v-if="data.__config__.defaultValue.length === 0"
      @click="selectDepart"
    >
      {{ data.placeholder }}
    </div>
    <div
      class="depart-check"
      v-else
      @click="selectDepart"
      :style="{ backgroundColor: data.disabled ? '#F5F7FA' : '#fff' }"
    >
      <div
        v-for="(item, index) in data.__config__.defaultValue"
        :key="index"
        class="depart-list"
      >
        <div>{{ item.label }}</div>
      </div>
    </div>

    <menberSelect
      :dialogVisible="selectDialog"
      @handleSelectClose="handleSelectClose"
      :title="title"
      :type="type"
      :activeData="data"
      :selectRange="data.isSelectRange"
      @submit="selectSubmit"
    ></menberSelect>
  </div>
</template>

<script>
import menberSelect from "@/components/menberSelect/index.vue";
export default {
  data() {
    return {
      title: "选择成员",
      echoData: [],
      type: "depart",
      selectDialog: false,
      showTrends: false,
    };
  },
  components: {
    menberSelect,
  },
  mounted() {
    console.log(this.data, "data");
    
    if (
      this.data.__config__.defaultValue &&
      this.data.__config__.defaultValue.length > 0
    ) {
      this.data.__config__.defaultValue =
        this.data.__config__.defaultValue.reduce((accumulator, current) => {
          if (!accumulator[current.id]) {
            accumulator[current.id] = true;
            accumulator.push(current);
          }
          return accumulator;
        }, []);
    }
  },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {},
  methods: {
    selectDepart() {
      if (this.data.modelType === "inDepart") {
        this.$bus.$emit("filed", this.data);

        this.selectDialog = true;
        console.log(this.data, "asas");
      }
      this.selectDialog = true;
    },
    handleSelectClose() {
      this.selectDialog = false;
    },
    sendValue() {
      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
    treeDataChange(data) {
      data.forEach((item) => {
        this.$set(item, "id", item._id);
        this.$set(item, "label", item.name);
        delete item._id;
        delete item.name;
        if (item.children) {
          this.treeDataChange(item.children);
        }
      });
      return data;
    },
    selectSubmit(list, showTrends) {
      this.$set(this.data.__config__, "defaultValue", list);
      this.showTrends = showTrends;

      this.selectDialog = false;
      this.sendValue();
    },
  },
  created() {
    console.log(this.data);
    this.showTrends = this.data.showTrends;

    if (
      (this.data.modelType === "custom" && this.data.trendsChecked) ||
      this.data.modelType === "all"
    ) {
      if (this.data.showTrends) {
        this.data.__config__.defaultValue.push({
          id: this.$store.state.user.PermissionsList._id,
          label: this.$store.state.user.PermissionsList.name,
        });
      }
    }
  },
};
</script>
<style lang='scss' scoped>
::v-deep .menber-tree .el-checkbox {
  position: absolute;
  right: 0;
  top: 8px;
}
.depart-check {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 14px;

  text-indent: 10px;
  font-weight: 400;
  cursor: pointer;
  color: #c0c4cc;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 34px;
}
.depart-list {
  div {
    margin: 0px 5px 5px 5px;
    color: #409eff;
  }
}
</style>
