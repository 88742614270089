<!--  -->
<template>
  <div>
    <el-input v-model="input" readonly="" :placeholder="placeholder"></el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: null,
    };
  },
  props: [
    "numberType",
    "isThousandth",
    "FixedNumber",
    "isDisabled",
    "value",
    "placeholder",
  ],
  watch: {
    value(val) {
      this.input = val;
    },
  },

  methods: {
    inputChange(val) {
      // this.input = this.input.replace(/[^-\d.]/g, "");
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>
v::d-eeep input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
v::d-eeep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
