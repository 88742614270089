export function regularDict(msg) {
  let pattern = ""
  if (msg === '手机号码格式错误') {
    pattern = /^1[3456789]\d{9}$/
  } else if (msg === '身份证号码格式错误') {
    pattern = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  } else if (msg === '邮箱格式错误') {
    pattern = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  } else if (msg === '邮政编码格式错误') {
    pattern = /^[1-9]\d{5}$/
  } else if (msg === '固定电话格式错误') {
    pattern = /^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/
  }

  return pattern
}
