
<!--  -->
<template>
  <div v-if="dialogVisible">
    <el-dialog :close-on-click-modal="false" :title="title" :modal-append-to-body="false" append-to-body
      :visible.sync="dialogVisible" width="50%" class="depart-select-dialog" :before-close="handleSelectClose"
      @open="handleOpen" :destroy-on-close="true">
      <div class="content">
        <div class="depart-ul">
          <div class="depart-li" v-for="(item, index) in selectList" :key="index">
            <div class="li-info">
              <i class="iconfont icon-zuzhibumen"></i>

              <div style="margin: 0 5px">
                {{ item.label }}
              </div>
              <i class="iconfont icon-shanchu" style="color: red" @click="delSelect(item)"></i>
            </div>
          </div>
          <!-- selectList: {{ selectList }}
          selectTrendsList: {{ selectTrendsList }} -->
          <div v-if="showTrends">
            <div class="depart-li" v-for="(item, index) in selectTrendsList" :key="index">
              <div class="li-info">
                <i class="iconfont icon-zuzhibumen"></i>

                <div style="margin: 0 5px">{{ item.label }}</div>
                <i class="iconfont icon-shanchu" style="color: red" @click="delTrendsSelect(item)"></i>
              </div>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" style="margin-bottom: -20px">
          <el-tab-pane label="组织架构" name="1"></el-tab-pane>
          <el-tab-pane label="动态参数" name="2" v-if="activeData.modelType === 'all' ||
            (activeData.modelType === 'custom' && activeData.trendsChecked)
            "></el-tab-pane>
        </el-tabs>
        <el-input placeholder="请输入内容" style="position: relative; top: -30px; width: 60%; left: 40%"
          prefix-icon="el-icon-search" @change="inputDM(1)" @input="inputDM(2)" v-model="inputDepartment">
        </el-input>
        <div class="select-depart-ul" v-show="activeName === '1'">
          <div class="depart-warp">
            <div class="depart-list depart-tree">
              <el-tree :data="data" node-key="id" show-checkbox highlight-current :default-expand-all="false"
                :check-strictly="true" :check-on-click-node="true" ref="tree" @check-change="checkChange"
                @check="handleCheck" :expand-on-click-node="false" accordion>
                <span slot-scope="{ node, data }">
                  <i class="iconfont icon-zuzhibumen" style="color: #409dff"></i>
                  <span style="margin-left: 10px; font-size: 12px">{{
                    node.label
                  }}</span>
                </span>
              </el-tree>
            </div>
          </div>
        </div>
        <div class="select-depart-ul" v-show="activeName === '2'">
          <div class="depart-warp">
            <el-checkbox-group v-model="trendsCheckout" v-if="activeData.multiple">
              <div class="depart-trends-left depart-trends-list" v-for="(item, index) in trendsDepartList" :key="index">
                <div style="flex: 1; display: flex; align-items: center">
                  <i class="iconfont icon-zuzhibumen" style="color: #409dff"></i>
                  <div style="font-size: 12px; margin-left: 10px">
                    {{ item.label }}
                  </div>
                </div>

                <el-checkbox :label="item.id"></el-checkbox>
              </div>
            </el-checkbox-group>
            <el-radio-group v-model="trendsCheckout" v-else style="width: 100%">
              <div class="depart-trends-left depart-trends-list" v-for="(item, index) in trendsDepartList" :key="index">
                <div style="flex: 1; display: flex; align-items: center">
                  <i class="iconfont icon-zuzhibumen" style="color: #409dff"></i>
                  <div style="font-size: 12px; margin-left: 10px">
                    {{ item.label }}
                  </div>
                </div>

                <el-radio :label="item.id"> </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSelectClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDepartMent, getDepartmentList } from '@/api/user'
import { mapGetters } from 'vuex'
import { i } from 'mathjs'
export default {
  data () {
    return {
      checked: false,
      data: [],
      inputDepartment: '',
      selectList: [],
      list: [],
      active: null,
      checkedList: [],
      trendsChecked: false,
      showTrends: false,
      trendsDepartList: [],
      trendsCheckout: [],
      selectTrendsList: [],
      trendsOldCheckout: [],
      allDataList: [],
      activeName: localStorage.getItem('activeName') || 'default',
      intervalId: null
    }
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    type: {
      default: '',
      type: String
    },
    echoData: '',
    selectRange: {
      default: () => {
        return []
      },
      type: Array
    },
    activeData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    // 监听外部变化
    activeName: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          console.log(newVal, oldVal, '212212')

          //  this.getList()
        }
      }
    },

    dialogVisible: {
      immediate: true,
      handler (val, oldVal) {
        this.activeName = '1'
        //     this.getList()
        if (val) {
          this.$nextTick(() => {
            // if (this.$refs.tree) { // 确保 $refs.tree 已定义

            this.selectList = this.activeData.__config__.defaultValue || []

            console.log(this.selectList, 'ajsdjaskdlajsldj')
            this.$refs.tree.setCheckedKeys(this.selectList.map((item) => item.id))

            if (
              this.activeData.__config__.defaultValue &&
              this.activeData.__config__.defaultValue.length > 0
            ) {
              if (this.activeData.multiple) {
                this.activeData.__config__.defaultValue.forEach((item) => {
                  this.$refs.tree.setChecked(item.id, true)
                })
              } else {
                if (
                  this.activeData.__config__.defaultValue[0].id !== this.trendsCheckout
                ) {
                  this.activeData.__config__.defaultValue.forEach((item) => {
                    this.$refs.tree.setChecked(item.id, true)
                  })
                }
              }
            }

            // this.trendsDepartList.forEach((item) => {
            //   this.$refs.tree.setChecked(item.id, true);
            // });
            this.showTrends = this.activeData.showTrends
            // }
          })
        }
      }
    },
    trendsCheckout (val) {
      if (this.activeData.multiple) {
        this.selectTrendsList = []

        val.forEach((item) => {
          this.selectTrendsList.push(this.findNodeById(this.allDataList, item))
        })

        this.$nextTick(() => {
          this.trendsDepartList.forEach((item) => {
            if (!val.some((itemA) => item.id === itemA)) {
              this.$refs.tree.setChecked(item.id, false)
            } else {
              this.$refs.tree.setChecked(item.id, true)
            }
          })
        })

        this.selectTrendsList.forEach((item, index) => {
          this.selectList.forEach((itemB) => {
            if (item.id === itemB.id) {
              this.selectTrendsList.splice(index, 1)
            }
          })
        })

        if (val.length > 0) {
          this.showTrends = true
        } else {
          this.showTrends = false
        }
      } else {
        if (val) {
          this.selectList = []

          this.trendsDepartList.forEach((item) => {
            if (item.id === val) {
              this.selectTrendsList.push(item)
            }
          })
          this.showTrends = true
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([])
          })
        } else {
          this.selectTrendsList = []
          this.showTrends = false
        }
      }
    }
  },
  computed: {
    ...mapGetters(['allMenberList', 'allDepartList'])
  },
  methods: {
    getData (node, data) {
      console.log(node, data)
    },
    inputDM (index) {
      //输入部门名称搜索
      let obj = {
        name: this.inputDepartment
      }
      if (index === 2) {
        this.data = this.allDataList
      }
      if (this.inputDepartment !== '') {
        getDepartmentList(obj).then((res) => {
          if (res.code === 200) {
            this.data = res.data
          }
        })
      } else {
        this.data = this.allDataList
      }
    },
    filterData (data, inputDepartment) {
      let result = []
      for (let i = 0; i < data.length; i++) {
        const department = data[i]
        // 如果找到了匹配的label
        if (department.label === inputDepartment) {
          result.push(department)
        } else {
          if (department.children) {
            const children = this.filterData(department.children, inputDepartment)
            if (children.length > 0) {
              result.push({ ...department, children })
            }
          }
        }
      }
      return result
    },
    handleOpen () {
      console.log('this.activeData', this.activeData)
    },
    handleCheck (nodes, resolve) {
      //nodes节点所对应的对象；resolve选中状态对象
      if (!this.activeData.multiple) {
        if (resolve.checkedKeys.length > 0) {
          this.$refs.tree.setCheckedKeys([nodes.id])
          this.selectTrendsList = []
          this.trendsCheckout = ''
        }
      }
    },
    findNodeById (data, targetId) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === targetId) {
          return data[i]
        } else if (data[i].children && data[i].children.length > 0) {
          const foundNode = this.findNodeById(data[i].children, targetId)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getList () {
      console.log(this.activeData, '部门内部获取值')

      if (this.activeData.multiple) {
        this.trendsCheckout = []
      } else {
        this.trendsCheckout = ''
      }

      getDepartMent({ type: 'all' }).then((res) => {
        console.log(res.data, 'res.data')
        // this.data = this.treeDataChange(res.data);
        this.allDataList = this.treeDataChange(res.data)
        if (this.activeData.modelType === 'custom') {
          // this.data = this.setDisabled(this.data);
          // this.selectRange.forEach((item) => {
          //   this.data = this.setRangeFunc(item.id, this.data);
          // });
          this.selectRange.forEach((item) => {
            if (item.children) {
              delete item.children
            }
          })
          this.data = JSON.parse(JSON.stringify(this.selectRange))
          this.trendsChecked = this.activeData.trendsChecked
        }

        if (
          (this.activeData.modelType === 'custom' &&
            this.activeData.trendsChecked) ||
          this.activeData.modelType === 'all'
        ) {
          if (this.activeData.modelType === 'all') {
            this.data = JSON.parse(JSON.stringify(this.allDataList))
          }
          let arr = JSON.parse(
            JSON.stringify(this.$store.state.user.PermissionsList.department)
          )
          this.trendsDepartList = this.treeDataChange(arr)
          // this.trendsDepartList.forEach((item) => {
          //   this.data.push(item);
          // });
          // this.data = Array.from(new Set(this.data.map((item) => item.id))).map(
          //   (id) => {
          //     return this.data.find((item) => item.id === id);
          //   }
          // );

          if (this.activeData.showTrends) {
            if (this.activeData.multiple) {
              this.trendsDepartList.forEach((item) => {
                this.trendsCheckout.push(item.id)
              })
            } else {
              this.trendsCheckout = this.trendsDepartList[0].id
            }
          }
          if (this.activeData.higherDepart.isShow) {
            let startData = this.findDepartmentAndLevel(
              this.data,
              this.activeData.higherDepart.departId
            ).department
            let nowIds = []
            this.$store.state.user.PermissionsList.department.forEach((item) => {
              nowIds.push(this.findDepartmentLevel([startData], item._id))
            })
            let nowDepartLevel = Math.max(...nowIds)

            // let manageData = this.getNodesAtLevel(
            //   [startData],
            //   this.activeData.higherDepart.levelNum + 1
            // )[0];

            let manageDepart = this.getNodesAtLevel(
              [startData],
              this.activeData.higherDepart.levelNum + 1
            )
            let manageData = this.findTopLevelDepartment(
              this.$store.state.user.PermissionsList.department[
                nowIds.indexOf(nowDepartLevel)
              ]._id,
              manageDepart
            )

            if (this.activeData.higherDepart.levelNum + 1 < nowDepartLevel) {
              console.log(manageData, '部门内容222')
              this.$emit('getManagerDepart', [manageData])
            }
          }
        }
      })
    },
    findTopLevelDepartment (departmentId, departments) {
      for (let i = 0; i < departments.length; i++) {
        const department = departments[i]

        // 如果找到了匹配的ID
        if (department.id === departmentId) {
          return department // 返回当前部门
        }

        // 如果当前部门有子部门，则递归查找子部门
        if (department.children && department.children.length > 0) {
          const found = this.findTopLevelDepartment(
            departmentId,
            department.children
          )
          if (found) {
            return department // 如果在子部门中找到了，返回当前部门作为顶级部门
          }
        }
      }
      return null // 如果没有找到
    },
    getNodesAtLevel (treeData, level) {
      if (level < 1) return [] // 如果level小于1，返回空数组
      let result = []

      // 辅助函数，递归遍历树结构
      const traverse = (nodes, currentLevel) => {
        if (currentLevel === level) {
          result.push(...nodes)
          return
        }

        nodes.forEach((node) => {
          if (node.children && node.children.length > 0) {
            traverse(node.children, currentLevel + 1)
          }
        })
      }

      // 从根节点开始递归
      traverse(treeData, 1)

      return result
    },
    findDepartmentLevel (departments, targetId, level = 1) {
      // 遍历当前层级的部门
      for (let department of departments) {
        // 如果找到目标部门，返回当前的层级
        if (department.id === targetId) {
          return level
        }

        // 如果当前部门有子部门，递归查找子部门
        if (department.children) {
          const result = this.findDepartmentLevel(
            department.children,
            targetId,
            level + 1
          )
          // 如果在子部门中找到了，直接返回
          if (result !== -1) {
            return result
          }
        }
      }

      // 如果没有找到目标部门，返回 -1
      return -1
    },
    findDepartmentAndLevel (departments, targetId, level = 1) {
      for (let department of departments) {
        // 如果当前部门的 ID 与目标 ID 匹配，返回当前层级和子级数据
        if (department.id === targetId) {
          return {
            level, // 当前部门所在的层级
            department, // 当前部门的数据
            children: department.children || [] // 返回该部门的所有子级数据
          }
        }

        // 如果该部门有子部门，则递归查找
        if (department.children) {
          const result = this.findDepartmentAndLevel(
            department.children,
            targetId,
            level + 1
          )
          if (result) {
            return result
          }
        }
      }

      // 如果没有找到目标部门，返回 null
      return null
    },
    setDisabled (data) {
      data.forEach((item) => {
        this.$set(item, 'disabled', true)
        if (item.children) {
          this.setDisabled(item.children)
        }
      })
      return data
    },
    setRangeFunc (id, data) {
      data.forEach((item) => {
        if (id === item.id) {
          this.$set(item, 'disabled', false)
        }
        if (item.children) {
          this.setRangeFunc(id, item.children)
        }
      })
      return data
    },
    handleSelectClose () {
      this.$emit('handleSelectClose')
    },

    checkChange (data, el, childEl) {
      this.selectList = this.$refs.tree.getCheckedNodes()
      if (this.activeData.multiple) {
        this.selectTrendsList = []
        if (!el) {
          this.trendsCheckout.forEach((item, index) => {
            if (item === data.id) {
              this.trendsCheckout.splice(index, 1)
            }
          })
        } else {
          if (
            this.trendsCheckout.indexOf(data.id) === -1 &&
            this.trendsDepartList.some((itemA) => data.id === itemA.id)
          ) {
            this.trendsCheckout.push(data.id)
          }
        }
        this.trendsCheckout.forEach((item) => {
          this.selectTrendsList.push(this.findNodeById(this.data, item))
        })

        this.selectTrendsList.forEach((item, index) => {
          this.selectList.forEach((itemB) => {
            if (item.id === itemB.id) {
              this.selectTrendsList.splice(index, 1)
            }
          })
        })
        this.trendsDepartList.forEach((item) => {
          if (!this.trendsCheckout.some((itemA) => item.id === itemA)) {
            this.$refs.tree.setChecked(item.id, false)
          } else {
            this.$refs.tree.setChecked(item.id, true)
          }
        })
      }
    },
    delSelect (item) {
      if (item === 'now') {
        this.trendsChecked = false
      } else {
        this.$refs.tree.setChecked(item.id, false)
      }
    },
    delTrendsSelect (item) {
      console.log(item, '111')

      if (!this.activeData.multiple) {
        this.trendsCheckout = ''
        this.selectTrendsList = []
      } else {
        this.selectTrendsList.forEach((itemA, index) => {
          if (itemA.id === item.id) {
            this.selectTrendsList.splice(index, 1)
          }
        })
        this.trendsCheckout.forEach((itemB, index) => {
          if (itemB === item.id) {
            this.trendsCheckout.splice(index, 1)
          }
        })
      }
    },
    submit () {
      this.$emit('submit', this.selectList, this.selectTrendsList, this.showTrends)
    },
    treeDataChange (data) {
      data.forEach((item) => {
        this.$set(item, 'id', item._id)
        this.$set(item, 'label', item.name)
        delete item._id
        delete item.name
        if (item.children) {
          this.treeDataChange(item.children)
        }
      })
      return data
    }
  },
  mounted () {
    console.log(222111)

    this.getList()
  },
  created () { }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: block !important;
}

::v-deep .el-tree-node.disabled {
  display: none;
}

::v-deep .el-checkbox__label {
  display: none;
}

::v-deep .el-radio__label {
  display: none;
}

::v-deep .el-tabs__item {
  font-size: 12px;
  padding: 0 10px;
}

::v-deep .el-tabs__nav-scroll {
  justify-content: unset !important;
  align-items: unset !important;
}

::v-deep .is-disabled {
  display: none !important;
}

::v-deep .el-tree>.el-tree-node {
  border-bottom: unset !important;
  padding-left: unset !important;
  padding-right: 14px;
  box-sizing: border-box;
  position: relative;
}

::v-deep .el-tree-node__content {
  position: relative;
}

.depart-tree {
  ::v-deep .el-checkbox {
    position: absolute;
    right: 0;
    top: 9px;
  }
}

.depart-trends-list {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 14px;
}

.depart-trends {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  padding: 0 14px 0 10px;
  font-size: 12px;

  .depart-trends-left {
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
      color: #409dff;
    }
  }
}

.all-checkd {
  ::v-deep .el-checkbox {
    position: unset !important;
    right: unset !important;
    top: unset !important;
  }
}

.iconfont {
  font-size: 12px !important;
}

.icon-warp {
  width: 32px;
  height: 32px;
  background: #409dff;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
  line-height: 32px;

  i {
    color: #fff !important;
    margin: unset !important;
  }
}

.title {
  font-size: 14px;
  margin: 20px 0 8px 0;
  font-weight: bold;
  color: #303133;
}

.select-depart-ul {
  height: 280px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  display: flex;
  align-items: center;

  .depart-warp {
    flex: 1;
    height: 100%;

    .depart-title {
      padding: 16px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      line-height: 32px;
      justify-content: space-between;

      &>div:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        color: #303133;
      }

      &>div:nth-child(2) {
        color: #409eff;
      }
    }

    .depart-list {
      overflow-y: scroll;
      height: 230px;

      .depart-list-li:hover {
        background: #f5f8fc;
      }

      .depart-list-li {
        font-size: 14px;
        padding: 10px 24px;
        box-sizing: border-box;
        font-weight: 400;
        color: #303133;
        display: flex;
        cursor: pointer;

        align-items: center;
        justify-content: space-between;

        .li-info {
          display: flex;
          align-items: center;

          i {
            color: #409dff;
            margin-right: 10px;
          }

          .li-info-atvar {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            background: #409eff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.depart-ul {
  margin-top: 10px;
  display: flex;
  border: 1px solid #e4e7ed;
  flex-wrap: wrap;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;

  min-height: 120px;

  .li-info {
    display: flex;
    align-items: center;
  }

  .depart-li {
    font-size: 12px;
    font-weight: 400;
    color: #303133;
    background: #f0f1f4;
    border-radius: 1px;
    box-sizing: border-box;
    padding: 5px 10px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    display: flex;
    margin: 5px 10px 0 0;

    i {
      color: #409eff;
      font-size: 16px;
    }
  }
}
</style>
