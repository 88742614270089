<template>
  <div>
    <div class="content-info">
      <div class="form-ul" v-if="!multi">
        <div v-for="(item, index) in fileds" :key="index" :style="{
          width:
            item.__config__.tagIcon === 'row'
              ? '100%'
              : item.__config__.tagIcon === 'table'
                ? '100%'
                : '100%',
        }" class="form-li-warp">
          <div class="form-li" v-if="item.__config__.tag === 'el-upload'">
            <div>{{ item.__config__.label }}</div>
            <div v-for="(fileItem, fileIndex) in getValue(item)" :key="fileItem.uid">
              <div class="li-value">
                <div class="file-list">
                  <div>{{ fileItem.name }}</div>
                  <el-button type="text" @click="down(fileItem)">查看下载</el-button>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="item.__config__.tag === 'img-upload'">
            <div>{{ item.__config__.label }}</div>
            <div class="img-upload-warp">
              <el-upload class="upload-demo" action="" list-type="picture-card" :on-preview="handlePreview"
                :file-list="getValue(item)" :show-file-list="true" :disabled="true">
              </el-upload>
              <el-dialog :visible.sync="previewVisible" :before-close="handleClosePreview" append-to-body
                :modal-append-to-body="false">
                <img :src="previewImage" class="preview-image" />
              </el-dialog>
            </div>
          </div>
          <div v-else-if="item.__config__.tag === 'el-editor'" class="form-li">
            <div>{{ item.__config__.label }}</div>
            <div v-html="getValue(item)" class="li-value"></div>
          </div>
          <div v-else-if="item.__config__.tagIcon === 'row'" class="form-li" style="width: 100%">
            <div>{{ item.__config__.rowTitle }}</div>
            <el-table :data="getValue(item)" style="width: 100%;" min-height=" 200" max-height="500" :header-cell-style="{
              background: '#F0F4FA',
              color: '#303133',
              borderRadius: '4px',
            }">
              <el-table-column type="index" width="55" label="序号" align="center">
              </el-table-column>
              <el-table-column v-for="(itemChild, indexChild) in item.__config__.children" :key="indexChild"
                :label="itemChild.__config__.label" align="center">
                <template v-slot:default="scope">
                  <div v-if="itemChild.__config__.tag !== 'el-upload'" class="row-class">
                    <div v-if="itemChild.__config__.tag === 'el-sign'">
                      <img style="height: 32px" :src="getTabsTabelValue(
                        scope.row,
                        itemChild,
                        scope.$index
                      )
                        " />
                    </div>
                    <div>
                      {{
                        getTabsTabelValue(
                          scope.row,
                          itemChild,
                          scope.$index
                        )
                      }}
                    </div>
                  </div>

                  <div class="upload-list" v-else>
                    <div style="color: #409eff; cursor: pointer" v-for="(
                              uploadItem, uploadIndex
                            ) in getTabsTabelValue(
                              scope.row,
                              itemChild,
                              scope.$index
                            )" :key="uploadIndex" @click="fileDown(uploadItem)">
                      {{ uploadItem.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="form-li" v-else-if="item.__config__.tagIcon === 'table'" style="width: 100%">
            <div style="margin-bottom: 15px">{{ item.__config__.label }}</div>
            <el-tabs type="border-card" v-model="activeName" style="width: 100%">
              <el-tab-pane :label="itemTab.name" :name="itemTab.name" v-for="(itemTab, indexTab) in getTabs(item)"
                :key="indexTab">
                <div class="tabs">
                  <div class="tabs-ul" v-for="(itemChild, indexChild) in itemTab.children" :key="indexChild" :style="{
                    width:
                      itemChild.__config__.tagIcon === 'row'
                        ? '100%'
                        : itemChild.__config__.tagIcon === 'table'
                          ? '100%'
                          : '50%',
                  }">
                    <div class="tabs-li" v-if="itemChild.__config__.tag === 'el-upload'">
                      <div class="li-title">{{ itemChild.__config__.label }}</div>
                      <div class="li-upload-text" v-for="(
                                uploadItem2, uploadIndex2
                              ) in getTabsValue(itemChild)" :key="uploadIndex2">
                        <div>{{ uploadItem2.name }}</div>
                        <el-button type="text" @click="down(uploadItem2)">查看下载</el-button>
                      </div>
                    </div>
                    <div v-else-if="itemChild.__config__.tagIcon === 'Editor'" class="tabs-li">
                      <div>{{ item.__config__.label }}</div>
                      <div v-html="getTabsValue(itemChild)" class="li-value"></div>
                    </div>
                    <div v-else-if="itemChild.__config__.tagIcon === 'row'" class="tabs-li">
                      <el-table :data="getTabsValue(itemChild)" max-height="500" :header-cell-style="{
                        background: '#F0F4FA',
                        color: '#303133',
                        borderRadius: '4px',
                      }">
                        <el-table-column type="index" width="55" label="序号" align="center">
                        </el-table-column>
                        <el-table-column v-for="(
                                  tableColumn, indexColumn
                                ) in itemChild.children" :key="indexColumn" :label="tableColumn.label"
                          :prop="tableColumn.fileds" align="center">
                          <template slot-scope="scope">
                            <div v-if="tableColumn.__config__.tag !== 'el-upload'" class="row-class">
                              <div v-if="tableColumn.__config__.tag === 'el-sign'">
                                <img style="height: 32px" :src="getTabsTabelValue(
                                  scope.row,
                                  tableColumn,
                                  scope.$index
                                )
                                  " />
                              </div>
                              <div v-else>
                                {{
                                  getTabsTabelValue(
                                    scope.row,
                                    tableColumn,
                                    scope.$index
                                  )
                                }}
                              </div>
                            </div>
                            <div class="upload-list" v-else>
                              <div style="color: #409eff; cursor: pointer" v-for="(
                                        uploadItem, uploadIndex
                                      ) in getTabsTabelValue(
                                        scope.row,
                                        tableColumn,
                                        scope.$index
                                      )" :key="uploadIndex" @click="fileDown(uploadItem)">
                                {{ uploadItem.name }}
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div class="tabs-li" v-else>
                      <div class="li-title">{{ itemChild.__config__.label }}</div>
                      <div class="li-text">
                        <div v-if="itemChild.__config__.tagIcon === 'Sign'">
                          <img style="height: 32px" :src="getTabsValue(itemChild)" />
                        </div>
                        <div v-else>
                          {{ getTabsValue(itemChild) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div></div>
          </div>
          <div class="form-li" v-else>
            <div>{{ item.__config__.label }}</div>
            <div class="li-value">
              <div v-if="item.__config__.tag === 'el-sign'">
                <img style="height: 32px" :src="getValue(item)" />
              </div>
              <div v-else>{{ getValue(item) }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 如果关联查询的是多条数据的话就展示表格 -->
      <div v-else>
        <el-table ref="table" :data="data" width="100%" height="100%" :header-cell-style="{
          background: '#F0F4FA',
          color: '#303133',
          borderRadius: '4px',
        }">
          <!-- <el-table-column type="selection" align="center" fixed="left">
          </el-table-column> -->

          <el-table-column :label="item.__config__.rowTitle ? item.__config__.rowTitle : item.__config__.label"
            align="center" v-for="(item, index) in fileds" :key="index" width="200" :prop="item.__config__.label">
            <template slot-scope="scope">
              <div class="col-class" :title="getData(scope.row, item)">
                <img style="height: 32px" v-if="item.__config__.tagIcon === 'Sign'" :src="getData(scope.row, item)" />
                <div v-else-if="item.__config__.tagIcon === 'Editor'" v-html="getData(scope.row, item)"></div>
                <div v-else>{{ getData(scope.row, item) }}</div>
              </div>
            </template>
            <template v-if="item.__config__.tagIcon === 'row'">
              <el-table-column :label="item2.__config__.label" width="200" align="center"
                v-for="(item2, index2) in item.__config__.children" :key="index2" :prop="item2.__vModel__">
                <template slot-scope="scope">
                  <div class="row-warp">
                    <div class="row-class" :title="getTabTableData(item3, item2)"
                      v-for="(item3, index3) in scope.row[item.__config__.componentName]" :key="index3">
                      <div>
                        {{ getTabTableData(item3, item2, scope.row[item.__config__.componentName]) }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </template>

            <template v-if="item.tagIcon === 'table'">
              <el-table-column v-for="(tabItem, tabIndex) in item.child" :key="tabIndex" align="center"
                :label="tabItem.name">
                <el-table-column v-for="(tabChildItem, tabChildIndex) in tabItem.child" :key="tabChildIndex" width="200"
                  align="center" :label="tabChildItem.label">
                  <template slot-scope="scope">
                    <div v-if="tabChildItem.tagIcon === 'Sign'">
                      <img style="height: 32px" :src="getData(
                        scope.row[item.fileds],
                        tabChildItem,
                        tabChildIndex
                      )
                        " />
                    </div>
                    <div v-else-if="tabChildItem.tagIcon === 'Editor'" v-html="getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                      "></div>
                    <div v-else>
                      {{
                        getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                      }}
                    </div>
                  </template>
                  <div v-if="tabChildItem.tagIcon === 'row'">
                    <el-table-column :label="tableItem.label" width="200" align="center"
                      v-for="(tableItem, tableIndex) in tabChildItem.child" :key="tableIndex">
                      <template slot-scope="scope">
                        <div class="row-warp">
                          <div class="row-class" :title="getTabTableData(item3, tableItem)" v-for="(item3, index3) in scope.row[item.fileds][
                            tabChildItem.fileds
                          ]" :key="index3">
                            <div v-if="tableItem.tagIcon === 'Sign'">
                              <img style="height: 32px" :src="getTabTableData(item3, tableItem)" />
                            </div>
                            <div v-else>
                              {{ getTabTableData(item3, tableItem) }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </div>
                </el-table-column>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" key="time" prop="add_time" align="center" fixed="right" v-if="isShowAddTime">
            <el-table-column label="提交人" prop="user_name" align="center" key="user" fixed="right"
              :width="isShowUserName ? '200' : '-1'">
            </el-table-column>
          </el-table-column>
          <el-table-column min-width="-1" align="center" fixed="right">
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="!searchType">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
            :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="total" background>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      activeName: "",
      fileds: [],
      isShowUserName: false,
      tableData: [],
      isShowAddTime: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,

      previewVisible: false,
      previewImage: '',

    };

  },
  // searchType这个字段表示是编辑表单的时候 就不用展示分页
  props: ["data", "headData", "multi", "searchType"],
  watch: {
    "data": {
      handler (val) {
        this.total = val.length;
      }
    },
    "headData": {
      handler (val) {
        this.fileds = val
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
  methods: {
    handlePreview (file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    handleClosePreview () {
      this.previewVisible = false;
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.$emit("getPage", this.pageSize, this.pageNum);
    },
    handleCurrentChange (val) {
      this.pageNum = val;
      this.$emit("getPage", this.pageSize, this.pageNum);
    },
    menberCheckDict (data) {
      console.log(data, "成员部门组件传输")
      let arr = [];
      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    departCheckDict (data) {
      let arr = [];
      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },


    getTabs (item) {
      this.activeName = item.children[0].name;
      return item.children;
    },
    getValue (item) {
      let obj = this.data;
      if (item.__config__.tagIcon === "row") {
        for (let i in obj) {
          if (i === item.__config__.componentName) {
            return obj[i];
          }
        }
      } else if (item.__config__.tag === "menberCheck" || item.__config__.tag === "menberSingle") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return this.menberCheckDict(obj[i]);
          }
        }
        return "";
      } else if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return this.departCheckDict(obj[i]);
          }
        }
        return "";
      } else if (item.__config__.tag === "el-cascader") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i].join("/");
          }
        }
      } else if (item.__config__.tag === "addr") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            if (Array.isArray(obj[i])) {
              return obj[i].join("/");
            }
          }
        }
      } else if (item.__config__.tag === "el-select") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            if (Array.isArray(obj[i])) {
              return obj[i].join(",");
            } else {
              return obj[i];
            }
          }
        }
      } else if (item.__config__.tag === "el-checkbox-group") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i].join(",");
          }
        }
      } else if (item.__config__.tag === "el-upload") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i];
          }
        }
      }else if (item.tag === "img-upload") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            let list = [];
            if (Array.isArray(obj[i])) {
              obj[i].forEach((itemA) => {
                itemA.urls =
                  `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
                  itemA.response.data.attachment_id;
                list.push({
                  url: itemA.urls,
                  name: itemA.name,
                });
              });
            } else {
              obj[i].split(",").forEach((itemA) => {
                let urls = ""
                urls =
                  `${window.location.origin}/api/upload/handleRequest?xf_upload_url=` +
                  itemA;
                list.push({
                  url: urls,
                });
              });
            }
            return list;
          }
        }
      } else if (item.__config__.tagIcon === "table") {
        return "标签页";
      } else {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i];
          }
        }
      }
    },

    getTabsTabelValue (row, item, index) {
      for (let i in row) {
        if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
          if (item.__vModel__ === i) {
            return this.departCheckDict(row[i]);
          }
        } else if (item.__config__.tag === "menberCheck" || item.__config__.tag === "menberSingle") {
          if (item.__vModel__ === i) {
            if (row[i]) {
              return this.menberCheckDict(row[i]);
            }
          }
        } else if (item.__config__.tag === "addr") {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        } else if (
          item.tag === "el-checkbox-group" ||
          item.tag === "el-cascader"
        ) {
          if (item.__vModel__ === i) {
            return row[i].join("/");
          }
        } else if (item.__config__.tag === "el-upload") {
          if (item.__vModel__ === i) {
            return row[i];
          }
        } else if (item.__config__.tag === "el-select") {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        } else if (item.__config__.tag === "datePicker") {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("至");
            } else {
              return row[i];
            }
          }
        } else {
          if (item.__vModel__ === i) {
            return row[i];
          }
        }
      }
    },

    getTabsValue (item) {
      let obj = this.data;
      let row = "";
      for (let i in obj) {
        if (i.includes("tabs")) {
          row = obj[i];
        }
      }
      if (item.__config__.tag === "menberCheck" || item.__config__.tag === "menberSingle") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            let arr = [];
            if (row[i].length > 0) {
              return this.menberCheckDict(row[i]);
            }
          }
        }
      } else if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (row[i].length > 0) {
              return this.departCheckDict(row[i]);
            }
          }
        }
      } else if (item.__config__.tag === "el-upload") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i];
          }
        }
      } else if (item.__config__.tag === "el-cascader") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i].join(",");
          }
        }
      } else if (item.__config__.tag === "el-select") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.__config__.tag === "datePicker") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.__config__.tag === "el-checkbox-group") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i].join(",");
          }
        }
      } else if (item.__config__.tag === "addr") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i];
          }
        }
      }
    },

    fileDown (fileItem) {
      if (process.env.NODE_ENV === "development") {
        window.open(
          "http://123.249.127.106:8787/api/upload/handleRequest?attachmen_id=" +
          fileItem.response.data.attachment_id
        );
      } else {
        window.open(
          `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
          fileItem.response.data.attachment_id
        );
      }
    },




    getData (row, item) {
      if (item.__config__.tag === "menberCheck" || item.__config__.tag === "menberSingle") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return this.menberCheckDict(row[i]);
          }
        }
      } else if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return this.departCheckDict(row[i]);
          }
        }
      } else if (item.__config__.tag === "el-upload") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            let arr = [];
            if (row[i] && row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.__config__.tag === "el-cascader") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i].join("/");
          }
        }
      } else if (item.__config__.tag === "el-select") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.__config__.tag === "datePicker") {
        for (let i in row) {
          // console.log(i,'i');

          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.__config__.tag === "el-checkbox-group") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i].join(",");
          }
        }
      } else if (item.__config__.tag === "addr") {
        for (let i in row) {
          if (item.__vModel__ === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else {
        for (let i in row) {
          if (item.__vModel__ === i) {
            return row[i];
          }
        }
      }
    },


    getTabTableData (row, item) {
      if (item.__config__.tag === "datePicker") {
        for (let i in row) {
          if (i === item.__vModel__) {
            return row[i];
          }
        }
      } else if (item.__config__.tag === "menberCheck" || item.tag === "menberSingle") {
        for (let i in row) {
          if (i === item.__vModel__) {
            if (row[i].length > 0) {
              return this.menberCheckDict(row[i]);
            }
          }
        }
      } else if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
        for (let i in row) {
          if (i === item.__vModel__) {
            if (row[i].length > 0) {
              return this.departCheckDict(row[i]);
            }
          }
        }
      } else if (item.__config__.tag === "el-upload") {
        for (let i in row) {
          if (i === item.__vModel__) {
            let arr = [];
            if (row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.__config__.tag === "el-cascader") {
        for (let i in row) {
          if (i === item.__vModel__) {
            return row[i].join("/");
          }
        }
      } else if (item.__config__.tag === "addr") {
        for (let i in row) {
          if (i === item.__vModel__) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else if (item.__config__.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.__vModel__) {
            return row[i].join(",");
          }
        }
      } else if (item.__config__.tag === "el-select" || item.__config__.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.__vModel__) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else {
        for (let i in row) {
          if (i === item.__vModel__) {
            console.log(row[i], "表格xxx")
            return row[i];
          }
        }
      }
    },
  }

}
</script>
<style lang="scss" scoped>
.img-upload-warp {
  ::v-deep .el-upload--picture-card {
    display: none;
  }

  ::v-deep .el-dialog {
    position: unset !important;
    margin: 0 auto !important;
    height: 80vh !important;
    width: 60% !important;
    margin-top: 50px !important;


  }

  ::v-deep .el-dialog__body {
    height: 60vh !important;
    padding: 20px 30px !important;
    box-sizing: border-box !important;
  }

  ::v-deep .el-dialog__header {
    position: relative;
  }

  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 0;
    right: 20px;
  }

}

.content-info {
  padding: 24px 0px;
  box-sizing: border-box;
}


.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // padding-top: 20px;
  box-sizing: border-box;

  .form-li-warp {
    margin-bottom: 16px;
    max-width: 100%;
    min-width: 50%;
  }

  .file-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
  }

  .form-li {
    width: 100%;

    .li-value {
      width: 95%;
      // margin-top: 20px;
      background: #f5f6f8;
      border-radius: 2px;
      color: #141e31;
      font-size: 14px;
      box-sizing: border-box;
      min-height: 32px;
      padding: 6px 8px;
      // margin-bottom: 10px;
    }
  }
}


.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tabs-ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
  }

  .tabs-li {
    margin-bottom: 16px;
    width: 100%;

    .li-text {
      width: 95%;
      margin-top: 20px;
      background: #f5f6f8;
      border-radius: 2px;
      color: #141e31;
      font-size: 14px;
      box-sizing: border-box;
      min-height: 32px;
      padding: 6px 8px;
      margin-bottom: 10px;
    }

    .li-upload-text {
      width: 95%;
      background: #f5f6f8;
      border-radius: 2px;
      color: #141e31;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 10px;
      box-sizing: border-box;
      height: 32px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.row-class {
  border-bottom: 1px solid #ebeef5;

  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col-class {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.pagination {
  text-align: right;
  padding: 20px;
  box-sizing: border-box;
}


::v-deep .el-table__body-wrapper {
  height: 100% !important;
}
</style>