<template>
  <el-select v-model="value" ref="mySelect" :key="selectKey" :placeholder="data.placeholder" :multiple="data.multiple"
    :filterable="data.filterable" :clearable="data.clearable" :style="data.style" :disabled="data.disabled"
    @change="sendValue">
    <el-option v-for="(item, index) in selectOption" :key="index" :label="item.label" :value="item.value"
      :disabled="item.disabled"></el-option>
  </el-select>
</template>

<script>
import { getFieldData } from "@/api/app";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectOption: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  name: "MySelect",
  data () {
    return {
      value: "",

      selectKey: 0,
      isLink: false,
      show: true
    };
  },
  watch: {
    selectOption: {
      handler (val) {
      }
    }

  },
  components: {},
  created () {


  },
  mounted () {
    if (this.draftData.value) {
      this.value = this.draftData.value;
    } else {
      this.value = this.data.__config__.defaultValue;
    }

    if (this.data.valueType === "relation") {
      // this.getselectData();
    }
    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
    setTimeout(() => {
      if (this.data.__config__.defaultValue) {
        this.sendValue(this.data.__config__.defaultValue)
      }
    }, 500)


  },
  methods: {
    // getselectData () {
    //   getFieldData(
    //     this.data.rlatedFields.formId,
    //     this.data.rlatedFields.fileds
    //   ).then((res) => {
    //     if (res.code == 200) {
    //       this.data.__slot__.options = [];
    //       res.data.forEach((item) => {
    //         if (item) {
    //           this.data.__slot__.options.push({
    //             label: item,
    //             value: item,
    //           });
    //         }
    //       });
    //     }
    //   });
    // },
    xygzFun () {
      if (
        this.data.regulation &&
        this.data.regulation.length > 0 &&
        this.data.regulation[0].name !== ""
      ) {
        let arr = [];
        let nowobj = {};
        this.data.regulation.forEach((item, index) => {
          if (item.name !== "") {
            if (item.name === this.value) {
              nowobj = {
                value: item.value,
                isshow: true,
              };
            } else {
              arr.push({
                value: item.value,
                isshow: false,
              });
            }
          }
        });
        if (nowobj.value) {
          arr.push(nowobj);
        }

        // this.$bus.$emit("tableXygz", arr, this.data.xygzParent, this.index);
      }
    },
    sendValue (val) {
      let dataArr = [];
      // if (this.data.multiple) {
      //   this.data.__slot__.options.forEach((item) => {
      //     let str = this.data.__config__.defaultValue.indexOf(item.value);
      //     if (str !== -1) {
      //       dataArr.push(item.value);
      //     }
      //   });
      //   // this.value = dataArr;
      //   this.value = [...new Set(dataArr)];
      // }
      let obj = {
        name: this.data.__vModel__,
        value: this.value,
        index: this.index,
      };
      this.xygzFun();
      this.$emit("tableValue", obj);
    },
  },
};
</script>

<style scoped></style>
