<template>
	<div class="tools-box">
		<el-dialog
			:visible.sync="openDialog"
			width="338px"
			:modal="false"
			:before-close="imgHandleClose"
			class="setDialog"
			:style="position">
			<div class="dialog-cont">
				<div class="lable">时间样式</div>
				<div :class="datas.data.font +' look-box'" @click="dialogImgDetails = true">10:30:25</div>
				<div class="lable">时间格式</div>
				<el-select v-model="datas.data.timetype" placeholder="请选择">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
		</el-dialog>
		
		
		<el-dialog
			:visible.sync="dialogImgDetails"
			width="338px"
			:modal="false"
			:before-close="imgDetailsHandleClose"
			class="setDialog"
			v-if="dialogImgDetails"
			:style="positionTow">
			<div class="dialog-cont" v-if="dialogImgDetails">
				<div :class="datas.data.font == item ? item +' look-box on' : item +' look-box'" v-for="(item,index) in fontType" :key="index" @click="setFont(item)">10:30:25</div>
			</div>
		</el-dialog>
		
		
	</div>
	
</template>

<script>
	
  export default {
		props: {
			openDialog: false,
		  datas: Object,
			position: "",
			positionTow: ""
		},
    data() {
      return {
				dialogImgDetails: false,
				fontType: ['hmt','pht','jcyt','mht'],
				options: [
					{
						value: 1,
						label: '2024-02-01 10:30:25'
					},
					{
						value: 2,
						label: '2024-02-01 10:30:25 星期四'
					},
					{
						value: 3,
						label: '2024/02/01 10:30:25'
					},
					{
						value: 4,
						label: '2024/02/01 10:30:25 星期四'
					},
					{
						value: 5,
						label: '2024年02月01日 10时30分25秒'
					},
					{
						value: 6,
						label: '2024年02月01日 10时30分25秒 星期四'
					},
					{
						value: 7,
						label: '10:30:25'
					},
					{
						value: 8,
						label: '10:30:25 星期四'
					},
					{
						value: 9,
						label: '10时30分25秒'
					},
					{
						value: 10,
						label: '10时30分25秒 星期四'
					},
				],
				value: ''
      };
    },
		watch: {
		},
    components: {
			
    },
		created() {
		},
		mounted() {
		},
    methods: {
			imgHandleClose(){
				this.$emit("handleClose", false);
			},
			imgDetailsHandleClose(){
				this.dialogImgDetails = false
			},
			setFont(type){
				this.datas.data.font = type;
			}
		}
  };
</script>

<style lang="scss" scoped>
	@font-face {
	  font-family: 'pht';
	  src: url('./font/pht.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'hmt';
	  src: url('./font/hmt.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'jcyt';
	  src: url('./font/jcyt.ttf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	@font-face {
	  font-family: 'mht';
	  src: url('./font/mht.otf') format('truetype');
	  font-weight: normal;
	  font-style: normal;
	}
	.pht{
		font-family: 'pht';
	}
	.hmt{
		font-family: 'hmt';
	}
	.jcyt{
		font-family: 'jcyt';
	}
	.mht{
		font-family: 'mht';
	}
	
	.look-box{
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		text-align: center;
		background-color: #F5F8FC;
		margin-bottom: 15px;
		cursor: pointer;
		box-sizing: border-box;
	}
	.look-box.on{
		border: 1px solid #409EFF;
	}
</style>