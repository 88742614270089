<template>
  <el-cascader
    v-model="value"
    :options="data.options"
    :props="data.props.props"
    :placeholder="data.placeholder"
    :filterable="data.filterable"
    :clearable="data.clearable"
    :disabled="data.disabled"
    :style="data.style"
    ref="testCascader"
    @change="sendValue"
  ></el-cascader>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    draftData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "MyCascader",
  data() {
    return {
      value: "",
    };
  },

  components: {},
  watch: {
    value(val) {
      this.sendValue();
    },
  },
  mounted() {
    if (this.draftData.value) {
      this.value = this.draftData.value;
    } else {
      this.value = this.data.__config__.defaultValue;
    }
  },
  methods: {
    sendValue(value) {
      let obj = {
        name: this.data.__vModel__,
        value: this.value,
        index: this.index,
      };
      this.$emit("tableValue", obj);
    },
  },
};
</script>

<style scoped>
.el-cascader-menu .el-scrollbar__wrap {
  min-height: 204px !important;
  max-height: 300px !important;
}
</style>
